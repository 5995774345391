import { CHAPTER_ACCESS, CHAPTER_LIST } from '../actions/types';
import { actionTypes } from './reducerTypes';

let initialState = {
    count: 10,
    chapterList: [],
    limit: 10,
    page: 1,
    totalPages: 10,
    hasChapterLevelAccess: false,
    chapterActionByLanguage: {}
};

export const chapterReducer = (state = initialState, action: actionTypes) => {
    switch (action.type) {
        case CHAPTER_ACCESS:
            return {
                ...state,
                // hasChapterLevelAccess: action.payload.access,
                chapterActionByLanguage: action.payload.chapterAction
            };
        case CHAPTER_LIST:
            return {
                ...state,
                count: action.payload.count,
                limit: action.payload.limit,
                page: action.payload.page,
                totalPages: action.payload.totalPages,
                chapterList: action.payload.data
            };
        default:
            return state;
    }
};

export default chapterReducer;

import axios from 'axios';
import { handleToast, Logout } from '../../utils/handler';

export function CatchReturnError(error: any) {
    if (axios.isCancel(error))
        return true
    let message: { result: string } | string = 'Something went wrong';
    if (error.response) {
        message = error.response.data;
    } else if (error.request) {
        message = error.request;
    } else {
        message = error.message;
    }
    if (error?.response?.status === 401) {
        Logout();
        handleToast({ message });
        return true;
    }
    handleToast({ message });

    // return message;
}

import ForgetPassword from './views/forget-password';
import Login from './views/login';
import ResetPassword from './views/reset-password';
// const Login = React.lazy(() => import('./views/SignIn/SignIn1'));
export interface RouteObject {
    checkProjectAssigned?: boolean;
    path: string;
    exact?: boolean;
    name?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: any;
    hideLayout?: boolean;
    isAdmin?: boolean;
    isDataScientist?: boolean;
    isAdminOrDS?: boolean;
    isReviewer?: boolean;
}
const route: RouteObject[] = [
    { path: '/login', exact: true, name: 'Login', component: Login },
    { path: '/forgot-password', exact: true, name: 'ForgetPassword', component: ForgetPassword },
    { path: '/reset-password/:resetToken', exact: true, name: 'ResetPassword', component: ResetPassword }
];
export default route;

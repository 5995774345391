interface Badge {
    title: string;
    type: string;
}
export interface MenuItemType {
    id: string;
    title: string;
    type: string;
    icon?: string;
    children?: MenuItemType[];
    breadcrumbs?: boolean;
    url?: string;
    badge?: Badge;
    target?: boolean;
    classes?: string;
    external?: boolean;
    hideSideMenu?: boolean;
    isAdmin?: boolean;
    isDataScientist?: boolean;
    isAdminAndScientist?: boolean;
    isReviewer?: boolean;
    internationalizationPrefixes: string;
}

const chartData: { items: MenuItemType[] } = {
    items: [
        {
            id: 'navigation',
            title: 'Navigasi',
            type: 'group',
            icon: 'icon-support',
            internationalizationPrefixes: 'main-menu.title',
            children: [
                {
                    id: 'home',
                    title: 'Dashboard',
                    type: 'item',
                    url: '/',
                    classes: 'nav-item',
                    internationalizationPrefixes: 'main-menu.dashboard',
                    icon: 'fa fa-home'
                },
                {
                    id: 'task-page',
                    title: 'Task Management',
                    type: 'item',
                    url: '/task-view',
                    classes: 'nav-item',
                    internationalizationPrefixes: 'main-menu.task-page',
                    icon: 'fa fa-list-check',
                    isAdminAndScientist: true
                },
                {
                    id: 'workspace',
                    title: 'Workspace',
                    type: 'item',
                    url: '/workspace',
                    classes: 'nav-item',
                    internationalizationPrefixes: 'main-menu.workspace',
                    icon: 'fa fa-magic'
                },
                {
                    id: 'task-board',
                    title: 'Task Board',
                    type: 'item',
                    url: '/task-board',
                    classes: 'nav-item',
                    internationalizationPrefixes: 'main-menu.task-board',
                    icon: 'fa-brands fa-trello'
                },
                {
                    id: 'aligned-sentences',
                    title: 'Aligned Sentences',
                    type: 'item',
                    url: '/aligned-sentences',
                    classes: 'nav-item',
                    internationalizationPrefixes: 'main-menu.aligned-sentences',
                    icon: 'fa fa-grip-lines',
                    isDataScientist: true
                },

                {
                    id: 'Definition',
                    title: 'Definition Management',
                    type: 'item',
                    url: '/definition-management',
                    classes: 'nav-item',
                    internationalizationPrefixes: 'main-menu.definition-management',
                    icon: 'fa fa-project-diagram',
                    isAdminAndScientist: true
                },
                {
                    id: 'profile',
                    title: 'Profile',
                    type: 'item',
                    url: '/profile',
                    classes: 'nav-item',
                    internationalizationPrefixes: 'main-menu.profile',
                    icon: 'fa fa-user',
                    hideSideMenu: true
                },
                {
                    id: 'export',
                    title: 'Data Export',
                    type: 'item',
                    url: '/export',
                    classes: 'nav-item',
                    internationalizationPrefixes: 'main-menu.export',
                    icon: 'fa fa-file-export',
                    isAdminAndScientist: true
                },
                {
                    id: 'user-management',
                    title: 'User Management',
                    type: 'item',
                    url: '/user-project-management',
                    classes: 'nav-item',
                    internationalizationPrefixes: 'main-menu.settings',
                    icon: 'fa fa-user-cog',
                    isAdminAndScientist: true,
                    isReviewer: false
                }
            ]
        }
    ]
};
export default chartData;

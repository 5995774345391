import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import englishTranslation from './english.json';
import indonesianTranslation from './indonesian.json';
// If need to check all localization visually use this package
// import emoji from 'i18next-emoji-postprocessor';
const resources = {
    en: {
        translation: { ...englishTranslation.translation, ...englishTranslation.errorMessage, ...englishTranslation.successMessage }
    },
    id: {
        translation: {
            ...indonesianTranslation.translation,
            ...indonesianTranslation.errorMessage,
            ...indonesianTranslation.successMessage
        }
    }
};
const localLanguage = localStorage.getItem("localLanguage") || "en";
i18n.use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // for all options read: https://www.i18next.com/overview/configuration-options
    // If need to check all localization visually use emoji
    // .use(emoji)
    .init({
        // If need to check all localization visually use postProcess
        // postProcess: 'emoji',
        debug: false,
        lng: localLanguage, //Current language
        fallbackLng: localLanguage, // Default language - if current language isn't found then i18 will use this fallback language to in UI
        resources,
    });
export default i18n;

/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Card, Col, Form, InputGroup, Row, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FILTER_LOADING_TYPE } from 'store/constant';
import { useRouter } from '../hooks/useRouter';
import { getNewDataInAllPages } from '../store/actions/call.action';
import { getTrelloTicketFilters } from '../store/actions/index';
import { getTaskHistory } from '../store/actions/task.action';
import {
    BOOK_DETAILS,
    CURRENT_PROJECT,
    FILTER_ITEM,
    RESET_BOOK,
    REVIEWER_FILTER,
    SHOW_VERSE_TOPICS,
    TASK_FILTER,
    TASK_MANAGEMENT_CURRENT_PROJECT,
    TASK_MANAGEMENT_FILTER
} from '../store/actions/types';
import { getVerseList, updateVerseBulkAction } from '../store/actions/verse.actions';
import { RootState } from '../store/reducer';
import { initialResetBook } from '../store/reducers';
import store from '../store/store';
import {
    checkIsAdminOrDs,
    filterComponent,
    formBookPath,
    getAllStatus,
    getCurrentProject,
    getFilterData,
    getStatusPercentage,
    getWordStatusClass,
    handleToast,
    toggleAssignedToMe
} from '../utils/handler';
import { UpdateBulkChapterType } from '../views/viewTypes/chapter.type';
import { IUser } from '../views/viewTypes/task.types';
import { Project, UserDetail } from '../views/viewTypes/user.types';
import { VerseObj } from '../views/viewTypes/verse.type';
import { ReactPagination } from './common';
import Loader from './shared/loader';

let searchFilter: ReturnType<typeof setTimeout>;
export function DefinitionFilter() {
    const { definitionFilter } = useSelector((state: RootState) => state.taskReducer);
    const { userList } = useSelector((state: RootState) => state.userReducer);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { name } = e.target;
        if (name === 'reviewerAssignedTo') {
            dispatch({ type: REVIEWER_FILTER, payload: { reviewerAssignedTo: Number(e.target.value) } });
        }
        if (name === 'reviewerStatusId') {
            dispatch({ type: REVIEWER_FILTER, payload: { reviewerStatusId: Number(e.target.value) } });
        }
    };
    return (
        <>
            <Col className="col-12 col-sm-12 col-md-12 col-lg col-xl mb-3 mb-lg-0">
                <InputGroup size="sm">
                    <Form.Control as="select" name="reviewerAssignedTo" value={definitionFilter.reviewerAssignedTo} onChange={onChange}>
                        {definitionFilter.reviewerAssignedTo ? (
                            <option selected key={0} value={0}>
                                {t('task-management.clear')}
                            </option>
                        ) : (
                            <option selected key={0} value={0} disabled>
                                {t('task-management.reviewer-assigned-to')}
                            </option>
                        )}
                        {userList.map((user: IUser) => (
                            <option key={user.id} value={user.id}>
                                {user.name}
                            </option>
                        ))}
                    </Form.Control>
                </InputGroup>
            </Col>
            <Col className="col-12 col-sm-12 col-md-12 col-lg-auto col-xl-auto mb-2 mb-lg-0">
                <InputGroup size="sm">
                    <Form.Control as="select" name="reviewerStatusId" onChange={onChange}>
                        {definitionFilter.reviewerStatusId ? (
                            <option selected key={0} value={0}>
                                {t('task-management.clear')}
                            </option>
                        ) : (
                            <option selected key={0} value={0} disabled>
                                {t('task-management.reviewer-status')}
                            </option>
                        )}
                        {getAllStatus('definitionManagement').map((status: { id: number; status: string }) => (
                            <option key={status.id} value={status.id}>
                                {status.status}
                            </option>
                        ))}
                    </Form.Control>
                </InputGroup>
            </Col>
        </>
    );
}
export function ProjectFilter(props: {
    component?: string, disableInput?: boolean
}) {
    const { projectList } = useSelector((state: RootState) => state.mainReducer);
    const currentProject = getCurrentProject(props.component || "");
    const dispatchType = props.component === filterComponent.taskManagement ? TASK_MANAGEMENT_CURRENT_PROJECT : CURRENT_PROJECT;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    return (
        <Form.Group as={Col} className="px-0 mb-3">
            <Form.Label className="mb-0 text-2 font-weight-bold">{t('filter.filter-project')}</Form.Label>
            <select
                className="form-control form-control-sm"
                value={Number(currentProject?.id)}
                name="projectId"
                onChange={(e) => {
                    dispatch({
                        type: dispatchType,
                        payload: _.find(projectList, { id: Number(e.target.value) })
                    });
                    dispatch({
                        type: FILTER_ITEM,
                        payload: FILTER_LOADING_TYPE.PROJECT
                    });
                    getTrelloTicketFilters();
                }}
                disabled={props.disableInput}
            >
                <option selected key={0} value={0} disabled>
                    {t('filter.select-project')}
                </option>
                {projectList.map((project: Project) => (
                    <option key={project.id} value={project.id}>
                        {project.name}
                    </option>
                ))}
            </select>
        </Form.Group>
    );
}
export function TableFilter({
    component = "",
    showStatus = true,
    showAssignedTo = true,
    showCollapse = false,
    showSearchBar = true,
    showAssignedToMe = false,
    showFilterVerse = true,
    statusFilter = 'workspace',
    showDefinitionFilter = false
}: {
    component?: string,
    showDefinitionFilter?: boolean;
    showStatus?: boolean;
    showSearchBar?: boolean;
    showAssignedTo?: boolean;
    showCollapse?: boolean;
    showAssignedToMe?: boolean;
    showFilterVerse?: boolean;
    statusFilter?: string;
}) {
    const statusList = getAllStatus(statusFilter);
    const { filter, booksList, chaptersList, versesList } = getFilterData(component);
    const { userList } = useSelector((state: RootState) => state.userReducer);
    const [search, setSearch] = useState('');
    const dispatch = useDispatch();
    const { showVerseTopics = false }: { showVerseTopics: boolean } = useSelector((state: RootState) => state.workspaceReducer);
    const { t } = useTranslation();
    const filterType = component === filterComponent.taskManagement ? TASK_MANAGEMENT_FILTER : TASK_FILTER;
    const filterString = JSON.stringify(filter);
    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setSearch(e.target.value);
        if (searchFilter) {
            clearTimeout(searchFilter);
        }
        searchFilter = setTimeout(() => {
            dispatch({ type: filterType, payload: { page: 1, search: e.target.value } });
        }, 500);
    };
    const handleCollapseAll = () => {
        dispatch({
            type: SHOW_VERSE_TOPICS,
            payload: !showVerseTopics
        });
    };
    useEffect(() => {
        const filterObject = JSON.parse(filterString);
        getTrelloTicketFilters(filterObject, component);
        setSearch(filterObject.search);
    }, [filterString, component]);
    const handleCheckBoxToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({ type: filterType, payload: { page: 1, assignedToMe: e.target.checked ? 1 : 0 } });
    };
    const reproduceVerseName = (verseName: string) => {
        if (verseName === 'Title Top') return t('filter.title-top');
        const chunks = verseName.split(' ');
        return `${t('filter.title')} ${chunks[1]}`;
    };
    const selectFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
        // const { filter } = store.getState().taskReducer;
        const { name } = e.target;
        const { value } = e.target;
        const payload = { ...filter, [name]: value, page: 1 };
        //--------------- if book filter then erase chapter and verse
        if (name === 'bookListId') {
            payload.chapterId = '';
            payload.versesId = '';
        } else if (name === 'chapterId') {
            //----------- if book chapter then erase  verse  filter
            payload.versesId = '';
        } else if (name === 'versesId') {
            if (!value) {
                payload.versesId = '';
            } else {
                payload.versesId = Number(value); //selVerse.id;
            }
        }
        payload.verseId = payload.versesId; //selVerse.id;
        getTrelloTicketFilters(payload, component);
        store.dispatch({ type: filterType, payload });
    };
    return (
        <Row className="search-header mx-0">
            {showAssignedToMe && (
                <Col className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-auto d-flex justify-content-between">
                    <Form.Group as={Col} className="px-0 mb-3">
                        <Form.Label className="mb-0 text-2 font-weight-bold">{t('filter.assigned-to-me')}</Form.Label>
                        <InputGroup size="sm">
                            <input
                                className="custom-checkbox c-pointer mx-auto"
                                name="assignedToMe"
                                type="checkbox"
                                checked={filter.assignedToMe === 1}
                                onChange={handleCheckBoxToggle}
                            />
                        </InputGroup>
                    </Form.Group>
                </Col>
            )}
            <Col className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-auto d-flex justify-content-between">
                <ProjectFilter component={component} />
            </Col>
            {showSearchBar && (
                <Col className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-auto">
                    <Form.Group as={Col} className="px-0 mb-3">
                        <Form.Label className="mb-0 text-2 font-weight-bold">{t('filter.search-for-tasks')}</Form.Label>
                        <InputGroup size="sm">
                            <Form.Control
                                type="text"
                                onChange={handleSearch}
                                value={search || ''}
                                placeholder={t('filter.search-for-tasks')}
                            />
                            <InputGroup.Prepend className="">
                                <i className="fa fa-search mt-2 text-secondary" />
                            </InputGroup.Prepend>
                        </InputGroup>
                    </Form.Group>
                </Col>
            )}
            {!showDefinitionFilter ? (
                <>
                    {showAssignedTo && (
                        <Col className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-auto d-flex justify-content-between">
                            <Form.Group as={Col} className="px-0 mb-3">
                                <Form.Label className="mb-0 text-2 font-weight-bold">{t('filter.assigned-to')}</Form.Label>
                                <InputGroup size="sm">
                                    <Form.Control as="select" name="assignedTo" value={filter.assignedTo} onChange={selectFilter}>
                                        {filter.assignedTo ? (
                                            <option selected key={0} value={''}>
                                                {t('filter.clear')}
                                            </option>
                                        ) : (
                                            <option selected key={0} value="" disabled>
                                                {t('filter.filter-assigned-to')}
                                            </option>
                                        )}
                                        {userList &&
                                            userList.map((user: UserDetail) => (
                                                <option key={user.id} value={user.id}>
                                                    {user.name}
                                                </option>
                                            ))}
                                    </Form.Control>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                    )}
                    {showStatus && (
                        <Col className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-auto d-flex justify-content-between">
                            <Form.Group as={Col} className="px-0 mb-3">
                                <Form.Label className="mb-0 text-2 font-weight-bold">{t('filter.status')}</Form.Label>
                                <InputGroup size="sm">
                                    <Form.Control as="select" value={filter.statusId} name="statusId" onChange={selectFilter}>
                                        {filter.statusId ? (
                                            <option selected key={0} value={''}>
                                                {t('filter.clear')}
                                            </option>
                                        ) : (
                                            <option selected key={0} value={''} disabled>
                                                {t('filter.filter-status')}
                                            </option>
                                        )}
                                        {statusList &&
                                            statusList.map((status: { id: number; status: string },) => (
                                                <option key={status.id} value={status.id}>
                                                    {t(`status.${status.status}`)}
                                                </option>
                                            ))}
                                    </Form.Control>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                    )}
                </>
            ) : null}
            <Col className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-auto d-flex justify-content-between">
                <Form.Group as={Col} className="px-0 mb-3">
                    <Form.Label className="mb-0 text-2 font-weight-bold">{t('filter.book')}</Form.Label>
                    <InputGroup size="sm">
                        <Form.Control
                            as="select"
                            name="bookListId"
                            value={filter.bookListId ? `${filter.bookListId}` : ''}
                            onChange={selectFilter}
                        >
                            {filter.bookListId ? (
                                <option selected key={0} value={''}>
                                    {t('filter.clear')}
                                </option>
                            ) : (
                                <option selected key={0} value={''} disabled>
                                    {t('filter.filter-book')}
                                </option>
                            )}
                            {booksList &&
                                booksList.map((book: { id: number; name: string; bookId: number | null },) => (
                                    <option key={book.id} value={book.id}>
                                        {t(`bookList.${book.name}`)}
                                    </option>
                                ))}
                        </Form.Control>
                    </InputGroup>
                </Form.Group>
            </Col>
            {filter.bookListId && chaptersList?.length > 0 && (
                <Col className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-auto d-flex justify-content-between">
                    <Form.Group as={Col} className="px-0 mb-3">
                        <Form.Label className="mb-0 text-2 font-weight-bold">{t('filter.chapter')}</Form.Label>
                        <InputGroup size="sm">
                            <Form.Control
                                as="select"
                                name="chapterId"
                                value={filter.chapterId ? `${filter.chapterId}` : ''}
                                onChange={selectFilter}
                            >
                                {filter.chapterId ? (
                                    <option selected key={0} value={''}>
                                        {t('filter.clear')}
                                    </option>
                                ) : (
                                    <option selected key={0} value={''} disabled>
                                        {t('filter.filter-chapter')}
                                    </option>
                                )}
                                {chaptersList &&
                                    chaptersList.map((chapter: { id: number; name: number }) => (
                                        <option key={chapter.id} value={chapter.id}>
                                            {chapter.name === 0 ? t('filter.intro') : chapter.name}
                                        </option>
                                    ))}
                            </Form.Control>
                        </InputGroup>
                    </Form.Group>
                </Col>
            )}
            {showFilterVerse && filter.chapterId && versesList && versesList?.length > 0 && (
                <Col className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-auto d-flex justify-content-between">
                    <Form.Group as={Col} className="px-0 mb-3">
                        <Form.Label className="mb-0 text-2 font-weight-bold">{t('filter.verse')}</Form.Label>
                        <InputGroup size="sm">
                            <Form.Control
                                as="select"
                                name="versesId"
                                value={filter.verseId ? `${filter.verseId}` : ''}
                                onChange={selectFilter}
                            >
                                {filter.verseId ? (
                                    <option selected key={0} value={''}>
                                        {t('filter.clear')}
                                    </option>
                                ) : (
                                    <option selected key={0} value={''} disabled>
                                        {t('filter.filter-verse')}
                                    </option>
                                )}
                                {versesList &&
                                    versesList.map((verse: { id: number; name: string }) => (
                                        <option key={verse.id} value={verse.id}>
                                            {Number(verse.name) || reproduceVerseName(verse.name)}
                                        </option>
                                    ))}
                            </Form.Control>
                        </InputGroup>
                    </Form.Group>
                </Col>
            )}
            {showDefinitionFilter ? <DefinitionFilter /> : null}
            {showCollapse && (
                <Col className="col-auto">
                    <button className="btn btn-sm btn-primary task-collapse-btn rounded-pill" onClick={handleCollapseAll}>
                        {showVerseTopics ? <i className="fa fa-compress-alt mr-2" /> : <i className="fa fa-expand-alt mr-2" />}
                        {t('filter.collapse-all')}
                    </button>
                </Col>
            )}
        </Row >
    );
}
const TaskTableView = React.memo((props: any) => {
    const dispatch = useDispatch();
    const showTableAction = props.showTableAction || false;
    const { verseList, count, limit, page, totalPages } = useSelector((state: RootState) => state.verseReducer);
    const { taskPaginationLoader } = useSelector((state: RootState) => state.taskReducer);
    const { filter } = useSelector((state: RootState) => state.taskManagementReducer);
    const { userList, myProfile } = useSelector((state: RootState) => state.userReducer);
    const { currentProject } = useSelector((state: RootState) => state.taskManagementReducer);
    const statusList = getAllStatus();
    const { bookDetails } = useSelector((state: RootState) => state.workspaceReducer);
    const [selectedRows, setSelectedRows] = useState([] as number[]);
    const [unSelectedRows, setUnSelectedRows] = useState([] as number[]);
    const [isAllRowSelected, setIsAllRowSelected] = useState(false);
    const [statusLoader, setStatusLoader] = useState(false);
    const [assignedToLoader, setAssignedToLoader] = useState(false);
    const [bulkUpdateDetails, setBulkUpdateDetails] = useState({ statusId: 0, assignedTo: 0, taskIds: '' } as {
        statusId: number;
        assignedTo: number;
        taskIds: string;
        assignedGroup: number;
    });
    const { push } = useRouter();
    const { t } = useTranslation();
    const filterString = JSON.stringify(filter);
    useEffect(() => {
        getVerseList(JSON.parse(filterString));
    }, [filterString, currentProject?.id]);
    const onPageChange = (pageNumber: number) => {
        dispatch({ type: TASK_MANAGEMENT_FILTER, payload: { page: pageNumber } });
    };
    const isRowSelected = () => isAllRowSelected || selectedRows.length > 0;
    const bulkUpdate = (type: string) => {
        if (bulkUpdateDetails.statusId || bulkUpdateDetails.assignedTo || bulkUpdateDetails.assignedGroup) {
            if (isRowSelected()) {
                const payload: UpdateBulkChapterType = {
                    isAllRowSelected,
                    selectedRows,
                    projectId: Number(getCurrentProject(filterComponent.taskManagement).id) || 1,
                    unSelectedRows,
                    assignedTo: Number(filter.assignedTo) || 0,
                    bookListId: Number(filter.bookListId) || 0,
                    chapterId: Number(filter.chapterId) || 0,
                    statusId: Number(filter.statusId) || 0,
                    search: filter.search
                };
                if (type === 'Status') {
                    if (bulkUpdateDetails.statusId) {
                        payload.statusIdUpdate = bulkUpdateDetails.statusId;
                        setStatusLoader(true);
                    }
                }
                if (type === 'AssignedTo') {
                    payload.assignedToUpdate = bulkUpdateDetails.assignedTo;
                    setAssignedToLoader(true);
                }
                if (type === 'AssignedGroup') {
                    if (bulkUpdateDetails.assignedGroup) {
                        payload.grouprelationsIdToUpdate = bulkUpdateDetails.assignedGroup;
                    }
                }
                updateVerseBulkAction(payload).then((res) => {
                    if (res) {
                        setSelectedRows([]);
                        setUnSelectedRows([]);
                        setIsAllRowSelected(false);
                        getVerseList({ ...filter, page });
                        store.dispatch({ type: BOOK_DETAILS, payload: { ...bookDetails, chapters: [] } });
                        store.dispatch({ type: RESET_BOOK, payload: { ...initialResetBook, resetTrello: true } });
                        getNewDataInAllPages();
                        handleToast({ message: res }, 'success');
                    }
                    setAssignedToLoader(false);
                    setStatusLoader(false);
                });
            } else {
                handleToast({ message: t('task-management.select-row-warning') });
            }
        } else {
            handleToast({ message: t('task-management.choose-option-warning') });
        }
    };
    const onSelectRow = (taskIdArg: number) => {
        const taskId = Number(taskIdArg);
        const tempArr = selectedRows;
        const itemIndex = tempArr.findIndex((id: number) => id === taskId);
        if (itemIndex === -1) {
            tempArr.push(taskId);
        } else {
            tempArr.splice(itemIndex, 1);
        }
        if (isAllRowSelected) {
            setUnSelectedRows((preProp) => [...preProp, taskId]);
        }
        setSelectedRows(tempArr);
    };
    // eslint-disable-next-line consistent-return
    const isTaskSelected = (taskId: number) => {
        if (isAllRowSelected) {
            if (!_.find(unSelectedRows, (id: number) => id === taskId)) {
                return true;
            }
        } else if (_.find(selectedRows, (id: number) => id === taskId)) {
            return true;
        }
    };
    return (
        <>
            <TableFilter component={filterComponent.taskManagement} />
            <Col className="mx-0 px-0">
                <Card className="shadow-none pl-3 pr-4">
                    <Card.Body className="p-0 overflow-hidden">
                        <Table responsive hover bordered className="text-center">
                            <thead>
                                <tr>
                                    {checkIsAdminOrDs() && showTableAction ? (
                                        <th className="py-2 w-10">{t('task-management.actions')}</th>
                                    ) : (
                                        ''
                                    )}
                                    <th className="py-2 w-10">{t('task-management.task-path')}</th>
                                    <th className="py-2">{t('task-management.task')}</th>
                                    <th className="py-2">{t('task-management.user-assignment')}</th>
                                    <th className="py-2">{t('task-management.status')}</th>
                                </tr>
                                {checkIsAdminOrDs() && showTableAction && (
                                    <tr>
                                        <th className="py-2 bg-white">
                                            {!isAllRowSelected ? (
                                                <button
                                                    className="btn btn-outline-primary border-0 btn-xs rounded-pill px-2 py-1"
                                                    onClick={() => {
                                                        setIsAllRowSelected(true);
                                                        setUnSelectedRows([]);
                                                    }}
                                                >
                                                    {t('task-management.select-all')}
                                                </button>
                                            ) : (
                                                <button
                                                    className="btn btn-primary btn-xs rounded-pill px-2 py-1"
                                                    onClick={() => {
                                                        setIsAllRowSelected(false);
                                                        setSelectedRows([]);
                                                        setUnSelectedRows([]);
                                                    }}
                                                >
                                                    {t('task-management.select-none')}
                                                </button>
                                            )}
                                        </th>
                                        <th className="bg-white" />
                                        <th className="bg-white" />
                                        <th className="bg-white py-2">
                                            <InputGroup>
                                                <select
                                                    className="form-control form-control-sm"
                                                    disabled={false}
                                                    value={bulkUpdateDetails.assignedTo}
                                                    onChange={(e) => {
                                                        const { value } = e.target;
                                                        setBulkUpdateDetails({
                                                            ...bulkUpdateDetails,
                                                            assignedTo: value === 'clear' ? -1 : Number(e.target.value)
                                                        });
                                                    }}
                                                >
                                                    <option selected key={0} value={0} disabled>
                                                        {t('task-management.select-to-update')}
                                                    </option>
                                                    <option key="clear" value="clear">
                                                        {t('task-management.clear-assignment')}
                                                    </option>
                                                    {userList.map((user: IUser) => (
                                                        <option key={user.id} value={user.id}>
                                                            {user.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                <button
                                                    hidden={!bulkUpdateDetails.assignedTo}
                                                    onClick={() => bulkUpdate('AssignedTo')}
                                                    className="btn btn-outline-primary border-0 btn-sm rounded-pill ml-1"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    title={
                                                        (assignedToLoader
                                                            ? t('task-management.updating')
                                                            : t('task-management.update-task')) as string
                                                    }
                                                >
                                                    {assignedToLoader ? (
                                                        <i className="fa fa-sync-alt fa-spin" />
                                                    ) : (
                                                        <i className="fa fa-sync-alt" />
                                                    )}
                                                </button>
                                            </InputGroup>
                                        </th>
                                        <th className="py-2 bg-white">
                                            <InputGroup className="">
                                                <select
                                                    className="form-control form-control-sm"
                                                    disabled={false}
                                                    value={bulkUpdateDetails.statusId}
                                                    onChange={(e) => {
                                                        setBulkUpdateDetails({
                                                            ...bulkUpdateDetails,
                                                            statusId: Number(e.target.value)
                                                        });
                                                    }}
                                                >
                                                    <option selected key={0} value={0} disabled>
                                                        {t('task-management.select-to-update')}
                                                    </option>
                                                    {statusList?.map((status: { id: number; status: string }) => (
                                                        <option key={status.id} value={status.id}>
                                                            {t(`status.${status.status}`)}
                                                        </option>
                                                    ))}
                                                </select>
                                                <button
                                                    hidden={!bulkUpdateDetails.statusId}
                                                    onClick={() => bulkUpdate('Status')}
                                                    className="btn btn-outline-primary border-0 btn-sm rounded-pill ml-1"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    title={
                                                        (statusLoader
                                                            ? t('task-management.updating')
                                                            : t('task-management.update-task')) as string
                                                    }
                                                >
                                                    {statusLoader ? (
                                                        <i className="fa fa-sync-alt fa-spin" />
                                                    ) : (
                                                        <i className="fa fa-sync-alt" />
                                                    )}
                                                </button>
                                            </InputGroup>
                                        </th>
                                    </tr>
                                )}
                            </thead>
                            <tbody>
                                {verseList?.map((verse: VerseObj) => (
                                    <tr key={verse.id} className="">
                                        {checkIsAdminOrDs() && showTableAction ? (
                                            <td className="py-1">
                                                <div className="row px-3">
                                                    <input
                                                        className="custom-checkbox c-pointer mx-1"
                                                        type="checkbox"
                                                        name="selectRow"
                                                        checked={isTaskSelected(Number(verse.id))}
                                                        onClick={() => {
                                                            onSelectRow(Number(verse.id));
                                                        }}
                                                    />
                                                    <i
                                                        className="fas fa-history text-primary mx-1 mt-2 action-icon-task-table"
                                                        onClick={() => getTaskHistory(Number(verse.id))}
                                                        data-toggle="tooltip"
                                                        data-placement="bottom"
                                                        title={t('task-management.view-history') as string}
                                                    />
                                                    {verse.userName ? (
                                                        <i
                                                            className="fa fa-pen text-primary mx-1 mt-2 action-icon-task-table"
                                                            onClick={() => {
                                                                const filterObj = {
                                                                    bookListId: verse?.bookListId || 1,
                                                                    chapterId: verse?.chaptersId || 1,
                                                                    versesId: verse?.id || 1,
                                                                    projectId: currentProject.id
                                                                };
                                                                dispatch({
                                                                    type: CURRENT_PROJECT,
                                                                    payload: currentProject
                                                                });
                                                                if (filter?.isAssignedMe === true && verse.userName !== myProfile.name) {
                                                                    toggleAssignedToMe(false);
                                                                }
                                                                push(
                                                                    `/workspace?bookListId=${filterObj.bookListId}&chapterId=${filterObj.chapterId}&verseId=${filterObj.versesId}&projectId=${filterObj.projectId}`
                                                                );
                                                            }}
                                                            data-toggle="tooltip"
                                                            data-placement="bottom"
                                                            title={t('task-management.edit-task') as string}
                                                        />
                                                    ) : null}
                                                </div>
                                            </td>
                                        ) : (
                                            ''
                                        )}
                                        <td className="text-center py-1">
                                            <span className="badge badge-light-primary mt-1">
                                                {formBookPath(verse)}
                                            </span>
                                        </td>
                                        <td className="text-left py-1 " title={`${t('task-management.verse')}: ${verse.verse}`}>
                                            <span className="text-truncate">{verse.verse}</span>
                                        </td>
                                        <td className="py-1" title={`${t('task-management.assigned-to')}: ${verse.userName}`}>
                                            <span className="text-truncate">{verse.userName}</span>
                                        </td>
                                        <td className="text-center py-1">
                                            <i
                                                className={`fa fa-circle ${getWordStatusClass(verse.statusId)}`}
                                                style={{ color: getStatusPercentage(verse.statusId).color }}
                                            >
                                                {' '}
                                            </i>{' '}
                                            {t(`status.${_.find(statusList, { id: verse.statusId })?.status || (statusList && statusList[0]?.status)}`)}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <div className="row d-flex justify-content-center">
                            <div className="col-auto mt-1">
                                {taskPaginationLoader ? (
                                    <Loader size={10} />
                                ) : (
                                    <ReactPagination pagination={{ count, limit, page, totalPages }} onPageChange={onPageChange} />
                                )}
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </>
    );
});
export default TaskTableView;

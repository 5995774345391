export const cryptoKEY = 'dWnpwdAA85131Is6mVHlXY';

const Config = {
    defaultPath: '/',
    basename: '/', // only at build time to set, like ///able-pro/react/default
    layout: 'vertical', // vertical, horizontal
    subLayout: '', // horizontal-2
    collapseMenu: true, // mini-menu
    layoutType: 'menu-light', // menu-dark, menu-light, dark
    headerBackColor: 'background-grd-blue', // background-blue, background-red, background-purple, background-info, background-green, background-dark, background-grd-blue, background-grd-red, background-grd-purple, background-grd-info, background-grd-green, background-grd-dark, background-img-1, background-img-2, background-img-3, background-img-4, background-img-5, background-img-6
    rtlLayout: false,
    navFixedLayout: false,
    headerFixedLayout: false,
    boxLayout: false
};
let baseUrl = 'https://dev.data-collector.avodah.ai/api';
if (process.env.REACT_APP_baseUrl) {
    baseUrl = process.env.REACT_APP_baseUrl || 'http://localhost:5000';
}

let gtsBaseUrl = 'https://text.gts.appserve.io/ml-service/';
if (process.env.REACT_APP_GTS_baseUrl) {
    gtsBaseUrl = process.env.REACT_APP_GTS_baseUrl || 'http://localhost:5001';
}

let plotBaseUrl = '';
if (process.env.REACT_APP_PLOT_baseUrl) {
    plotBaseUrl = process.env.REACT_APP_PLOT_baseUrl || 'http://localhost:5002';
}

let logRocketID = '';
if (process.env.REACT_APP_logRocketId) {
    logRocketID = process.env.REACT_APP_logRocketId || '';
}

const imagePath = baseUrl + '/files';
const requestHeader = {
    headers: {
        Authorization: sessionStorage.getItem('generator-token') || ''
    }
};
export { baseUrl, gtsBaseUrl, imagePath, logRocketID, plotBaseUrl, requestHeader };
export default Config;

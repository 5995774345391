/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import logoDark from '../assets/images/logo-dark.png';
import '../assets/scss/style.scss';
import { passwordResetRequest } from '../store/actions/index';
import { decryptData, handleToast, isValidEmail } from '../utils/handler';

interface loginForm {
    email: string;
}
export function ForgetPassword() {
    const [userDetails, setUserDetails] = useState<loginForm>({
        email: ''
    });
    const { t, i18n } = useTranslation();
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name } = e.target;
        const { value } = e.target;
        setUserDetails({
            ...userDetails,
            [name]: value
        });
    };
    useEffect(() => {
        const rememberMe = localStorage.getItem('generator-remember');
        if (rememberMe === 'true') {
            const email = localStorage.getItem('generator-email') || '';
            setUserDetails({
                ...userDetails,
                email: decryptData(email)
            });
        }
    }, []);
    const submitLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        const { email = '' } = userDetails;
        const isValid = isValidEmail(email);
        if (!isValid) {
            handleToast({ message: t('forgot-password.invalid-email-error') }, 'error');
            return;
        }
        const result = await passwordResetRequest({ email, locale: i18n.language });
        if (result) handleToast({ message: t('forgot-password.email-sent-notification') }, 'success');
    };
    return (
        <div className="auth-wrapper">
            <div className="auth-content">
                <div className="card">
                    <div className="row align-items-center text-center">
                        <div className="col-md-12">
                            <div className="card-body">
                                <form noValidate onSubmit={submitLogin}>
                                    <img src={logoDark} alt="" className="img-fluid mb-4" />
                                    <h4 className="mb-3 f-w-400">{t('forgot-password.reset-password')}</h4>
                                    <div className="d-flex align-items-start">
                                        <small className="text-muted mb-2 text-align-start">
                                            {t('forgot-password.description')}
                                        </small>
                                    </div>
                                    <div className="form-group fill mb-4">
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            placeholder={t('forgot-password.email-placeholder') as string}
                                            // eslint-disable-next-line jsx-a11y/no-autofocus
                                            autoFocus
                                            name="email"
                                            value={userDetails.email}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <button className="btn btn-block btn-primary mt-4" onSubmit={submitLogin}>
                                        {t('forgot-password.submit')}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ForgetPassword;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import TextareaAutosize from 'react-textarea-autosize';
import { ReferenceVerses } from '../../components/common';
import { translatedWordsFreeEdit } from '../../store/actions/translated.words.action';
import { updateVerseAction } from '../../store/actions/verse.actions';
import { RootState } from '../../store/reducer';
import { eventKeys } from '../../store/reducers';
import errorMessages from '../../utils/errorMessages';
import { getSourceLanguage, getTargetLanguage, getTaskStatus, handleToast, referenceVerseType } from '../../utils/handler';
import { TranslatedContainer } from '../viewTypes/task.types';
import { nextVerse, replaceBookDetail } from './handler';

function Edit() {
    const [editValue, setEditValue] = useState<string>('');
    const [translatedVerse, setTranslatedVerse] = useState<string>('');
    const { selectedVerse, selectedVerseIndex } = useSelector((state: RootState) => state.workspaceReducer);
    const { t } = useTranslation();
    const { currentProject } = useSelector((state: RootState) => state.mainReducer);
    const targetContainers: TranslatedContainer[] = selectedVerse?.translatedContainers || [];
    const isFreeEditAvailable = getTaskStatus(selectedVerse) <= 2;
    useEffect(() => {
        const translatedVerseStatic = targetContainers.map((container: TranslatedContainer) => container.word).join(' ');
        if (selectedVerse?.translatedVerses && selectedVerse.translatedVerses[0]) {
            const translatedVerseValue = translatedVerseStatic || selectedVerse.translatedVerses[0].verse;
            setTranslatedVerse(translatedVerseValue || "");
            setEditValue(translatedVerseStatic);
        }
    }, [selectedVerse]);
    const updateStatus = (versesId: number = selectedVerse.id, projectId: number = currentProject.id, statusId: number = 3) => {
        updateVerseAction({
            versesId,
            projectId,
            statusId
        }).then((result) => {
            if (result) {
                selectedVerse.activeTab = eventKeys.translate;
                selectedVerse.taskActions = [result];
                if (statusId === 3) {
                    selectedVerse.activeTab = eventKeys.structure;
                    replaceBookDetail({ cIndex: 0, vIndex: selectedVerseIndex, data: selectedVerse });
                    nextVerse();
                } else {
                    replaceBookDetail({ cIndex: 0, vIndex: selectedVerseIndex, data: selectedVerse });
                }
            }
        });
    };
    const onDone = () => {
        if (translatedVerse) {
            let translatedVerseId = 0;
            selectedVerse.translatedVerses = selectedVerse.translatedVerses || [];
            if (selectedVerse?.translatedVerses && selectedVerse?.translatedVerses[0]) {
                translatedVerseId = selectedVerse?.translatedVerses[0]?.id;
            }
            if (editValue === translatedVerse) {
                updateStatus();
            } else {
                translatedWordsFreeEdit({
                    translatedVerse,
                    versesId: selectedVerse.id,
                    translatedVerseId,
                    languageVersionId: currentProject.targetLanguageVersionId
                })
                    .then((result) => {
                        if (result) {
                            selectedVerse.translatedVerses[0] = result.translatedVerse;
                            selectedVerse.translatedContainers = result.containers;
                            selectedVerse.taskActions = [result.taskAction];
                            selectedVerse.wordMappings = [];
                            updateStatus();
                        }
                    })
                    .then(() => {
                        handleToast({ message: t('Translate Verse Succeed!') }, 'success');
                    });
            }
        } else {
            handleToast({ message: errorMessages.emptyTranslation });
        }
    };
    return (
        <Col>
            <Card>
                {selectedVerse.verseAccess && <Card.Header className="bg-light-primary py-1">
                    <Row className="pl-2 pr-2">
                        {isFreeEditAvailable ? <Button size="sm" variant="primary" onClick={() => onDone()}>
                            <i className="fa fa-check mr-2" />{t('workspace.done')}
                        </Button> : <p className="ml-3 my-0">{t('workspace.edited-already')}</p>}
                    </Row>
                </Card.Header>}
                < Card.Body className="overflow-hidden">
                    <ReferenceVerses type={referenceVerseType.typeSource} />
                    <div className=' mb-3'>
                        <div className='d-flex'>
                            <div className="d-flex align-items-center justify-content-end  w-130">
                                <span className="badge badge-light-dark mt-1">{getSourceLanguage()}</span>
                            </div>
                            <div className="py-1 w-100  ml-2 "><span>{selectedVerse?.verse}</span></div>
                        </div>
                        <div className='d-flex mt-3'>
                            <div className="d-flex align-items-center justify-content-end  w-130">
                                <span className="badge badge-light-primary mt-2">{getTargetLanguage()}</span>
                            </div>
                            <div className=' w-100'>
                                <TextareaAutosize
                                    minRows={1}
                                    maxRows={6}
                                    className="bg-transparent  form-control pl-10"
                                    placeholder={t('workspace.free-edit-placeholder')}
                                    value={translatedVerse}
                                    onChange={(e) => setTranslatedVerse(e.target.value)}
                                    readOnly={!isFreeEditAvailable || !selectedVerse.verseAccess}
                                />
                            </div>
                        </div>
                    </div>
                    <ReferenceVerses type={referenceVerseType.typeTarget} />
                </Card.Body>
            </Card>
        </Col >
    );
}
export default Edit;

import axios from 'axios';
import { baseUrl, requestHeader } from '../../config';
import { filterComponent, getCurrentProject } from '../../utils/handler';
import store from '../store';
import { BIBLE_FILTER, FILTER_LOADING, TASK_MANAGEMENT_FILTER_DATA } from './types';
let globalFilter: {
    languageVersionId?: any;
    bookId?: number;
    chapterId?: number;
    currentProjectId?: number;
} = {};
export const getTrelloList = async (filters: any) => {
    filters.projectId = getCurrentProject().id;
    filters.versesId = filters.versesId || filters.verseId;
    try {
        const { data } = await axios.get(baseUrl + '/getVisualTickets', {
            headers: requestHeader.headers,
            params: filters
        });
        return data;
    } catch (error) {
        console.log(error);
    }
};
export interface TrelloPayload {
    statusId: number;
    projectId: number;
    assignedTo?: number;
    versesId: number;
}
interface getTrelloFilterPayload {
    bookId?: number;
    bookListId?: number;
    chapterId?: number;
    verseId?: number;
    isWizard?: boolean;
    isAssignedMe?: boolean;
}
export const updateTrelloTicketStatus = async ({ statusId, assignedTo, versesId }: TrelloPayload) => {
    let projectId = getCurrentProject().id;
    try {
        const result = await axios.patch(`${baseUrl}/updateVerseAction`, { statusId, assignedTo, versesId, projectId }, requestHeader);
        return result;
    } catch (error) {
        console.log(error);
    }
};
export const getTrelloTicketFilters = async (params?: getTrelloFilterPayload, component = "") => {
    try {
        let currentProject = getCurrentProject(component);
        params = params || store.getState().taskReducer.filter;
        let newParam = {
            languageVersionId: currentProject.sourceLanguageVersionId,
            bookId: params?.bookListId || undefined,
            chapterId: params?.chapterId || undefined,
            isWizard: params?.isWizard || undefined,
            isAssignedMe: params?.isAssignedMe || undefined,
            currentProjectId: currentProject.id
        };
        if (JSON.stringify(newParam) !== JSON.stringify(globalFilter)) {
            globalFilter = newParam;
            store.dispatch({ type: FILTER_LOADING, payload: true });
            const { data } = await axios.get(`${baseUrl}/visual-tickets-filter`, {
                ...requestHeader,
                params: newParam
            });
            if (component === filterComponent.taskManagement) {
                store.dispatch({ type: TASK_MANAGEMENT_FILTER_DATA, payload: data });
            } else {
                store.dispatch({ type: BIBLE_FILTER, payload: data });
            }
            store.dispatch({ type: FILTER_LOADING, payload: false });
            return data;
        }
    } catch (error) {
        console.log(error);
        store.dispatch({ type: FILTER_LOADING, payload: false });
    }
};

import Axios from 'axios';
import _ from 'lodash';
import { baseUrl, requestHeader } from '../../config';
import { filterComponent, getCurrentProject, getCurrentProjectId } from '../../utils/handler';
import store from '../store';
import { CatchReturnError } from './actionHandler';
import { CURRENT_PROJECT, LANGUAGE_VERSION_LIST, PROJECT_LIST, TASK_MANAGEMENT_CURRENT_PROJECT } from './types';

export const getLanguageVersionList = async () => {
    Axios.get(`${baseUrl}/getLanguageVersionList`, requestHeader)
        .then((res) => store.dispatch({ type: LANGUAGE_VERSION_LIST, payload: res.data }))
        .catch((err) => CatchReturnError(err));
};
export const getProjectList = async () => Axios.get(`${baseUrl}/projects`, requestHeader)
    .then((res) => {
        const { data } = res;
        store.dispatch({ type: PROJECT_LIST, payload: data });
        let projectIndex = 0;
        if (!_.isEmpty(getCurrentProject())) {
            projectIndex = _.findIndex(data, { id: getCurrentProjectId() });
        }
        const currentProject = data.length ? data[projectIndex] : { id: 0 };
        store.dispatch({ type: CURRENT_PROJECT, payload: currentProject });
        if (_.isEmpty(getCurrentProject(filterComponent.taskManagement))) {
            store.dispatch({ type: TASK_MANAGEMENT_CURRENT_PROJECT, payload: currentProject });
        }
        return data;
    })
    .catch((err) => {
        CatchReturnError(err);
    });
export const createProjectAction = async (name: string, sourceLanguageVersionId: number, targetLanguageId: number) => Axios.post(`${baseUrl}/projects`, { name, sourceLanguageVersionId, targetLanguageId }, requestHeader)
    .then((res) => {
        getLanguageVersionList();
        getProjectList();
        return res.data;
    })
    .catch((err) => CatchReturnError(err));
export const updateProjectAction = async (projectId: number, name: string, sourceLanguageVersionId: number, targetLanguageId: number) => Axios.patch(`${baseUrl}/projects/${projectId}`, { name, sourceLanguageVersionId, targetLanguageId }, requestHeader)
    .then((res) => {
        getLanguageVersionList();
        getProjectList();
        return res.data;
    })
    .catch((err) => CatchReturnError(err));
export const removeProjectAction = async (projectId: number) => Axios.delete(`${baseUrl}/projects/${projectId}`, requestHeader)
    .then((res) => {
        getProjectList();
        return res;
    })
    .catch((err) => CatchReturnError(err));
export const assignNewGroupToProject = async (projectId: number, userGroupId: number) => Axios.post(
    `${baseUrl}/usergroup-assigned-project`,
    {
        projectId,
        userGroupId
    },
    requestHeader
)
    .then((res) => {
        getProjectList();
        return res;
    })
    .catch((err) => CatchReturnError(err));
export const removeAssignedGroupFromProject = async (projectId: number, userGroupId: number) => Axios.delete(`${baseUrl}/usergroup-assigned-project/${projectId}/${userGroupId}`)
    .then((res) => {
        getProjectList();
        return res;
    })
    .catch((err) => CatchReturnError(err));

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import TaskTableView from '../components/taskTable';
import { UPDATE_BREADCRUMBS } from '../store/actions/types';

function Task() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({
            type: UPDATE_BREADCRUMBS,
            payload: []
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <TaskTableView showTableAction />
    );
}
export default Task;

/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import { useEffect, useState } from 'react';
import {
    Button, ButtonToolbar, Card, Col, Row, Tab, ToggleButtonGroup,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Xwrapper } from 'react-xarrows';
import errorMessages from 'utils/errorMessages';
import { ReferenceVerses, SourceLanguageCell, TargetLanguageCell } from '../../components/common';
import { updateMapping } from '../../store/actions/index';
import { SHOW_LOADING_WINDOW } from '../../store/actions/types';
import { updateVerseAction } from '../../store/actions/verse.actions';
import { RootState } from '../../store/reducer';
import { eventKeys } from '../../store/reducers';
import generateRandomColor from '../../utils/generateRandomColor';
import {
    getMappingsObject, getTaskStatus, handleToast, referenceVerseType,
} from '../../utils/handler';
import {
    Mapping,
    Task,
    TranslatedContainer, Verse
} from '../viewTypes/task.types';
import { MappingObj } from '../viewTypes/words.type';
import ArrowGroup from './ArrowGroup';
import { nextVerse, replaceBookDetail } from './handler';

function MappingPage() {
    const { currentProject } = useSelector((state: RootState) => state.mainReducer);
    const { selectedVerse, selectedVerseIndex } = useSelector((state: RootState) => state.workspaceReducer);
    const [connections, setConnections] = useState<Array<Mapping>>([]);
    const [translatedVerseObj, setTranslatedVerseObj] = useState<Verse>();
    const [mappingProgress, setMappingProgress] = useState<boolean>(false);
    const [selectedSourceContainers, setSelectedSourceContainers] = useState<Array<number>>([]);
    const [selectedDestinationContainers, setSelectedDestinationContainers] = useState<Array<number>>([]);
    const translatedLanguageVersionId = currentProject.targetLanguageVersionId;
    const translatedContainers: TranslatedContainer[] = selectedVerse?.translatedContainers || [];
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const checkMappingIdExist = (endId: number) => _.find(selectedVerse.translatedContainers, { id: endId });
    const paintMappings = (mappings: Array<MappingObj>) => {
        const existingColorTable = new Map<string, string>();
        const newColorTable = new Map<string, string>();
        connections.forEach((mapping: MappingObj) => {
            if (mapping.color) {
                existingColorTable.set(`start-${mapping.start}`, mapping?.color);
                existingColorTable.set(`end-${mapping.end}`, mapping?.color);
            }
        });
        const newMappings: MappingObj[] = [];
        mappings.forEach((mapping) => {
            if (checkMappingIdExist(mapping.end) && !_.find(
                newMappings,
                { start: mapping.start, end: mapping.end },
            )) newMappings.push(mapping);
        });
        newMappings.sort((mapping1: MappingObj, mapping2: MappingObj) => {
            if (mapping1.start < mapping2.start) return -1;
            if (mapping1.start > mapping2.start) return 1;
            if (mapping1.end < mapping2.end) return -1;
            if (mapping1.end > mapping2.end) return 1;
            return 0;
        });
        return newMappings.map((mapping: MappingObj) => {
            const newStartColor = newColorTable.get(`start-${mapping.start}`);
            const newEndColor = newColorTable.get(`end-${mapping.end}`);
            const existingStartColor = existingColorTable.get(`start-${mapping.start}`);
            const existingEndColor = existingColorTable.get(`end-${mapping.end}`);
            const color = newStartColor || newEndColor || existingStartColor
                || existingEndColor || generateRandomColor();
            newColorTable.set(`start-${mapping.start}`, color);
            newColorTable.set(`end-${mapping.end}`, color);
            return {
                mappingID: mapping.mappingID,
                start: mapping.start,
                end: mapping.end,
                color,
            };
        });
    };
    useEffect(() => {
        const initMapping = async () => {
            const filteredMaps = selectedVerse?.wordMappings || [];
            setConnections(paintMappings(getMappingsObject(filteredMaps || [])));
        };
        initMapping();
        const tmpTranslatedVerseObj = _.find(
            selectedVerse?.translatedVerses || [],
            { languageVersionId: translatedLanguageVersionId },
        ) || {};
        setTranslatedVerseObj(tmpTranslatedVerseObj);
    }, [currentProject.id, selectedVerse]);
    if (!selectedVerse?.translatedVerses) {
        <Col>
            <Card>
                <Card.Header className="bg-light-primary py-1" />
                <Card.Body className="overflow-hidden">
                    <Row>
                        <h4 className="ml-5">{t('workspace.this-verse-is-not-to-be-ready-for-mapping')}</h4>
                    </Row>
                </Card.Body>
            </Card>
        </Col>;
    }
    const onSelectSource = async (sourceContainerId: number) => {
        if (selectedDestinationContainers.length === 0) {
            // --- Multi select source
            const containerIndex = _.findIndex(
                selectedSourceContainers,
                (containerId) => containerId === sourceContainerId,
            );
            let sourceContainerList: number[] = [...selectedSourceContainers];
            if (containerIndex >= 0) {
                sourceContainerList.splice(containerIndex, 1);
            } else if (mappingProgress) {
                sourceContainerList = [sourceContainerId];
                setMappingProgress(false);
            } else {
                sourceContainerList.push(sourceContainerId);
            }
            setSelectedSourceContainers(sourceContainerList);
        } else {
            setMappingProgress(true);
            const newConnection: MappingObj[] = [...connections];
            selectedDestinationContainers.forEach((destinationId) => {
                const connectObj: MappingObj = {
                    mappingID: 0,
                    start: sourceContainerId,
                    end: destinationId,
                };
                if (selectedDestinationContainers.length === 1) {
                    const isExist = _.findIndex(
                        connections,
                        (data) => data.start === connectObj.start && data.end === connectObj.end,
                    );
                    if (isExist >= 0) {
                        newConnection.splice(isExist, 1);
                    } else {
                        newConnection.push(connectObj);
                    }
                } else {
                    newConnection.push(connectObj);
                }
            });
            setConnections(paintMappings(newConnection));
        }
    };
    const onSelectTranslation = async (destinationContainerId: number) => {
        if (selectedSourceContainers.length === 0) {
            // --- Multi select target
            const containerIndex = _.findIndex(
                selectedDestinationContainers,
                (containerId) => containerId === destinationContainerId,
            );
            let destinationContainerList: number[] = [...selectedDestinationContainers];
            if (containerIndex >= 0) {
                destinationContainerList.splice(containerIndex, 1);
            } else if (mappingProgress) {
                destinationContainerList = [destinationContainerId];
                setMappingProgress(false);
            } else {
                destinationContainerList.push(destinationContainerId);
            }
            setSelectedDestinationContainers(destinationContainerList);
        } else {
            setMappingProgress(true);
            const newConnection: MappingObj[] = [...connections];
            selectedSourceContainers.forEach((sourceContainerId) => {
                const connectObj = {
                    mappingID: 0,
                    start: sourceContainerId,
                    end: destinationContainerId,
                };
                if (selectedSourceContainers.length === 1) {
                    const isExist = _.findIndex(connections, (data) => data.start === connectObj.start
                        && data.end === connectObj.end);
                    if (isExist >= 0) {
                        newConnection.splice(isExist, 1);
                    } else {
                        newConnection.push(connectObj);
                    }
                } else {
                    newConnection.push(connectObj);
                }
            });
            setConnections(paintMappings(newConnection));
        }
    };
    const updateStatus = async (
        versesId: number = selectedVerse.id,
        projectId: number = currentProject.id,
        statusId: number = 5,
    ) => {
        let result = selectedVerse?.taskActions[0];
        // -- checking if newly added or removed mappings
        if (!translatedVerseObj) { handleToast({ message: errorMessages.dataNotFound }); return true; }
        if (connections.length !== selectedVerse?.wordMappings?.length) {
            dispatch({ type: SHOW_LOADING_WINDOW, payload: true });
            const mappingResult = await updateMapping({
                verseId: translatedVerseObj?.id,
                mappings: connections,
                languageVersionId: translatedLanguageVersionId,
            });
            dispatch({ type: SHOW_LOADING_WINDOW, payload: false });
            selectedVerse.wordMappings = mappingResult;
        }
        if (getTaskStatus(selectedVerse) <= 4) {
            result = await updateVerseAction({
                versesId,
                projectId,
                statusId,
            });
        }
        if (result) {
            selectedVerse.taskActions = [result];
            replaceBookDetail({ cIndex: 0, vIndex: selectedVerseIndex, data: selectedVerse });
            nextVerse();
        }
        return true;
    };
    return (
        <Row className="px-3">
            <Card>
                <Tab.Container defaultActiveKey="mapping-advanced">
                    {selectedVerse.verseAccess && (
                        <Card.Header className="bg-light-primary py-1">
                            <Row>
                                <Col className="col-auto">
                                    <ButtonToolbar>
                                        <ToggleButtonGroup type="radio" name="options" size="sm" defaultValue={[1]}>
                                            <Button
                                                size="sm"
                                                variant="danger"
                                                onClick={async () => {
                                                    await updateVerseAction({
                                                        versesId: selectedVerse.id,
                                                        projectId: currentProject.id,
                                                        statusId: 3,
                                                    }).then((result) => {
                                                        if (result) {
                                                            selectedVerse.taskActions = [result];
                                                            selectedVerse.activeTab = eventKeys.structure;
                                                            replaceBookDetail({
                                                                cIndex: 0,
                                                                vIndex: selectedVerseIndex,
                                                                data: selectedVerse,
                                                            });
                                                        }
                                                    });
                                                }}
                                            >
                                                <i className="fa fa-undo mr-2" />
                                                {t('workspace.reject')}
                                            </Button>
                                            <Button
                                                size="sm"
                                                variant="primary"
                                                onClick={async () => {
                                                    updateStatus();
                                                }}
                                            >
                                                <i className="fa fa-check mr-2" />
                                                {t('workspace.done')}
                                            </Button>
                                        </ToggleButtonGroup>
                                    </ButtonToolbar>
                                </Col>
                            </Row>
                        </Card.Header>
                    )}
                    <Tab.Content>
                        <Tab.Pane eventKey="mapping-advanced">
                            <Card.Body className="overflow-hidden">
                                <ReferenceVerses type={referenceVerseType.typeSource} />
                                <Tab.Container defaultActiveKey="v1">
                                    <Row>
                                        <Col>
                                            <Tab.Content className="overflow-none">
                                                <Tab.Pane eventKey="v1">
                                                    <table
                                                        className="b-spacing table table-responsive
                                                        table-xs table-bordered table-hover border-0 mb-0
                                                        overflow-scroll table table-responsive mb-3"
                                                        style={{ position: 'relative' }}
                                                    >
                                                        <Xwrapper>
                                                            <tbody >
                                                                <tr>
                                                                    <SourceLanguageCell />
                                                                    {selectedVerse?.tasks?.map((taskInfo: Task) => (
                                                                        <td key={taskInfo.id}
                                                                            className={`text-center px-1 ${selectedSourceContainers?.includes(taskInfo.id)
                                                                                ? 'selectableItem'
                                                                                : ''
                                                                                }
                                                                            ${selectedVerse.verseAccess
                                                                                    ? ' cursor-pointer ' : null}`}
                                                                            id={`source-${taskInfo.id}`}
                                                                            onClick={() => onSelectSource(taskInfo.id)}
                                                                        >
                                                                            {taskInfo.word}
                                                                        </td>
                                                                    ))
                                                                    }
                                                                </tr >
                                                                <tr>
                                                                    <td className="w-10 px-1 py-0 text-right">
                                                                        <span className="badge badge-danger my-2">
                                                                            {t('workspace.mapping')}
                                                                        </span>
                                                                    </td>
                                                                    <td
                                                                        colSpan={100}
                                                                        className="align-middle text-center"
                                                                        style={{ height: '100px' }}
                                                                    >
                                                                        {connections.length ? (
                                                                            ''
                                                                        ) : (
                                                                            <>
                                                                                <i className="fa fa-network-wired
                                                                                fa-lg my-4 pr-3"
                                                                                />
                                                                                {t('workspace.mapping-goes-here')}
                                                                            </>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <TargetLanguageCell />
                                                                    {translatedContainers?.map(
                                                                        (
                                                                            container: TranslatedContainer,
                                                                            index: number,
                                                                        ) => (
                                                                            <td
                                                                                className={`px-3 py-2 text-center
                                                                                    ${selectedDestinationContainers
                                                                                        ?.includes(
                                                                                            container.id,
                                                                                        )
                                                                                        ? 'selectableItem'
                                                                                        : ''
                                                                                    }
                                                                                    `}
                                                                                id={`TC${container?.id?.toString()}`}
                                                                                key={container.id}
                                                                                data-tIndex={index}
                                                                                onClick={() => onSelectTranslation(
                                                                                    container.id,
                                                                                )}
                                                                            >
                                                                                {container?.word}
                                                                            </td>
                                                                        ),
                                                                    )}
                                                                    <ArrowGroup
                                                                        connections={connections}
                                                                        sourceTaskId={selectedSourceContainers}
                                                                        destinationTaskId={
                                                                            selectedDestinationContainers
                                                                        }
                                                                    />
                                                                </tr>
                                                            </tbody >
                                                        </Xwrapper >
                                                    </table >
                                                </Tab.Pane >
                                            </Tab.Content >
                                        </Col >
                                    </Row >
                                </Tab.Container >
                                <ReferenceVerses type={referenceVerseType.typeTarget} />
                            </Card.Body >
                        </Tab.Pane >
                    </Tab.Content >
                </Tab.Container >
            </Card >
        </Row >
    );
}
export default MappingPage;

import axios from 'axios';
import { baseUrl, requestHeader } from '../../config';
import { getCurrentProjectId } from '../../utils/handler';
import { TaskFilterType } from '../../views/viewTypes/task.types';
import store from '../store';
import { CatchReturnError } from './actionHandler';
import { DEFINITION_MANAGEMENT_PHRASE, DEFINITION_MANAGEMENT_SINGLE } from './types';

export const getDefinition = async (filterData?: TaskFilterType) => {
    const filters = {
        ...{
            assignedTo: 0,
            bookListId: 0,
            statusId: 0,
            reviewerStatusId: 0,
            reviewerAssignedTo: 0,
            chapterId: 0,
            verseId: 0,
            search: '',
            filterBy: '',
            filterType: '',
            orderBy: 'createdAt',
            orderType: 'ASC',
            limit: 10,
            page: 1,
        },
        ...filterData,
    };
    const URL = `${baseUrl
        }/definition-management?search=${filters.search}
    &limit=${filters.limit}&page=${filters.page}
    &bookListId=${Number(filters.bookListId)}
    &chapterId=${Number(filters.chapterId)}
    &versesId=${Number(filters.verseId)}
    &filterPhrase=${filters.filterPhrase}
    &reviewerAssignedTo=${filters.assignedTo}
    &reviewerStatusId=${filters.statusId}
    &projectId=${getCurrentProjectId()}
    `;
    return axios.get(URL, requestHeader)
        .then((res: { data: unknown }) => {
            const { data } = res;
            if (!filters?.filterPhrase) {
                store.dispatch({ type: DEFINITION_MANAGEMENT_SINGLE, payload: data });
            } else {
                store.dispatch({ type: DEFINITION_MANAGEMENT_PHRASE, payload: data });
            }
            return res.data;
        })
        .catch((err: unknown) => {
            CatchReturnError(err);
        });
};
export const getMeaningsByWord = async (payload: {
    projectId: number, word:
    string, uniqueMeaningId?: number, versesId?: string
}) => {
    const { projectId, word } = payload;
    const url = `${baseUrl}/getMeaningsByWord/${projectId}/${word}`;
    return axios.get(url, { headers: requestHeader.headers, params: payload })
        .then((res: { data: unknown }) => res.data)
        .catch((err: unknown) => {
            CatchReturnError(err);
        });
};

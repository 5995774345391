import Axios from 'axios';
import { baseUrl, requestHeader } from '../../config';
import { getCurrentProjectId } from '../../utils/handler';
import { CreateContainerType } from '../../views/viewTypes/translatedContainer.type';
import { SourceCreate } from '../../views/viewTypes/words.type';
import store from '../store';
import { CatchReturnError } from './actionHandler';
import { GET_GRAMMAR_LIST, HISTORY_MODAL, STATUS_LIST, TASK_PAGINATION, TASK_PAGINATION_LOADER, TOPIC_LIST } from './types';

let globalTaskUrl = '';
export interface createTaskType {
    name: string;
    assignedUserUID: string;
    details: string;
    statementType: string;
    book: string;
    chapter: string;
    verse: string;
}
export const uploadTaskFiles = (file: FormData) => Axios.post(`${baseUrl}/files`, file, requestHeader)
    .then((res) => res.data)
    .catch((err) => {
        CatchReturnError(err);
    });
export const getTaskPagination = (taskURL: string) => {
    globalTaskUrl = taskURL;
    store.dispatch({ type: TASK_PAGINATION_LOADER, payload: true });
    return Axios.get(globalTaskUrl.replace('tasks', 'taskPagination'), requestHeader)
        .then((res) => {
            const pagination = res.data;
            store.dispatch({ type: TASK_PAGINATION, payload: pagination });
        })
        .catch((err) => {
            store.dispatch({ type: TASK_PAGINATION_LOADER, payload: false });
            CatchReturnError(err);
        });
};
// removed @get('/booklist/{id}) due to unused getBookByBooklistId
export const createContainer = async (payload: CreateContainerType) => await Axios.post(`${baseUrl}/createContainer/${getCurrentProjectId()}`, payload, requestHeader)
    .then((res) => res.data)
    .catch((err) => {
        CatchReturnError(err);
    });
export const splitContainer = (taskId: number) => Axios.patch(`${baseUrl}/splitContainer/${taskId}`, {}, requestHeader)
    .then((res) => res.data)
    .catch((err) => {
        CatchReturnError(err);
    });
export const deleteTask = (taskId: number, fromEditor = false, isTarget = true) => Axios.delete(`${baseUrl}/tasks/${taskId}/${fromEditor}/${isTarget}`, requestHeader)
    .then((res) => res.data)
    .catch((err) => {
        CatchReturnError(err);
    });
// Have a language look
export const getTaskHistory = (taskId: number) => {
    const languageId = 1;
    store.dispatch({ type: HISTORY_MODAL, payload: { showHistory: true, showHistoryLoader: true } });
    return Axios.get(`${baseUrl}/getTaskHistory/${taskId}?languageId=${languageId}`, requestHeader)
        .then((res) => {
            store.dispatch({ type: HISTORY_MODAL, payload: { showHistory: true, showHistoryLoader: false, historyDetails: res.data } });
        })
        .catch((err) => {
            CatchReturnError(err);
        });
};
export const meaningsUpdateForWord = (payload: {
    languageId: number;
    uniqueMeaningId: number;
    word: string;
    meaning: string;
    newMeaning: string;
}) => Axios.patch(`${baseUrl}/updateMeaningForWord`, payload, requestHeader)
    .then((res) => res.data)
    .catch((err) => {
        CatchReturnError(err);
    });
export const updateUniqueMeaning = (payload: { uniqueMeaningId: number; targetMeaning: string; tasksId: number }) => Axios.patch(`${baseUrl}/updateUniqueMeaning`, payload, requestHeader)
    .then((res) => res.data)
    .catch((err) => {
        CatchReturnError(err);
    });
export const createTopic = (topic: string) => Axios.post(`${baseUrl}/topics`, { topic }, requestHeader)
    .then((res) => res.data)
    .catch((err) => {
        CatchReturnError(err);
    });
export const createGrammar = (grammar: string) => Axios.post(`${baseUrl}/grammar`, { grammar }, requestHeader)
    .then((res) => {
        const { data } = res;
        const { grammarList } = store.getState().workspaceReducer;
        store.dispatch({ type: GET_GRAMMAR_LIST, payload: [...grammarList, data] });
        return data;
    })
    .catch((err) => {
        CatchReturnError(err);
    });
export const topicsAddByTaskId = (data: any) => {
    const { taskId, topic } = data;
    return Axios.post(`${baseUrl}/topics`, { taskId, topic }, requestHeader)
        .then((res) => res.data)
        .catch((err) => {
            CatchReturnError(err);
        });
};
export const topicsAddBySentenceLevel = (data: any) => {
    const { topic, verse } = data;
    return Axios.post(`${baseUrl}/topics`, { versesId: verse, topic }, requestHeader)
        .then((res) => res.data)
        .catch((err) => {
            CatchReturnError(err);
        });
};
export const topicsListFetchByTaskId = (taskId: number) => Axios.get(`${baseUrl}/topics?taskId=${taskId}`, requestHeader)
    .then((res) => res.data)
    .catch((err) => {
        CatchReturnError(err);
    });
export const fetchAllTopics = () => Axios.get(`${baseUrl}/topics`, requestHeader)
    .then((res) => {
        const { data } = res;
        store.dispatch({ type: TOPIC_LIST, payload: data });
        return data;
    })
    .catch((err) => {
        CatchReturnError(err);
    });
export const getStatusList = () => Axios.get(`${baseUrl}/statusList`, requestHeader)
    .then((res) => {
        const response = res.data;
        store.dispatch({ type: STATUS_LIST, payload: response });
        return response;
    })
    .catch((err) => {
        CatchReturnError(err);
    });
export const addMultipleWordsToTaskById = (payload: { tasksId: number; word: string }) => Axios.post(`${baseUrl}/words`, payload, requestHeader)
    .then((res) => res)
    .catch((err) => {
        CatchReturnError(err);
    });
export const updateMultipleWordsToTaskById = (payload: any) => Axios.patch(`${baseUrl}/words`, payload, requestHeader)
    .then((res) => res.data)
    .catch((err) => {
        CatchReturnError(err);
    });
export const updateSourceWord = (wordId: number, wordObj: SourceCreate) => Axios.patch(`${baseUrl}/updateSourceWord/${wordId}`, wordObj, requestHeader)
    .then((res) => {
        const response = res.data;
        return response;
    })
    .catch((err) => {
        CatchReturnError(err);
    });
export const deleteMeaningImage = (meaningId: number) => Axios.delete(`${baseUrl}/meaning-data/${meaningId}`, requestHeader)
    .then((res) => res.data)
    .catch((err) => {
        CatchReturnError(err);
    });

import CryptoJS from 'crypto-js';
import { createBrowserHistory } from 'history';
import _, { isString } from 'lodash';
import LogRocket from 'logrocket';
import { toast } from 'react-toastify';
import { FILTER_LOADING_TYPE } from 'store/constant';
import { eventKeys } from 'store/reducers';
import {
    baseUrl, cryptoKEY, logRocketID, requestHeader,
} from '../config';
import i18n from '../internationalization/i18n';
import { RouteObject } from '../route';
import {
    fetchAllTopics, getGrammarList, getLanguages, getStatusList, getTrelloTicketFilters,
} from '../store/actions/index';
import { getLanguageVersionList, getProjectList } from '../store/actions/projects.actions';
import * as types from '../store/actions/types';
import {
    fetchUserGroups, getMyProfile, getRolesAction, getUsersAction,
} from '../store/actions/user.action';
import { store } from '../store/store';
import { MeaningObj } from '../views/viewTypes/definition-management.type';
import { ProjectListType } from '../views/viewTypes/project.type';
import {
    Mapping, TaskActions, Verse, WordMappings,
} from '../views/viewTypes/task.types';
import { ContainerType } from '../views/viewTypes/verse.type';
import { MappingObj } from '../views/viewTypes/words.type';
import { getVersesFromBookDetails } from '../views/workspace-wizard/handler';

export const rolesObject = {
    admin: { roleType: 'Admin', id: 1 },
    contributor: { roleType: 'Contributor', id: 2 },
    dataScientist: { roleType: 'Data Scientist', id: 3 },
    reviewer: { roleType: 'Reviewer', id: 4 },
};
export const history: any = createBrowserHistory();
export const localStorageKey = {
    selectedLanguage: 'selectedLanguage',
    currentProject: 'currentProject',
    projectList: 'projectList',
    workspaceData: 'workspaceData',
    filter: 'filter',
    bibleFilter: 'bibleFilter',
    singleDefinitionData: 'singleDefinitionData',
    phraseDefinitionData: 'phraseDefinitionData',
    reduxStore: 'reduxStore',
    localLanguage: 'localLanguage',
};
export const filterComponent = {
    taskManagement: 'taskManagement',
};
export const referenceVerseType = {
    typeSource: 'Source',
    typeTarget: 'target',
};
export const getLocalStorageItem = (item: string, defaultValues?: any) => {
    const localValue = localStorage.getItem(item);
    if (localValue && localValue !== null && localValue !== '' && localValue !== 'undefined') {
        return JSON.parse(localValue);
    }
    return defaultValues;
};
export const setLocalStorageItem = (item: string, value: string) => {
    if (value) return localStorage.setItem(item, value);
};
export const getUserImageURL = (image: string) => {
    if (!image) {
        return '';
    }
    if (image.includes('http')) {
        return image;
    }
    return baseUrl + image;
};
export const meaningForConfig = {
    translationContainer: 'TranslatedContainer',
    taskId: 'taskId',
};
export const getUserById = (id: number) => {
    const { userList } = store.getState().userReducer;
    return _.find(userList, { id });
};
export const getCurrentProject = (component?: string) => {
    if (component === filterComponent.taskManagement) {
        return store.getState().taskManagementReducer.currentProject;
    }
    return store.getState().mainReducer.currentProject;
};
export const getTargetLanguageVersionId = () => getCurrentProject().targetLanguageVersionId;
export const getSourceLanguageVersionId = () => getCurrentProject().sourceLanguageVersionId;
export const getTaskLocationIndex = (wordLocation: string | null | number | undefined) => {
    if (wordLocation) {
        if (typeof wordLocation === 'string') {
            const locationArray = wordLocation?.toString().split(' ');
            const wordLocationIndex = locationArray[locationArray.length - 1];
            return Number(wordLocationIndex);
        }
    }
    return wordLocation;
};
export const cleanParams = (obj: any) => {
    for (const propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '' || !obj[propName]) {
            delete obj[propName];
        }
    }
    return obj;
};
export const loginSignupSuccess = async (res: string) => new Promise(async (resolve, reject) => {
    try {
        if (res) {
            const token = `Bearer ${res}`;
            sessionStorage.setItem('generator-token', token);
            requestHeader.headers.Authorization = token;
            const profile = await getMyProfile();
            if (logRocketID !== '' && profile.role.id !== rolesObject.dataScientist.id) {
                LogRocket.identify(profile.id, {
                    name: profile.name,
                    email: profile.email,
                });
            }
            loadInitialApis();
            resolve(profile);
        }
    } catch (error) {
        reject(error);
    }
});
export const checkIsSourceLanguage = (language: number) => {
    const { languageList } = store.getState().mainReducer;
    const foundLanguage = languageList.find((l: any) => l.id === language);
    if (foundLanguage) {
        return foundLanguage.isSource;
    }
    return language === 1;
};
export const getSourceLanguage = () => {
    const { languageList } = store.getState().mainReducer;
    const project = getCurrentProject();
    const foundLanguage = languageList.find((language: { id: number; language: string }) => language.id === project.sourceLanguageVersion?.targetLanguageId)
        ?.language || '';
    return foundLanguage || 'Source';
};
export const getTargetLanguage = () => {
    const { languageList } = store.getState().mainReducer;
    const project = getCurrentProject();
    const targetLanguage = languageList.find((language: { id: number; language: string }) => language.id === project.targetLanguageVersion?.targetLanguageId)
        ?.language || '';
    return targetLanguage || 'Target';
};
// const validateEmail = (mail: string) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail);
export const isValidEmail = (email: string) => {
    const emailExpression = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return email && emailExpression.test(email);
};
export const isValidPassword = (password: string) => {
    // eslint-disable-next-line no-useless-escape
    const passwordExpression = /(?=.*\d)(?=.*[A-Z])(?=.*[!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]).{8,}/;
    return passwordExpression.test(password);
};
export const isUserLoggedIn = () => {
    if (sessionStorage.getItem('generator-token')) return true;
    return false;
};
export const encryptData = (data: any) => {
    const encryptedValue = CryptoJS.AES.encrypt(JSON.stringify(data), cryptoKEY).toString();
    return encryptedValue || data;
};
export const decryptData = (encryptValue: any) => {
    try {
        encryptValue = encryptValue.replaceAll(' ', '+');
        const decrypted = CryptoJS.AES.decrypt(encryptValue, cryptoKEY);
        const decryptedValue = decrypted.toString(CryptoJS.enc.Utf8);
        return decryptedValue ? JSON.parse(decryptedValue) : encryptValue;
    } catch (error) {
        return encryptValue;
    }
};
export const Logout = () => {
    sessionStorage.removeItem('generator-token');
    requestHeader.headers.Authorization = '';
    store.dispatch({ type: types.GET_MY_PROFILE, payload: {} });
    const rememberMe = localStorage.getItem('generator-remember');
    localStorage.removeItem('myProfile');
    localStorage.removeItem(localStorageKey.currentProject);
    if (rememberMe !== 'true') {
        localStorage.removeItem('generator-email');
        localStorage.removeItem('generator-password');
    }
    LogRocket.startNewSession();
    history.push('/login');
};
export const formBookPath = (verse: { bookName: string, chapter: number, isTitle: boolean, titleTop: boolean, verseNumber: number }) => {
    const { t } = i18n;
    const titleTopCheck = verse.titleTop ? t('task-management.top') : (verse.verseNumber || t('task-management.top'));
    return `${t(`bookList.${verse.bookName}`)} ${verse.chapter === 0 ? `${t('task-management.intro')}  ${verse.isTitle ? `${t('task-management.title')} ${titleTopCheck}` : verse.verseNumber}`
        : `${verse.chapter} ${verse.isTitle ? `${t('task-management.title')} ${titleTopCheck}`
            : verse.verseNumber}`}`;
};
export const handleToast = (payload: { message: any; toastId?: string }, type?: '' | 'success' | 'error' | 'info' | 'warn') => {
    const { t } = i18n;
    try {
        let { message = {}, toastId = '', result = { message: {} } } = { ...payload };
        type = type || 'error';
        message = isString(payload) ? payload : getMessageString(message, result) || 'Something went wrong';
        toastId = toastId || message;
        toast[type](t(message), {
            toastId,
        });
    } catch (error) {
        console.log(error);
    }
};
export const checkIsAdmin = () => checkRole(rolesObject.admin);
export const checkIsReviewer = () => checkRole(rolesObject.reviewer);
export const checkIsReviewerOrDataScientist = () => checkRole(rolesObject.reviewer) || checkRole(rolesObject.dataScientist);
export const checkIsDataScientist = () => checkRole(rolesObject.dataScientist);
export const checkIsContributor = () => checkRole(rolesObject.contributor);
export const checkIsAdminOrDs = () => checkRole(rolesObject.dataScientist) || checkRole(rolesObject.admin);
export const showMenu = (checkAdmin: boolean = false) => {
    if (checkAdmin) {
        return isUserLoggedIn() && checkIsAdmin();
    }
    return true;
};
export const checkIsAdminPage = (route: RouteObject) => {
    if (route.isAdmin) {
        if (isUserLoggedIn() && checkIsAdmin()) {
            return false;
        }
        return false;
    }
    return true;
};
const getMessageString = (message: string | { message?: string }, result: string | { message?: {} }) => {
    let msg = isString(message)
        ? message
        : isString(result)
            ? result
            : isString(result.message)
                ? result.message
                : isString(message.message)
                    ? message.message
                    : message;
    msg = isString(msg) ? msg : 'Something went wrong';
    return msg;
};
export const checkIsImage = (path: string) => path.match(/.(jpg|jpeg|png|gif)$/i);
export const myProfileObj = () => store.getState().userReducer.myProfile;
export const checkRole = (type: any) => {
    const myProfile = myProfileObj();
    return myProfile.role?.id === type.id;
};
export const checkDestinationAccess = (workSpaceTask: TaskActions | undefined) => checkIsAdminOrDs() || (workSpaceTask && workSpaceTask.assignedTo === myProfileObj().id);
export const getMyTaskAction = (taskActions: TaskActions[]): TaskActions | undefined => _.find(taskActions, { projectId: getCurrentProjectId() });
export const checkDestinationAccessTaskActions = (taskActions: TaskActions[]) => checkDestinationAccess(getMyTaskAction(taskActions));
export const scrollDoc = (elementId: string) => {
    const domainNameForm = document.getElementById(elementId);
    if (domainNameForm) {
        domainNameForm.scrollIntoView({
            behavior: 'auto',
            block: 'center',
            inline: 'center',
        });
        setTimeout(() => {
            domainNameForm.focus();
        }, 500);
    }
};
export const loadValues = () => {
    const encryptedProfile = localStorage.getItem('myProfile');
    if (encryptedProfile) store.dispatch({ type: types.GET_MY_PROFILE, payload: decryptData(encryptedProfile) });
};
export const getWordStatusClass = (statusId: number, isHover: boolean = false) => {
    if (statusId === 1) {
        return !isHover ? 'not-started' : 'hover-not-started';
    } if (statusId === 2) {
        return !isHover ? 'in-progress' : 'hover-in-progress';
    } if (statusId === 3) {
        return !isHover ? 'ready-for-review' : 'hover-ready-for-review';
    } if (statusId === 4) {
        return !isHover ? 'completed' : 'hover-completed';
    } if (statusId === 5) {
        return !isHover ? 'rejected' : 'hover-rejected';
    }
    return '';
};
export const getWordStatusClassColor = (statusId: number) => {
    if (statusId === 1) {
        return 'dark border-dark';
    } if (statusId === 2) {
        return 'warning border-warning';
    } if (statusId === 3) {
        return 'primary border-primary';
    } if (statusId === 4) {
        return 'success border-success';
    } if (statusId === 5) {
        return 'danger border-danger';
    }
    return '';
};
export const loadInitialApis = () => {
    const currentProject = getLocalStorageItem(localStorageKey.currentProject);
    const projectList = getLocalStorageItem(localStorageKey.projectList);
    if (currentProject && _.isObject(currentProject)) {
        store.dispatch({
            type: types.CURRENT_PROJECT,
            payload: currentProject,
        });
    }
    if (projectList) {
        store.dispatch({
            type: types.PROJECT_LIST,
            payload: projectList,
        });
    }
    if (isUserLoggedIn()) {
        getStatusList();
        getTrelloTicketFilters();
        getGrammarList();
        fetchAllTopics();
        loadValues();
        getUsersAction();
        getProjectList();
        fetchUserGroups();
        getLanguageVersionList();
        getRolesAction();
        getLanguages();
        loadVerseAccess();
    }
};
export const loadVerseAccess = () => {
    const { bookDetails, selectedVerseIndex } = store.getState().workspaceReducer;
    const verses: Verse[] = getVersesFromBookDetails(bookDetails);
    verses.forEach((verse) => {
        verse.verseAccess = checkVerseAccess(verse.taskActions);
    });
    const selectedVerse = verses[selectedVerseIndex] || {};
    store.dispatch({ type: types.BOOK_DETAILS, payload: bookDetails });
    store.dispatch({ type: types.SELECTED_VERSE, payload: selectedVerse });
};
export const getStatusById = (statusId: number = 1) => {
    const statusList = getAllStatus();
    return _.find(statusList, { id: statusId })?.status || (statusList && statusList[0]?.status);
};
export const getGrammar = (statusId: number) => {
    if (statusId === 1) {
        return 'Noun';
    } if (statusId === 2) {
        return 'Conjunction';
    } if (statusId === 3) {
        return 'Verb';
    } if (statusId === 4) {
        return 'Pronoun';
    } if (statusId === 5) {
        return 'Preposition';
    } if (statusId === 6) {
        return 'Article';
    } if (statusId === 7) {
        return 'Adjective';
    } if (statusId === 8) {
        return 'Adverb';
    } if (statusId === 9) {
        return 'Interjection';
    }
    return '';
};
export const containerTypes = {
    word: 'word',
    grammar: 'grammar',
    action: 'action',
};
export const getCurrentProjectId = () => getCurrentProject().id;
export const checkTranslationAccess = () => checkIsAdminOrDs();
export const checkVerseAccess = (taskActions?: TaskActions[]) => {
    let verseAccess = checkIsAdminOrDs();
    if (!verseAccess && taskActions?.length) {
        const { myProfile } = store.getState().userReducer;
        const { currentProject } = store.getState().mainReducer;
        verseAccess = !!_.find(taskActions, { projectId: currentProject.id, assignedTo: myProfile?.id });
    }
    return verseAccess;
};
export const getMappingsObject = (mappingData: WordMappings[]) => mappingData.map((mapping: WordMappings) => ({
    mappingID: mapping.id,
    start: mapping.sourceContainerId,
    end: mapping.translatedContainerId,
}));
export const SELECTING_MODE = {
    NONE: 0,
    SOURCE: 1,
    TRANSLATION: 2,
};
export const getStatusPercentage = (status: number) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let percentage = 0 as any;
    switch (status) {
        case 2:
            percentage = {
                statusName: 'Edit',
                percentage: 20,
                badge: ' bg-warning',
                bgcolor: '#607d8b',
                borderColor: '#607d8b',
                icon: 'running',
                chartColor: '#d4faf4',
                color: '#607d8b',
                className: 'status3',
            };
            break;
        case 3:
            percentage = {
                statusName: 'In Progress',
                percentage: 20,
                badge: ' bg-warning',
                bgcolor: '#5d9cec',
                borderColor: '#5d9cec',
                icon: 'running',
                chartColor: '#ed9c28',
                color: '#5d9cec',
                className: 'status2',
            };
            break;
        case 4:
            percentage = {
                statusName: 'Review',
                percentage: 60,
                badge: ' bg-tertiary',
                bgcolor: '#9c27b0',
                borderColor: '#9c27b0',
                icon: 'search',
                chartColor: '#2baab1',
                color: '#9c27b0',
                className: 'status4',
            };
            break;
        case 5:
            percentage = {
                statusName: 'Completed',
                percentage: 40,
                badge: ' bg-success',
                bgcolor: '#ff4081',
                borderColor: '#ff4081',
                icon: 'check',
                chartColor: '#47a447',
                color: '#ff4081',
                className: 'status5',
            };
            break;
        case 6:
            percentage = {
                statusName: 'Rejected',
                percentage: 90,
                badge: ' bg-danger',
                bgcolor: '#ffc154',
                borderColor: '#ffc154',
                icon: 'times',
                chartColor: '#d2322d',
                color: '#ffc154',
                className: 'status6',
            };
            break;
        case 7:
            percentage = {
                statusName: 'Empty',
                percentage: 90,
                badge: ' bg-info',
                bgcolor: '#5b7ebe',
                borderColor: '#5b7ebe',
                color: '#5b7ebe',
                icon: 'check',
                chartColor: '#ccff00',
                className: 'status7',
            };
            break;
        case 8:
            percentage = {
                statusName: '',
                percentage: 90,
                badge: ' bg-info',
                bgcolor: '#2e9b8f',
                borderColor: '#2e9b8f',
                color: '#2e9b8f',
                icon: 'check',
                chartColor: '#66ff00',
                className: 'status8',
            };
            break;
        case 9:
            percentage = {
                statusName: '',
                percentage: 100,
                badge: ' bg-success',
                bgcolor: '#6fde5e',
                borderColor: '#6fde5e',
                color: '#6fde5e',
                icon: 'thumbs-up',
                chartColor: '#00FF00',
                className: 'status9',
            };
            break;
        case 10:
            percentage = {
                statusName: '',
                percentage: 100,
                badge: ' bg-success',
                icon: 'thumbs-up',
                chartColor: '#00FF00',
                className: 'status10',
                bgcolor: 'rgb(111, 222, 94)',
                borderColor: 'rgb(111, 222, 94)',
                color: 'rgb(111, 222, 94)',
            };
            break;
        case 13:
            percentage = {
                statusName: 'Unmappable',
                percentage: 0,
                badge: 'bg-dark',
                bgcolor: '#AAA',
                icon: 'star',
                chartColor: '#AAA',
                className: 'status1',
            };
            break;
        default:
            percentage = {
                statusName: 'Not Started',
                percentage: 0,
                badge: ' rgb(224, 224, 224);',
                icon: 'minus',
                chartColor: '#2e353e',
                className: 'status1',
                bgcolor: 'rgb(224, 224, 224)',
                color: 'rgb(224, 224, 224)',
                borderColor: 'rgb(224, 224, 224)',
            };
            break;
    }
    return percentage as any;
};
export const getReviewerStatus = (id: number = 6) => {
    switch (id) {
        case 6:
            return {
                statusName: 'Not Started',
                percentage: 0,
                badge: ' bg-dark',
                bgcolor: '#000000',
                icon: 'minus',
                chartColor: '#2e353e',
                color: '#ffffff',
                className: 'status1',
                button: 'secondary',
            };
        case 7:
            return {
                statusName: 'Preliminary Mapping Review',
                percentage: 60,
                badge: ' bg-tertiary',
                bgcolor: '#2baab1',
                icon: 'search',
                chartColor: '#2baab1',
                color: '#ffffff',
                className: 'status1',
                button: 'info',
            };
        case 8:
            return {
                statusName: 'Preliminary Translation Review',
                percentage: 60,
                badge: ' bg-tertiary',
                bgcolor: '#2baab1',
                icon: 'search',
                chartColor: '#2baab1',
                color: '#ffffff',
                className: 'status1',
                button: 'info',
            };
        case 9:
            return {
                statusName: 'In Progress',
                percentage: 50,
                badge: ' bg-warning',
                bgcolor: '#ed9c28',
                icon: 'running',
                chartColor: '#ed9c28',
                color: '#ffffff',
                className: 'status1',
                button: 'primary',
            };
        case 10:
            return {
                statusName: 'Mapping Review',
                percentage: 60,
                badge: ' bg-tertiary',
                bgcolor: '#2baab1',
                icon: 'search',
                chartColor: '#2baab1',
                color: '#ffffff',
                className: 'status1',
                button: 'warning',
            };
        case 11:
            return {
                statusName: 'Translation Review',
                percentage: 60,
                badge: ' bg-tertiary',
                bgcolor: '#2baab1',
                icon: 'search',
                chartColor: '#2baab1',
                color: '#ffffff',
                className: 'status1',
                button: 'info',
            };
        case 12:
            return {
                statusName: 'Done',
                percentage: 100,
                badge: ' bg-success',
                bgcolor: '#00FF00',
                icon: 'thumbs-up',
                chartColor: '#00FF00',
                button: 'success',
            };
        default:
            return {
                statusName: 'Not Started',
                percentage: 0,
                badge: ' bg-dark',
                bgcolor: '#000000',
                icon: 'minus',
                chartColor: '#2e353e',
                color: '#ffffff',
                className: 'status1',
                button: 'primary',
            };
    }
};
export const checkReviewerAccess = (taskAction: TaskActions) => {
    if (isUserLoggedIn()) {
        if (checkIsReviewerOrDataScientist() || taskAction?.reviewerAssignedTo === myProfileObj().id) {
            return true;
        }
    }
    return false;
};
export const getAllStatus = (statusType: string = 'workspace') => store.getState().mainReducer.statusList[statusType];
export const removePunctuation = (word: string) => word.replace(/[.”,/#!$%^&*;:{}=_`~()]/g, '');
export const getActiveTabByStatus = (statusId: number) => {
    switch (true) {
        case statusId === 1:
            return eventKeys.translate;
        case statusId === 2:
            return eventKeys.edit;
        case statusId === 3:
            return eventKeys.structure;
        case statusId === 4:
            return eventKeys.mapping;
        case (statusId > 4):
            return eventKeys.mapping;
        default:
            return eventKeys.translate;
    }
};
export const getActualStatus = (verse: Verse) => {
    const statusId = getTaskStatus(verse);
    if (statusId >= 3 && verse.translatedContainers.length === 0) {
        return verse.translatedVerses.length > 0 ? 2 : 1;
    } if (statusId >= 2 && verse.translatedVerses.length === 0) {
        return 1;
    }
    return statusId;
};
export const updateLocationDragAndDrop = (payload: {
    newUniqueMeaningId: number;
    meanings: MeaningObj[];
    sourcePayload: any;
    targetPayload: any;
}) => {
    const {
        meanings, sourcePayload, targetPayload, newUniqueMeaningId,
    } = payload;
    const { targetUniqueMeaningId } = targetPayload;
    const { sourceUniqueMeaningId, sourceTasksId, sourceWord } = sourcePayload;
    const meaningIndex = _.findIndex(meanings, { uniqueMeaningId: sourceUniqueMeaningId });
    if (meaningIndex >= 0) {
        const sourceWordIndex = _.findIndex(meanings[meaningIndex].words, { highlightWord: sourceWord });
        if (sourceWordIndex >= 0) {
            const targetMeaningIndex = _.findIndex(meanings, { uniqueMeaningId: targetUniqueMeaningId });
            // update only if its different meaning
            if (meaningIndex !== targetMeaningIndex) {
                sourceTasksId.forEach((tasksId: number) => {
                    const sourceWordLocationIndex = _.findIndex(meanings[meaningIndex].words[sourceWordIndex].wordLocations, {
                        tasksId: Number(tasksId),
                    });
                    if (sourceWordLocationIndex >= 0) {
                        const wordDetails = meanings[meaningIndex].words[sourceWordIndex].wordLocations.splice(sourceWordLocationIndex, 1);
                        if (meanings[meaningIndex].words[sourceWordIndex].wordLocations.length === 0) {
                            meanings[meaningIndex].words.splice(sourceWordIndex, 1);
                        }
                        if (targetMeaningIndex >= 0) {
                            const targetWordIndex = _.findIndex(meanings[targetMeaningIndex].words, { highlightWord: sourceWord });
                            if (targetWordIndex >= 0) {
                                meanings[targetMeaningIndex].words[targetWordIndex].wordLocations.push(wordDetails[0]);
                            } else {
                                const languageVersionId = 5;
                                if (!targetUniqueMeaningId) meanings[targetMeaningIndex].uniqueMeaningId = Number(newUniqueMeaningId);
                                meanings[targetMeaningIndex].words.push({
                                    extend: false,
                                    languageVersionId,
                                    highlightWord: sourceWord,
                                    wordLocations: wordDetails,
                                });
                            }
                        }
                    }
                });
            }
            return meanings;
        }
    }
};
export const isPhrase = (word: string) => {
    if (word.split(' ').length > 1) {
        return true;
    }
    return false;
};
export const deleteExisting = (existingConnection: Mapping[], newConnection: Mapping[]) => {
    const reducedExisting = existingConnection.filter((connection: Mapping) => (
        newConnection.find((existing: Mapping) => existing.mappingID === connection.mappingID) !== undefined
    ));
    const reducedConnection = newConnection.filter((connection: Mapping) => (
        reducedExisting.find((existing: Mapping) => existing.mappingID === connection.mappingID) === undefined
    ));
    return [...reducedExisting, ...reducedConnection];
};
export const getDeepLinks = (connections: MappingObj[], sourceIds: number[], destinationIds: number[]) => {
    let sourceContainers: number[] = [];
    let targetContainers: number[] = [];
    const connectedLinks = (sourceIds: number[], destinationIds: number[]) => {
        //= ======= Implement promises here to avoid large number of loops
        if (destinationIds?.length) {
            const newSourceContainers = connections
                .filter((data) => destinationIds.includes(data.end) && !sourceContainers.includes(data.start))
                .map((data) => data.start);
            if (newSourceContainers.length) {
                sourceContainers = [...sourceContainers, ...newSourceContainers];
                connectedLinks(newSourceContainers, []);
            }
        }
        if (sourceIds?.length) {
            const newDestinationContainers = connections
                .filter((data) => sourceIds.includes(data.start) && !targetContainers.includes(data.end))
                .map((data) => data.end);
            if (newDestinationContainers.length) {
                targetContainers = [...targetContainers, ...newDestinationContainers];
                connectedLinks([], newDestinationContainers);
            }
        }
    };
    connectedLinks(sourceIds, destinationIds);
    return { sourceContainers: _.uniq(sourceContainers), targetContainers: _.uniq(targetContainers) };
};
export const addOrRemoveLink = (newConnection: MappingObj[], connectObj: MappingObj) => {
    const existingIndex = _.findIndex(newConnection, (data) => data.start === connectObj.start && data.end === connectObj.end);
    if (existingIndex >= 0) {
        // ---- Remove all connection for target and source
        newConnection = newConnection.filter((data) => Number(data.start) !== connectObj.start && Number(data.end) !== connectObj.end);
    } else {
        newConnection.push(connectObj);
    }
    return newConnection;
};
export const checkFreeEditIsDone = (verse: Verse) => (verse?.translatedContainers?.length > 0);
//
//  get project which is assigned to me
export const getProjectListByUser = (projectList: ProjectListType[] = []): ProjectListType[] => {
    projectList = projectList.length ? projectList : store.getState().mainReducer.projectList;
    const myProfile = myProfileObj();
    let myProjectList: ProjectListType[] = projectList;
    if (!checkIsAdminOrDs()) {
        myProjectList = [];
        projectList?.forEach((project: ProjectListType) => {
            let isMyProject = false;
            project?.assignedGroups?.forEach((assignedProject) => {
                if (_.find(myProfile.assignedGroups, { id: assignedProject.id })) {
                    isMyProject = true;
                }
            });
            if (isMyProject) {
                myProjectList.push(project);
            }
        });
    }
    myProjectList = _.uniqBy(myProjectList, 'id') || [];
    setLocalStorageItem(localStorageKey.projectList, JSON.stringify(myProjectList));
    return myProjectList;
};
export const getFieldFromContainerPosition = (position: string, field: string) => {
    const positionInList: string[] = position.split(':').join('$').split('_').join('$')
        .split('$');
    const book = positionInList[0];
    const chapter = positionInList[1];
    const verseNumber = positionInList[2];
    const wordIndex = positionInList[3];
    switch (field) {
        case 'bookName':
            return book;
        case 'chapterNumber':
            return Number(chapter);
        case 'verseNumber':
            return Number(verseNumber);
        case 'position':
            return Number(wordIndex);
        default:
            break;
    }
};
export const formTreeStructure = (containerList: ContainerType[]) => {
    const tre = _(containerList)
        .groupBy((book) => getFieldFromContainerPosition(book.position, 'bookName'))
        .map((bookDetail: any, bookName: any) => ({
            label: bookName,
            value: bookName,
            children: _(bookDetail)
                .groupBy((book) => getFieldFromContainerPosition(book.position, 'chapterNumber'))
                .map((chapterDetail: any, chapterNumber: any) => ({
                    label: `Chapter ${chapterNumber}`,
                    value: `${bookName}$Chapter ${chapterNumber}`,
                    children: _(chapterDetail)
                        .groupBy((book) => getFieldFromContainerPosition(book.position, 'verseNumber'))
                        .map((verseDetail: any, verseNumber: any) => ({
                            label: `Verse ${verseNumber}`,
                            value: `${bookName}$Chapter ${chapterNumber}$Verse ${verseNumber}`,
                            data: verseDetail.map((container: ContainerType) => ({ ...container, wordIndex: getFieldFromContainerPosition(container.position, 'verseNumber') })),
                        }))
                        .value(),
                }))
                .value(),
        }))
        .value();
    return tre;
};
const getTaskAction = (verse: Verse) => {
    const taskAction = verse?.taskActions ? verse?.taskActions[0] : { statusId: 0, assignedTo: 0 };
    return taskAction;
};
export const getTaskStatus = (verse?: Verse) => {
    const statusId = verse ? getTaskAction(verse).statusId : 1;
    return statusId || 1;
};
export const selectFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { filter } = store.getState().taskReducer;
    const { name } = e.target;
    const { value } = e.target;
    const payload = { ...filter, [name]: value, page: 1 };
    // --------------- if book filter then erase chapter and verse
    if (name === 'bookListId') {
        payload.chapterId = '';
        payload.versesId = '';
        store.dispatch({ type: types.FILTER_ITEM, payload: FILTER_LOADING_TYPE.BOOK });
    } else if (name === 'chapterId') {
        // ----------- if book chapter then erase  verse  filter
        payload.versesId = '';
        store.dispatch({ type: types.FILTER_ITEM, payload: FILTER_LOADING_TYPE.CHAPTER });
    } else if (name === 'versesId') {
        if (!value) {
            payload.versesId = '';
        } else {
            payload.versesId = Number(value); // selVerse.id;
        }
    }
    payload.verseId = payload.versesId; // selVerse.id;
    store.dispatch({ type: types.TASK_FILTER, payload });
};
export const toggleAssignedToMe = async (isAssignedMe: boolean) => {
    const { filter } = store.getState().taskReducer;
    store.dispatch({ type: types.TASK_FILTER, payload: { ...filter, isAssignedMe } });
    await getTrelloTicketFilters(filter);
};
export const getFilterData = (component = '') => (component === filterComponent.taskManagement ? store.getState().taskManagementReducer : store.getState().taskReducer);
let jumpToTimeoutId!: ReturnType<typeof setTimeout>;
export const jumpToDebounce = (fn: Function, ms = 300) => (function (this: any, ...args: any[]) {
    jumpToTimeoutId && clearTimeout(jumpToTimeoutId);
    jumpToTimeoutId = setTimeout(() => fn.apply(this, args), ms);
}());

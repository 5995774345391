/* eslint-disable @typescript-eslint/no-explicit-any */
import { t } from 'i18next';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Select from 'react-select';
import Loader from '../components/shared/loader';
import { getDataExport, getTrelloTicketFilters } from '../store/actions/index';
import { RootState } from '../store/reducer';
import { checkIsAdmin, checkIsDataScientist, getAllStatus, handleToast } from '../utils/handler';
import { ProjectListType } from './viewTypes/project.type';
import { Status } from './viewTypes/task.types';

const statusListOptions = () => {
    const statusList = getAllStatus();
    return statusList?.map((status: Status) => ({
        value: status.id,
        label: status.status
    }));
};
interface optionType { id: number, name: string }
class ExportComponent extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            selectedProject: null,
            selectedBook: null,
            selectedChapter: null,
            selectedStatus: null,
            loadingData: false
        };
        this.updateBook = this.updateBook.bind(this);
        this.updateChapter = this.updateChapter.bind(this);
        this.updateLanguageVersion = this.updateLanguageVersion.bind(this);
        this.getDataExport = this.getDataExport.bind(this);
    }
    componentDidMount() {
        const { history } = this.props;
        if (!checkIsAdmin() && !checkIsDataScientist()) {
            history?.push(`/`);
        }
    }
    getDataExport() {
        const { selectedProject, selectedBook, selectedChapter, selectedStatus } = this.state;
        if (selectedProject == null || selectedBook == null) {
            handleToast({ message: 'Missing field(s)' }, 'error');
            return;
        }
        this.setState({ loadingData: true });
        getDataExport(selectedProject.value, selectedBook.value)
            .then((res: any) => {
                if (res) {
                    handleToast(res, "success");
                }
                this.setState({ loadingData: false });
            })
            .catch((err) => {
                this.setState({ loadingData: false });
                handleToast({ message: err }, 'error');
            });
    }
    languageListOptions() {
        const { projectList } = this.props;
        return projectList?.map((project: ProjectListType) => ({
            value: project.id,
            label: project.name
        }));
    }
    bookListOptions() {
        const { booksList } = this.props;
        return booksList?.map((b: optionType) => ({
            value: b.id,
            label: b.name
        }));
    }
    chaptersListOptions() {
        const { chaptersList } = this.props;
        const values = chaptersList?.map((c: optionType) => ({
            value: c.id,
            label: c.name
        }));
        return [{ value: -1, label: t('export.entire-book') }].concat(values);
    }
    updateLanguageVersion(data: optionType) {
        this.setState({ selectedProject: data });
    }
    updateBook(data: { value: number }) {
        this.setState({ selectedBook: data, selectedChapter: null });
        getTrelloTicketFilters({ bookListId: data.value });
    }
    updateChapter(data: optionType) {
        this.setState({ selectedChapter: data });
    }
    render() {
        const { loadingData, versionCode, selectedBook, selectedStatus, selectedChapter, selectedProject } = this.state;
        return (
            <div className="justify-content-center pt-3">
                <div style={{ width: '250px', margin: '0 auto' }}>
                    <Select
                        disabled={loadingData}
                        className={'export-selector'}
                        placeholder={t('filter.filter-project')}
                        options={this.languageListOptions()}
                        onChange={this.updateLanguageVersion}
                        value={versionCode}
                    />
                    {selectedProject ? <Select
                        disabled={loadingData}
                        className={'export-selector'}
                        placeholder={t('filter.filter-book')}
                        options={this.bookListOptions()}
                        onChange={this.updateBook}
                        value={selectedBook}
                    /> : null}
                    {/* {selectedBook ? < Select
                        disabled={loadingData}
                        className={'export-selector'}
                        placeholder={t('filter.filter-chapter')}
                        options={this.chaptersListOptions()}
                        onChange={this.updateChapter}
                        value={selectedChapter}
                    /> : null}
                    {selectedChapter ? <Select
                        disabled={loadingData}
                        className={'export-selector'}
                        placeholder={t('filter.filter-status')}
                        options={statusListOptions()}
                        onChange={(data) => this.setState({ selectedStatus: data })}
                        value={selectedStatus}
                    /> : null} */}
                    <div style={{ textAlign: 'center' }}>
                        <button
                            disabled={loadingData}
                            className={'btn btn-primary btn-xs rounded px-2 py-1'}
                            onClick={this.getDataExport}
                        >
                            {t('export.export-data')}
                        </button>
                        {loadingData ? <Loader /> : null}
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: RootState) => ({
    booksList: state.taskReducer.booksList,
    chaptersList: state.taskReducer.chaptersList,
    projectList: state.mainReducer.projectList
});
const mapDispatchToProps = () => ({});
export default withTranslation('translation')(connect(mapStateToProps, mapDispatchToProps)(ExportComponent));

import * as types from '../actions/types';
import { actionTypes } from './reducerTypes';
export let taskManagementInitialState = {
  projectId: 1,
  search: "",
  filter: {},
  booksList: [],
  chaptersList: [],
  versesList: [],
  pagination: {
    count: 725657,
    limit: 1,
    page: 1,
    totalPages: 1
  },
  currentProject: {}
};
export const taskManagementReducer = (state = taskManagementInitialState, action: actionTypes) => {
  switch (action.type) {
    case types.TASK_MANAGEMENT_FILTER:
      return {
        ...state,
        filter: { ...state.filter, ...action.payload }
      };
    case types.TASK_MANAGEMENT_CURRENT_PROJECT:
      return {
        ...state,
        currentProject: action.payload
      };
    case types.TASK_MANAGEMENT_FILTER_DATA:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};
export default taskManagementReducer;

import Axios from 'axios';
import { baseUrl, requestHeader } from '../../config';
import { IDataset, ISentence } from '../../views/aligned-sentences';
import { CatchReturnError } from './actionHandler';

export const createSentence = (datasetId: number) => {
    return Axios.post(baseUrl + '/sentences', { datasetId }, requestHeader)
        .then((res) => res.data)
        .catch((error) => {
            CatchReturnError(error);
        });
};

export const saveSentence = (sentence: ISentence, datasetId: number) => {
    sentence.datasetId = datasetId;
    return Axios.put(baseUrl + '/sentences', sentence, requestHeader)
        .then((res) => res.data)
        .catch((error) => {
            CatchReturnError(error);
        });
};

export const deleteSentence = (sentence: ISentence) => {
    return Axios.delete(baseUrl + `/sentences/${sentence.id}`, requestHeader)
        .then((res) => res.data)
        .catch((error) => {
            CatchReturnError(error);
        });
};

export const createDataset = (dataset: IDataset) => {
    const { title, description, languageId, sentences } = dataset;
    return Axios.post(
        baseUrl + '/sentence-datasets',
        {
            title,
            description,
            languageId,
            sentences
        },
        requestHeader
    )
        .then((res) => res.data)
        .catch((error) => {
            CatchReturnError(error);
        });
};

export const saveDataset = (dataset: IDataset) => {
    const { id, title, description, languageId } = dataset;
    if (!id) return createDataset(dataset);
    return Axios.put(
        baseUrl + '/sentence-datasets',
        {
            id,
            title,
            description,
            languageId
        },
        requestHeader
    )
        .then((res) => res.data)
        .catch((error) => {
            CatchReturnError(error);
        });
};

export const getDatasets = () => {
    return Axios.get(baseUrl + '/sentence-datasets', requestHeader)
        .then((res) => res.data)
        .catch((error) => {
            CatchReturnError(error);
        });
};

export const loadDataset = (dataset: IDataset) => {
    return Axios.get(baseUrl + `/sentence-datasets/${dataset.id}`, requestHeader)
        .then((res) => res.data)
        .catch((error) => {
            CatchReturnError(error);
        });
};

import { useMemo } from 'react';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { getQueryStringParams } from './../utils/urlParse';

/**
 *  Return memoized function with { push, replace, pathname, query(params), match, location, history}
 */
export const useRouter = () => {
    const params = useParams();
    const location = useLocation();
    const history = useHistory();
    const match = useRouteMatch();

    return useMemo(() => {
        return {
            push: history.push,
            replace: history.replace,
            pathname: location.pathname,
            query: {
                ...getQueryStringParams(location.search), // Convert string to object
                ...params
            } as any,
            match,
            location,
            history
        };
    }, [params, match, location, history]);
};

let successMessages = {
    passwordChanged: 'Password changed successfully',
    success: 'Success',
    userUpdatedSuccessfully: 'User updated successfully',
    userDeleted: 'User Deleted',
    userDetailsUpdatedSuccessfully: 'User Details Updated Successfully',
    deletedSuccessfully: 'Deleted successfully',
    formSubmitted: 'Form submitted ',
    UpdatedSuccessfully: 'Updated successfully',
    deleteSuccess: 'Deleted successfully',
    bookUpdatedSuccessfully: 'Book updated successfully',
    userAddedToGroup: 'User added to User Group',
    userRemovedFromGroup: 'User removed from the User Group',
    groupUpdatedSuccess: 'User Group updated successfully',

    proceed: "Proceed",
    ruleAppliedSuccessfully: "The replacement rule has been applied.",
    mappingUpdated: "Mapping updated successfully",
    projectCreated: "Project created",
    projectUpdated: "Project Updated",
    projectRemoved: "Project removed",
    deleteUserConfirmation: "Are you sure you want to delete this user?",
    deleteUserGroupConfirmation: "Are you sure you want to remove this User Group?",
    deleteContainerConfirmation: "Are you sure you want to remove this container?",
    deleteProjectConfirmation: "Are you sure you want to remove this project?",
    userGroupRemoved: "User Group removed successfully",
    userGroupAssigned: "User Group assigned successfully",
    removeUserFromGroup: "Are you sure you want to remove this user from the group?",
    verseTranslated: "Translate Verse Succeed",
    taskUpdated: "Task updated successfully",

};

export default successMessages;

import { MenuItemType } from '../../../../../../menu-items';

interface NavBadgeProps {
    items: MenuItemType;
    // eslint-disable-next-line react/no-unused-prop-types
    layout?: string;
}
function NavBadge(props: NavBadgeProps) {
    return props.items.badge ? (
        <span className={['label', 'pcoded-badge', props.items.badge.type].join(' ')}>{props.items.badge.title}</span>
    ) : null;
}
export default NavBadge;

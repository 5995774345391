/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { t } from 'i18next';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import useWindowSize from '../../../../../../hooks/useWindowSize';
import { MenuItemType } from '../../../../../../menu-items';
import * as actionTypes from '../../../../../../store/actions';
import { useSelector } from '../../../../../../store/reducer';
import { checkIsAdminOrDs, checkIsDataScientist, checkIsReviewer, showMenu } from '../../../../../../utils/handler';
import NavBadge from "../NavBadge";
import NavIcon from "../NavIcon";

interface NavItemProps {
    item: MenuItemType;
    // eslint-disable-next-line react/no-unused-prop-types
    layout?: string;
}
function NavItem(props: NavItemProps) {
    const { windowWidth } = useWindowSize();
    const dispatch = useDispatch();
    const layout = useSelector((state) => state.able.layout);
    const onItemClick = () => dispatch({ type: actionTypes.COLLAPSE_MENU });
    const onItemLeave = () => dispatch({ type: actionTypes.NAV_CONTENT_LEAVE });
    const itemInternationalizationPrefixes = props.item.internationalizationPrefixes;
    let itemTitle: React.ReactNode = t(itemInternationalizationPrefixes) || props.item.title;
    if (props.item.hideSideMenu) {
        return <span />;
    }
    if (props.item.isReviewer === false) {
        if (checkIsReviewer()) {
            return <span />;
        }
    }
    if (props.item.isAdmin) {
        if (!showMenu(props.item.isAdmin) && !checkIsReviewer()) {
            return <span />;
        }
    }
    if (props.item.isDataScientist) {
        if (!checkIsDataScientist()) {
            return <span />;
        }
    }
    if (props.item.isAdminAndScientist) {
        if (!checkIsAdminOrDs() && !checkIsReviewer()) {
            return <span />;
        }
    }
    if (props.item.icon) {
        itemTitle = <span className="pcoded-mtext">{t(itemInternationalizationPrefixes) || props.item.title}</span>;
    }
    let itemTarget = '';
    if (props.item.target) {
        itemTarget = '_blank';
    }
    let subContent: React.ReactNode;
    if (props.item.external) {
        subContent = (
            <a href={props.item.url} target="_blank" rel="noopener noreferrer">
                <NavIcon items={props.item} />
                {itemTitle}
                <NavBadge layout={layout} items={props.item} />
            </a>
        );
    } else {
        subContent = (
            <NavLink to={props.item.url!} className="nav-link" exact target={itemTarget}>
                <NavIcon items={props.item} />
                {itemTitle}
                <NavBadge layout={layout} items={props.item} />
            </NavLink>
        );
    }
    let mainContent: React.ReactNode;
    if (layout === 'horizontal') {
        mainContent = <li onClick={onItemLeave}>{subContent}</li>;
    } else if (windowWidth < 992) {
        mainContent = (
            <li className={props.item.classes} onClick={onItemClick}>
                {subContent}
            </li>
        );
    } else {
        mainContent = <li className={props.item.classes}>{subContent}</li>;
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{mainContent}</>;
    // return showMenu(props.item.isAdmin) ? <>
    // {mainContent}
    // </> : null;
}
export default NavItem;

import axios from 'axios';
import { baseUrl, requestHeader } from '../../config';
import { checkVerseAccess, cleanParams, getActiveTabByStatus, getCurrentProject, getCurrentProjectId, getTaskStatus } from '../../utils/handler';
import { Verse } from '../../views/viewTypes/task.types';
import { MappingObj } from '../../views/viewTypes/words.type';
import store from '../store';
import { CatchReturnError } from './actionHandler';
import { BOOK_DETAILS, GET_GRAMMAR_LIST, WORKSPACE_LOADER } from './types';
export const getVerse = (bookId: number, params: object) => {
    return axios
        .get(baseUrl + `/books/${bookId}`, {
            params: { ...params },
            headers: requestHeader.headers
        })
        .then((res) => {
            return res['data'];
        })
        .catch((err) => {
            CatchReturnError(err);
        });
};
export const getBookByIdWithFilters = (bookId: number, params: object) => {
    return axios
        .get(baseUrl + `/books/${bookId}`, {
            params: { ...params },
            headers: requestHeader.headers
        })
        .then((res) => {
            return res['data'];
        })
        .catch((err) => {
            CatchReturnError(err);
        });
};
export const getWorkspaceData = (payload?: { appendResult?: boolean; page?: number; filter?: any }) => {
    let { page = 1, filter = store.getState().taskReducer.filter } = { ...payload };
    delete filter.versesId;
    page = page || filter.pageNumber || 1;
    let tmpFilter = filter;
    tmpFilter.projectId = getCurrentProjectId();
    if (tmpFilter.versesId) delete tmpFilter['versesId'];
    store.dispatch({ type: WORKSPACE_LOADER, payload: true });
    return axios
        .get(baseUrl + `/workspaceChapters`, {
            params: cleanParams({ ...tmpFilter, page }),
            headers: requestHeader.headers
        })
        .then((res) => {
            let data = res['data'];
            if (data.chapters.length) {
                let verses = data.chapters[0].verses
                verses.forEach((verse: Verse) => {
                    verse.verseAccess = checkVerseAccess(verse.taskActions);
                    verse.freeEdit = !verse?.translatedContainers?.length
                    verse.activeTab = getActiveTabByStatus(getTaskStatus(verse))
                    if (!verse.translatedVerses)
                        verse.translatedVerses = []
                    if (!verse.translatedContainers)
                        verse.translatedContainers = []
                });
                data.chapters[0].verses = verses
            }
            // console.log(data.chapters[0].verses)
            store.dispatch({ type: BOOK_DETAILS, payload: data });
            store.dispatch({ type: WORKSPACE_LOADER, payload: false });
            return data;
        })
        .catch((err) => {
            store.dispatch({ type: WORKSPACE_LOADER, payload: false });
            CatchReturnError(err);
        });
};
export const addMappingToStore = (workSpaceData: any) => {
    store.dispatch({ type: BOOK_DETAILS, payload: workSpaceData });
};
export const addVerseTopic = (topicIds: number[], verseId: number) => {
    return axios
        .post(baseUrl + `/addVerseTopic/` + verseId, topicIds, requestHeader)
        .then((res) => {
            let data = res['data'];
            return data;
        })
        .catch((err) => {
            CatchReturnError(err);
        });
};
export const mergeWordsIntoContainer = (payload: { tasksId: number; wordId: number; versesId: number, languageVersionId?: number }) => {
    payload.languageVersionId = getCurrentProject().sourceLanguageVersionId
    return axios
        .patch(baseUrl + `/mergeWordsIntoContainer`, payload, requestHeader)
        .then((res) => {
            let data = res['data'];
            return data;
        })
        .catch((err) => {
            CatchReturnError(err);
        });
};
export const deleteContainers = (containerIds: number[]) => {
    return axios.delete(baseUrl + `/deleteContainers/` + containerIds.join(","), requestHeader)
        .then((res) => {
            let data = res['data'];
            return data;
        })
        .catch((err) => {
            CatchReturnError(err);
        });
}
export const updateMapping = (payload: {
    verseId: number;
    languageVersionId: number;
    mappings: MappingObj[]
}
) => {
    return axios.patch(baseUrl + `/updateMapping`, payload, requestHeader)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            CatchReturnError(err);
        });
};
export const getGrammarList = () => {
    return axios
        .get(baseUrl + `/getGrammarList`, requestHeader)
        .then((res) => {
            let data = res['data'];
            store.dispatch({ type: GET_GRAMMAR_LIST, payload: data });
        })
        .catch((err) => {
            CatchReturnError(err);
        });
};

import { useState } from 'react';
import {
    Button, ButtonToolbar, Card, Col, Row, ToggleButton, ToggleButtonGroup,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SortableContainer, arrayMove } from 'react-sortable-hoc';
import { ReferenceVerses, SourceLanguageCell } from '../../components/common';
import { deleteContainers } from '../../store/actions/index';
import { BOOK_DETAILS, CREATE_NEW_CONTAINER, SELECTED_VERSE } from '../../store/actions/types';
import { updateVerseAction } from '../../store/actions/verse.actions';
import { RootState } from '../../store/reducer';
import { eventKeys } from '../../store/reducers';
import errorMessages from '../../utils/errorMessages';
import { handleToast, referenceVerseType } from '../../utils/handler';
import { Task, TranslatedContainer } from '../viewTypes/task.types';
import { OrderPayload } from "../viewTypes/translatedContainer.type";
import DestinationRow from './DestinationRow';
import { checkIsEmptyHasWords, nextVerse, replaceBookDetail } from './handler';
// import ArrowGroup from './ArrowGroup';
import { updateContainerOrder } from '../../store/actions/translated.words.action';

const SortableList = SortableContainer(() => <DestinationRow modifyMode={1} />);
function Structure() {
    const {
        bookDetails, selectedVerse, selectedVerseIndex, translatedVerse, isCreatingContainer,
    } = useSelector(
        (state: RootState) => state.workspaceReducer,
    );
    const { currentProject } = useSelector((state: RootState) => state.mainReducer);
    const [modifyMode, setModifyMode] = useState(selectedVerse.verseAccess ? 1 : 0);
    const translatedContainerList: TranslatedContainer[] = selectedVerse
        ?.translatedContainers || [];
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const checkEmptyBlock = () => translatedContainerList[translatedContainerList.length - 1]
        ?.words?.length === 0;
    const onAddTranslationContainer = () => {
        if (!checkEmptyBlock()) {
            const containerPayload = {
                id: 0,
                grammarId: 0,
                meaningId: 0,
                orderIndex: translatedContainerList.length,
                versesId: selectedVerse.id,
                languageVersionId: translatedVerse.languageVersionId,
                words: [],
                topics: [],
            };
            selectedVerse.translatedContainers.push(containerPayload);
            replaceBookDetail({ cIndex: 0, vIndex: selectedVerseIndex, data: selectedVerse });
            dispatch({ type: CREATE_NEW_CONTAINER, payload: true });
        } else {
            handleToast({ message: errorMessages.alreadyEmptyContainer }, 'warn');
        }
    };
    const deleteEmptyContainers = () => {
        const newContainers: Task[] = [];
        const emptyContainerIds: number[] = [];
        translatedContainerList.forEach((element) => {
            if (checkIsEmptyHasWords(element)) {
                emptyContainerIds.push(element.id);
            } else {
                newContainers.push(element);
            }
        });
        if (emptyContainerIds.length) {
            deleteContainers(emptyContainerIds);
            selectedVerse.translatedContainers = newContainers;
            replaceBookDetail({ cIndex: 0, vIndex: selectedVerseIndex, data: selectedVerse });
        }
    };
    const updateStatus = (versesId: number = selectedVerse.id, projectId:
        number = currentProject.id, statusId: number = 4) => {
        updateVerseAction({
            versesId,
            projectId,
            statusId,
        }).then((result) => {
            if (result) {
                selectedVerse.activeTab = eventKeys.mapping;
                selectedVerse.taskActions = [result];
                replaceBookDetail({ cIndex: 0, vIndex: selectedVerseIndex, data: selectedVerse });
                nextVerse();
                deleteEmptyContainers();
            }
        });
    };
    const onReject = () => {
        updateVerseAction({
            versesId: selectedVerse.id,
            projectId: currentProject.id,
            statusId: 2,
        }).then((result) => {
            if (result) {
                selectedVerse.taskActions = [result];
                selectedVerse.activeTab = eventKeys.edit;
                replaceBookDetail({ cIndex: 0, vIndex: selectedVerseIndex, data: selectedVerse });
            }
        });
    };
    const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
        if (oldIndex !== newIndex) {
            const { translatedContainers } = selectedVerse;
            selectedVerse.translatedContainers = arrayMove(
                translatedContainers,
                oldIndex,
                newIndex,
            );
            const fromContainer = selectedVerse.translatedContainers[oldIndex];
            if (fromContainer) {
                const payload: OrderPayload[] = [];
                selectedVerse.translatedContainers.forEach((container: Task, index: number) => {
                    // double check the reassigned container
                    const taskContainer = container;
                    taskContainer.wordLocation = index;
                    payload.push({
                        id: container.id,
                        orderIndex: index + 1,
                    });
                });
                updateContainerOrder({
                    verseId: fromContainer.versesId,
                    containerId: fromContainer.id,
                    fromEditor: false,
                    orderPayload: payload,
                });
            }
            dispatch({ type: SELECTED_VERSE, payload: selectedVerse });
            dispatch({ type: BOOK_DETAILS, payload: bookDetails });
        }
    };
    return (
        <Col>
            <Card>
                {selectedVerse.verseAccess && (
                    <Card.Header className="bg-light-primary py-1">
                        <Row>
                            {translatedContainerList && (
                                <Col className="col-auto">
                                    <ButtonToolbar aria-disabled={!translatedContainerList}>
                                        <ToggleButtonGroup
                                            type="radio"
                                            name="options"
                                            size="sm"
                                            value={modifyMode}
                                            onChange={(newValue) => {
                                                setModifyMode(newValue);
                                            }}
                                        >
                                            <ToggleButton
                                                variant="outline-primary"
                                                value={1}
                                                data-toggle="tooltip"
                                                title={t('workspace.re-order-container') || ''}
                                            >
                                                <i className="fa fa-exchange-alt " />
                                            </ToggleButton>
                                            <ToggleButton
                                                variant="outline-primary"
                                                value={2}
                                                data-toggle="tooltip"
                                                title={t('workspace.edit-merge-container') || ''}
                                            >
                                                <i className="fa fa-i-cursor px-1" />
                                            </ToggleButton>
                                            <ToggleButton
                                                className=""
                                                variant="outline-primary"
                                                value={3}
                                                data-toggle="tooltip"
                                                title={t('workspace.show-delete') || ''}
                                            >
                                                <i className="fa-regular fa-eye" />
                                                <span className="ml-1">
                                                    {t('workspace.action')}
                                                </span>
                                            </ToggleButton>
                                            {modifyMode === 2 ? (
                                                <Button
                                                    size="sm"
                                                    variant="primary"
                                                    onClick={() => {
                                                        onAddTranslationContainer();
                                                    }}
                                                    disabled={isCreatingContainer}
                                                    data-toggle="tooltip"
                                                    title={t('workspace.add-empty-container') || ''}
                                                >
                                                    <i className="fa fa-plus " />
                                                </Button>
                                            ) : null}
                                            <Button
                                                size="sm"
                                                variant="danger"
                                                onClick={async () => {
                                                    onReject();
                                                }}
                                                className="ml-3"
                                            >
                                                <i className="fa fa-undo mr-2" />
                                                {t('workspace.reject')}
                                            </Button>
                                            <Button
                                                size="sm"
                                                variant="primary"
                                                onClick={async () => {
                                                    updateStatus();
                                                }}
                                            >
                                                <i className="fa fa-check mr-2" />
                                                {t('workspace.done')}
                                            </Button>
                                        </ToggleButtonGroup>
                                    </ButtonToolbar>
                                </Col>
                            )}
                            {!translatedContainerList && (
                                <Col>
                                    <p className="my-0">{t('workspace.needs-translation-first')}</p>
                                </Col>
                            )}
                        </Row>
                    </Card.Header>
                )}
                <Card.Body className="overflow-hidden" aria-disabled={!translatedContainerList}>
                    <ReferenceVerses type={referenceVerseType.typeSource} />
                    <table
                        className="b-spacing table table-responsive table-xs
                        table-bordered table-hover
                         border-0 mb-0 overflow-scroll table table-responsive mb-3"
                        style={{ position: 'relative' }}
                    >
                        <tbody>
                            <tr>
                                <SourceLanguageCell />
                                {selectedVerse?.tasks?.map((taskInfo: Task) => (
                                    <td className="px-3 py-2 text-center" key={taskInfo.id}>
                                        <div className="d-flex justify-content-between">
                                            <span>
                                                {' '}
                                                {taskInfo.word}
                                            </span>
                                        </div>
                                    </td>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                    <table
                        className="b-spacing table table-responsive table-xs
                        table-bordered table-hover
                         border-0 mb-0 overflow-scroll table table-responsive mb-3"
                        style={{ position: 'relative' }}
                    >
                        <tbody>
                            {translatedContainerList && (
                                modifyMode === 1 ? (
                                    <SortableList axis="x" onSortEnd={onSortEnd} />
                                ) : (
                                    <DestinationRow modifyMode={modifyMode} />
                                )
                            )}
                        </tbody>
                    </table>
                    <ReferenceVerses type={referenceVerseType.typeTarget} />
                </Card.Body>
            </Card>
        </Col>
    );
}
export default Structure;

import { localStorageKey, setLocalStorageItem } from '../../utils/handler';
import * as types from '../actions/types';
import { actionTypes } from './reducerTypes';
export let taskReducerInitialState = {
    projectId: 1,
    currentTask: '',
    activeTask: {},
    taskBookDetails: {},
    filter: {},
    isFilterLoading: false,
    filter_item: 0,
    booksList: [],
    chaptersList: [],
    versesList: [],
    taskList: [],
    totalTask: 725657,
    taskPaginationLoader: false,
    pagination: {
        count: 725657,
        limit: 1,
        page: 1,
        totalPages: 1
    },
    definitionFilter: { reviewerAssignedTo: 0, reviewerStatusId: 0 },
};
export const taskReducer = (state = taskReducerInitialState, action: actionTypes) => {
    switch (action.type) {
        case types.REVIEWER_FILTER:
            return {
                ...state,
                definitionFilter: { ...state.definitionFilter, ...action.payload }
            };
        case types.TASK_PAGINATION_LOADER:
            return {
                ...state,
                taskPaginationLoader: action.payload
            };
        case types.TASK_PAGINATION:
            let totalTask = state.totalTask;
            if (state.totalTask === 0) {
                totalTask = action.payload.count;
            }
            return {
                ...state,
                taskPaginationLoader: false,
                pagination: action.payload,
                totalTask
            };
        case types.TASK_LIST:
            return {
                ...state,
                taskList: action.payload.tasks
            };
        case types.BIBLE_FILTER:
            setLocalStorageItem(localStorageKey.bibleFilter, JSON.stringify(action.payload));
            return {
                ...state,
                ...action.payload
            };
        case types.TASK_FILTER:
            return {
                ...state,
                filter: { ...state.filter, ...action.payload }
            };
        case types.FILTER_LOADING:
            return {
                ...state,
                isFilterLoading: action.payload
            };
        case types.FILTER_ITEM:
            return {
                ...state,
                filter_item: action.payload
            };
        case types.UPDATE_CURRENT_TASK:
            return {
                ...state,
                currentTask: action.payload
            };
        case types.UPDATE_ACTIVE_TASK:
            return {
                ...state,
                activeTask: action.payload
            };
        default:
            return state;
    }
};
export default taskReducer;

import { createSelectorHook } from 'react-redux';
import { combineReducers } from 'redux';
import ableReducer, { initialState as AbleState } from './ableReducer';
import taskManagementReducer from './reducers/filter.reducer';
import {
    breadcrumbsReducer,
    chapterReducer,
    definitionManagementReducer,
    mainReducer,
    taskReducer,
    userReducer,
    verseReducer,
    workspaceReducer,
} from './reducers/index';
const reducer = combineReducers({
    able: ableReducer,
    userReducer,
    mainReducer,
    taskReducer,
    chapterReducer,
    verseReducer,
    workspaceReducer,
    breadcrumbsReducer,
    definitionManagementReducer,
    taskManagementReducer,
});
export const useSelector = createSelectorHook<{
    able: typeof AbleState;
    userReducer: typeof userReducer;
    mainReducer: typeof mainReducer;
    chapterReducer: typeof chapterReducer;
    verseReducer: typeof verseReducer;
    workspaceReducer: typeof workspaceReducer;
    breadcrumbsReducer: typeof breadcrumbsReducer;
    definitionManagementReducer: typeof definitionManagementReducer;
    taskManagementReducer: typeof taskManagementReducer;
}>();
export default reducer;
export type RootState = ReturnType<typeof reducer>;

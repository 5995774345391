/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import {
    Button, Card, Col, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import TextareaAutosize from 'react-textarea-autosize';
import { ReferenceVerses } from '../../components/common';
import { saveTranslatedVerse } from '../../store/actions/translated.words.action';
import { RootState } from '../../store/reducer';
import { eventKeys } from '../../store/reducers';
import errorMessages from '../../utils/errorMessages';
import {
    getSourceLanguage, getTargetLanguage, getTaskStatus, handleToast, referenceVerseType,
} from '../../utils/handler';
import { TranslatedContainer } from '../viewTypes/task.types';
import { nextVerse, replaceBookDetail } from './handler';

function Translation() {
    const { selectedVerse,
        selectedVerseIndex,
    } = useSelector((state: RootState) => state.workspaceReducer);
    const { currentProject } = useSelector((state: RootState) => state.mainReducer);
    const [translatedVerse, setTranslatedVerse] = useState('');
    const [editValue, setEditValue] = useState('');
    const { t } = useTranslation();
    const targetContainers: TranslatedContainer[] = selectedVerse?.translatedContainers || [];
    const setEmptyTranslation = () => {
        setEditValue('');
        setTranslatedVerse('');
    };
    const isFreeEditAvailable = getTaskStatus(selectedVerse) <= 2;
    useEffect(() => {
        try {
            // translatedVerseStatic
            const tvs = targetContainers.map((container: TranslatedContainer) => container.word,).join(' ');
            if (selectedVerse?.translatedVerses && selectedVerse.translatedVerses[0]) {
                const translatedVerseValue = tvs || selectedVerse.translatedVerses[0].verse;
                setEditValue(translatedVerseValue);
                setTranslatedVerse(translatedVerseValue);
            } else {
                setEmptyTranslation();
            }
        } catch (error) {
            setEmptyTranslation();
        }
    }, [selectedVerse]);
    const updateStatus = () => {
        setEmptyTranslation();
        selectedVerse.activeTab = eventKeys.edit;
        nextVerse();
        replaceBookDetail({ cIndex: 0, vIndex: selectedVerseIndex, data: selectedVerse });
    };
    const onTranslationComplete = () => {
        if (editValue) {
            let translatedVerseId = 0;
            selectedVerse.translatedVerses = selectedVerse.translatedVerses || [];
            if (selectedVerse?.translatedVerses && selectedVerse?.translatedVerses[0]) {
                translatedVerseId = selectedVerse?.translatedVerses[0]?.id;
            }
            saveTranslatedVerse({
                translatedVerse: editValue,
                versesId: selectedVerse.id,
                translatedVerseId,
                languageVersionId: currentProject.targetLanguageVersionId,
            })
                .then((result) => {
                    if (result) {
                        selectedVerse.translatedVerses = [result.translatedVerse];
                        selectedVerse.taskActions = [result.taskAction];
                        updateStatus();
                    }
                })
                .then(() => {
                    handleToast({ message: t('Translate Verse Succeed!') }, 'success');
                });
        } else {
            handleToast({ message: errorMessages.emptyTranslation });
        }
    };
    return (
        <Col>
            <Card>
                {selectedVerse.verseAccess && (
                    <Card.Header className="bg-light-primary py-1">
                        <Row className="pl-2 pr-2">
                            {isFreeEditAvailable ? (
                                <Button
                                    disabled={!isFreeEditAvailable}
                                    size="sm"
                                    variant="primary"
                                    onClick={() => onTranslationComplete()}
                                >
                                    <i className="fa fa-check mr-2" />
                                    {t('workspace.done')}
                                </Button>
                            ) : (
                                <p className="ml-3 my-0">{t('workspace.edited-already')}</p>
                            )}
                        </Row>
                    </Card.Header>
                )}
                <Card.Body className="overflow-hidden">
                    <ReferenceVerses type={referenceVerseType.typeSource} />
                    <div className=" mb-3">
                        <div className="d-flex">
                            <div className="d-flex align-items-center justify-content-end w-130">
                                <span className="badge badge-light-dark mt-1">
                                    {getSourceLanguage()}
                                </span>
                            </div>
                            <div className="py-1 w-100  ml-2 ">
                                <span>
                                    {selectedVerse?.verse}
                                </span>
                            </div>
                        </div>
                        <div className="d-flex mt-3">
                            <div className="d-flex align-items-center justify-content-end w-130">
                                <span className="badge badge-light-primary
                                t-2"
                                >
                                    {getTargetLanguage()}
                                </span>
                            </div>
                            <div className=" w-100">
                                <TextareaAutosize
                                    className="bg-transparent  form-control pl-10"
                                    rows={2}
                                    placeholder={t('workspace.free-edit-placeholder')}
                                    value={isFreeEditAvailable ? editValue : translatedVerse}
                                    onChange={(e) => setEditValue(e.target.value)}
                                    readOnly={!isFreeEditAvailable || !selectedVerse.verseAccess}
                                />
                            </div>
                        </div>
                    </div>
                    <ReferenceVerses type={referenceVerseType.typeTarget} />
                </Card.Body>
            </Card>
        </Col>
    );
}
export default Translation;

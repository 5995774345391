import Loader from 'components/shared/loader';
import _ from 'lodash';
import { useEffect } from 'react';
import {
    Card, Form, Nav, Tab,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ProjectFilter } from '../../components/taskTable';
import { RootState } from '../../store/reducer';
import {
    getStatusById,
    getStatusPercentage,
    getTaskStatus, selectFilter, toggleAssignedToMe
} from '../../utils/handler';
import { Verse } from '../viewTypes/task.types';
import { getVersesFromBookDetails, loadSelectedVerse, nextVerse } from './handler';

function SideBar() {
    const {
        filter, booksList, chaptersList, versesList,
    } = useSelector(
        (state: RootState) => state.taskReducer,
    );
    const { t } = useTranslation();
    const { bookDetails, workspaceLoader } = useSelector((state: RootState) => state.workspaceReducer);
    const verses: Verse[] = getVersesFromBookDetails(bookDetails);
    useEffect(() => {
        loadSelectedVerse();
    }, [filter.verseId, workspaceLoader]);
    const reproduceVerseName = (verseName: string) => {
        if (verseName === 'Title Top') return t('filter.title-top');
        const chunks = verseName.split(' ');
        return `${t('filter.title')} ${chunks[1]}`;
    };
    const getVerseById = (verseId: number = 0) => _.find(verses, { id: verseId });
    const showBook = booksList?.length > 0;
    const showChapter = showBook && filter.bookListId && chaptersList?.length > 0;
    const showVerses = showBook && showChapter && filter.chapterId && versesList?.length > 0;
    const getVerseByIndex = (index: number = 0) => verses[index];
    return (
        <Tab.Container defaultActiveKey="only-me">
            <Card>
                <Card.Header className="py-1">
                    <Form.Group className="m-0">
                        <div className="switch d-inline align-middle">
                            <Form.Control
                                type="checkbox"
                                id="switch1"
                                checked={filter?.isAssignedMe ?? false}
                                onChange={() => {
                                    toggleAssignedToMe(!(filter?.isAssignedMe ?? false));
                                }}
                                disabled={workspaceLoader}
                            />
                            <Form.Label htmlFor="switch1" className="cr mr-2" style={{ top: 5 }} />
                        </div>
                        <Form.Label className="m-0">{t('workspace.assigned-to-me')}</Form.Label>
                    </Form.Group>
                </Card.Header>
                <Card.Body>
                    <>
                        <ProjectFilter disableInput={workspaceLoader} />
                        {(
                            showBook && (
                                <>
                                    <label
                                        htmlFor={t('filter.filter-book')}
                                        className="mb-0 mt-3 text-2 font-weight-bold"
                                    >
                                        {t('filter.filter-book')}
                                    </label>
                                    <Form.Control
                                        as="select"
                                        name="bookListId"
                                        value={filter.bookListId ? `${filter.bookListId}` : ''}
                                        onChange={selectFilter}
                                        disabled={workspaceLoader}
                                    >
                                        {filter.bookListId ? (
                                            <option selected key={0} value="">
                                                {t('filter.clear')}
                                            </option>
                                        ) : (
                                            <option selected key={0} value="" disabled>
                                                {t('filter.filter-book')}
                                            </option>
                                        )}
                                        {booksList?.map((book: {
                                            id: number; name: string;
                                            bookId: number | null
                                        }) => (
                                            <option key={book.id} value={book.id}>
                                                {t(`bookList.${book.name}`)}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </>
                            )
                        )}
                    </>
                    {showChapter && (
                        <>
                            <label
                                htmlFor={t('filter.filter-chapter')}
                                className="mb-0 mt-3
                            text-2 font-weight-bold"
                            >
                                {t('filter.filter-chapter')}
                            </label>
                            <Form.Control
                                as="select"
                                name="chapterId"
                                value={filter.chapterId ? `${filter.chapterId}` : ''}
                                onChange={selectFilter}
                                disabled={workspaceLoader}
                            >
                                {filter.chapterId ? (
                                    <option selected key={0} value="">
                                        {t('filter.clear')}
                                    </option>
                                ) : (
                                    <option selected key={0} value="" disabled>
                                        {t('filter.filter-chapter')}
                                    </option>
                                )}
                                {chaptersList?.map((chapter: { id: number; name: number }) => (
                                    <option key={chapter.id} value={chapter.id}>
                                        {chapter.name === 0 ? t('filter.intro') : chapter.name}
                                    </option>
                                ))}
                            </Form.Control>
                        </>
                    )}
                    {workspaceLoader ? <Loader size={10} /> : showVerses && (
                        <>
                            <label
                                htmlFor={t('filter.filter-verse')}
                                className="mb-0 mt-3 text-2
                            font-weight-bold"
                            >
                                {t('filter.filter-verse')}
                            </label>
                            <div className="side-menu-verse-list">
                                <Nav variant="pills" className="flex-sm-column">
                                    {versesList?.map((verse: { id: number; name: string }, index: number) => (
                                        <Nav.Item
                                            key={verse.id}
                                            data-toggle="tooltip"
                                            data-placement="bottom"
                                            title={
                                                t(`status.${getStatusById(
                                                    getTaskStatus(getVerseById(verse.id)),
                                                )}`)
                                            }
                                        >
                                            <Nav.Link
                                                active={verse.id === Number(filter.verseId)}
                                                eventKey={verse.id}
                                                className="py-1 rounded-0 font-weight-bold"
                                                onClick={() => nextVerse(verse.id)}
                                            >
                                                <span className="mr-2">
                                                    <i
                                                        className="fa-solid
                                                        fa-circle bg-transparent"
                                                        style={{ color: getStatusPercentage(Number(getTaskStatus(getVerseByIndex(index)))).bgcolor }}
                                                    />
                                                </span>
                                                <span className="mr-2 mt-1">
                                                    {Number(verse.name)
                                                        ? `
                                                        ${t('workspace.verse')}
                                                        ${Number(verse.name)}`
                                                        : reproduceVerseName(verse.name)}
                                                </span>
                                            </Nav.Link>
                                        </Nav.Item>
                                    ))}
                                </Nav>
                            </div>
                        </>
                    )}
                </Card.Body>
            </Card>
        </Tab.Container>
    );
}
export default SideBar;

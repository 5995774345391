import i18n from 'internationalization/i18n';
import { localStorageKey, setLocalStorageItem } from '../../utils/handler';
import * as types from '../actions/types';
import { actionTypes } from './reducerTypes';

let initialState: Root = {
    email: '',
    previousAction: '',
    statusList: [],
    topics: [],
    languageList: [],
    selectedLanguage: 1,
    treeData: {},
    showLoader: false,
    allWords: { definitionData: [], totalPages: 0, count: 1, page: 1, limit: 50, search: '' },
    projectList: [],
    currentProject: {},
    languageVersionList: [],
    currentLocalLanguage: i18n.language

};

export interface Root {
    email: string;
    previousAction: string;
    statusList: any[];
    topics: any[];
    languageList: any[];
    selectedLanguage: number;
    treeData: TreeData;
    showLoader: boolean;
    allWords: AllWords;
    projectList: any[];
    currentProject: CurrentProject;
    languageVersionList: any[];
    currentLocalLanguage: string
}

export interface TreeData { }

export interface AllWords {
    definitionData: any[];
    totalPages: number;
    count: number;
    page: number;
    limit: number;
    search: string;
}

export interface CurrentProject {
    documentId?: number;
    id?: number;
    name?: string;
    sourceLanguageVersionId?: number;
    statusId?: number;
    targetLanguageVersionId?: number;
}

export const mainReducer = (state = initialState, action: actionTypes) => {
    switch (action.type) {
        case types.CURRENT_LOCAL_LANGUAGE:
            return {
                ...state,
                currentLocalLanguage: action.payload
            };
        case types.LANGUAGE_VERSION_LIST:
            return {
                ...state,
                languageVersionList: action.payload
            };
        case types.CURRENT_PROJECT:
            setLocalStorageItem(localStorageKey.currentProject, JSON.stringify(action.payload));
            return {
                ...state,
                currentProject: action.payload || {}
            };
        case types.PROJECT_LIST:
            return {
                ...state,
                projectList: action.payload
            };
        case types.ALL_WORDS:
            return {
                ...state,
                allWords: action.payload
            };
        case types.SHOW_LOADING_WINDOW:
            return {
                ...state,
                showLoader: action.payload
            };
        case types.TREE_VIEW:
            return {
                ...state,
                treeData: action.payload
            };
        case types.LANGUAGE_LIST:
            return {
                ...state,
                languageList: action.payload
            };

        case types.CURRENT_LANGUAGE:
            setLocalStorageItem(localStorageKey.selectedLanguage, action.payload.toString());
            return {
                ...state,
                selectedLanguage: action.payload
            };
        case types.TOPIC_LIST:
            return {
                ...state,
                topics: action.payload
            };
        case types.STATUS_LIST:
            return {
                ...state,
                statusList: action.payload
            };
        case types.PREVIOUS_ACTION:
            return {
                ...state,
                previousAction: action.payload
            };
        default:
            return state;
    }
};

export default mainReducer;

import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import errorMessages from 'utils/errorMessages';
import { IGroup, IRole, IUser } from '.';
import { ReactPagination } from '../../components/common';
import { getUsersAction } from '../../store/actions/index';
import { USER_LIST } from '../../store/actions/types';
import * as UserAction from '../../store/actions/user.action';
import { deleteUserAction } from '../../store/actions/user.action';
import { RootState } from '../../store/reducer';
import { handleToast, isValidEmail, isValidPassword } from '../../utils/handler';

function UsersPad() {
    const { userList, userGroups, userRoles, myProfile } = useSelector((state: RootState) => state.userReducer);
    const [isModalVisible, setModalVisible] = useState(false);
    const [searchKey, setSearchKey] = useState('');
    const [users, setUsers] = useState([]);
    const [selectedUserGroups, setSelectedUserGroups] = useState<Array<number>>([]);
    const [selectedRole, setSelectedRole] = useState<number>(userRoles[0]?.id);
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [editingUser, setEditingUser] = useState(-1);
    const [pageNumber, setPageNumber] = useState(1);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const renderRoleBadge = (roleId: number) => {
        switch (roleId) {
            case 1:
                return (
                    <span className="badge badge-primary">
                        <i className="fa fa-user-tie mr-2" />
                        {t('landing.administrator-title')}
                    </span>
                );
            case 2:
                return (
                    <span className="badge badge-info">
                        <i className="fa fa-address-card mr-2" />
                        {t('landing.contributor-title')}
                    </span>
                );
            case 3:
                return (
                    <span className="badge badge-warning">
                        <i className="fa fa-flask mr-2" />
                        {t('landing.datascientist-title')}
                    </span>
                );
            case 4:
                return (
                    <span className="badge badge-success">
                        <i className="fa fa-user-magnifying-glass mr-2" />
                        {t('landing.reviewer-title')}
                    </span>
                );
            default:
                return <span />;
        }
    };
    const getRoleText = (roleId: number) => {
        switch (roleId) {
            case 1:
                return t('landing.administrator-title');
            case 2:
                return t('landing.contributor-title');
            case 3:
                return t('landing.datascientist-title');
            case 4:
                return t('landing.reviewer-title');
            default:
                return <span />;
        }
    };
    useEffect(() => {
        setUsers(userList);
    }, [userList]);
    const filterUser = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchKey(e.target.value);
        const keyword = e.target.value.toLowerCase();
        setUsers(
            userList.filter((user: IUser) => user.name.toLowerCase().includes(keyword) || user.email.toLowerCase().includes(keyword))
        );
        setPageNumber(1);
    };
    const deleteUser = (id: number) => {
        // eslint-disable-next-line no-alert
        if (window.confirm(t('user-management.user-delete-confirmation-message') as string)) {
            deleteUserAction(id).then((response: string) => {
                if (response) {
                    handleToast({ message: response }, 'success');
                    dispatch({ type: USER_LIST, payload: userList.filter((user: IUser) => user.id !== id) });
                }
            });
        }
    };
    const selectUserGroups = (id: number, value: boolean) => {
        if (value) setSelectedUserGroups([...selectedUserGroups, id]);
        else setSelectedUserGroups(selectedUserGroups.filter((userGroup) => userGroup !== id));
    };
    const checkFormValidation = () => {
        let isValid = false;
        if (!fullName) {
            handleToast({ message: errorMessages.requiredFieldIsMissing });
        } else if (!email) {
            handleToast({ message: errorMessages.emptyEmail });
        } else if (!isValidEmail(email)) {
            handleToast({ message: errorMessages.invalidEmail });
        } else if (!password) {
            handleToast({ message: errorMessages.passwordIsMissing });
        } else if (!isValidPassword(password)) {
            handleToast({ message: errorMessages.passwordInvalidContent });
        } else if (password !== confirmPassword) {
            handleToast({ message: errorMessages.confirmPasswordMsg });
        } else {
            isValid = true;
        }
        return isValid;
    };
    const createUser = async () => {
        if (checkFormValidation()) {
            await UserAction.createUser({
                email,
                name: fullName,
                roleId: selectedRole,
                password,
                confirmPassword,
                userGroups: selectedUserGroups
            }).then((response) => {
                if (response) {
                    setEditingUser(-1);
                    handleToast({ message: response }, 'success');
                    getUsersAction();
                    setModalVisible(false);
                }
            });
        }
    };
    const updateUser = async () => {
        await UserAction.updateUser(editingUser, {
            name: fullName,
            email,
            roleId: selectedRole,
            userGroups: selectedUserGroups
        }).then((response) => {
            if (response) {
                handleToast({ message: response }, 'success');
                getUsersAction();
                setModalVisible(false);
            }
        });
    };
    const handleNextPage = (pgNumber: number) => {
        setPageNumber(pgNumber);
    };
    return (
        <>
            <Modal backdrop="static" centered show={isModalVisible} onHide={() => setModalVisible(false)}>
                <Modal.Header closeButton>
                    <Modal.Title as="h5">{editingUser === -1 ? t('user-management.new-user') : t('user-management.edit-user')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group as={Row} controlId="name">
                            <Form.Label column sm={3}>
                                {t('user-management.full-name')}<span className='text-danger ml-1'>*</span>
                            </Form.Label>
                            <Col sm={9}>
                                <Form.Control
                                    type="text"
                                    placeholder={t('user-management.full-name')}
                                    value={fullName}
                                    onChange={(e) => setFullName(e.target.value)}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="email">
                            <Form.Label column sm={3}>
                                {t('user-management.email')}<span className='text-danger ml-1'>*</span>
                            </Form.Label>
                            <Col sm={9}>
                                <Form.Control
                                    type="email"
                                    placeholder={t('user-management.email')}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Col>
                        </Form.Group>
                        {editingUser === -1 && (
                            <>
                                <Form.Group as={Row} controlId="password">
                                    <Form.Label column sm={3}>
                                        {t('user-management.password')}<span className='text-danger ml-1'>*</span>
                                    </Form.Label>
                                    <Col sm={9}>
                                        <Form.Control
                                            type="password"
                                            placeholder={t('user-management.password')}
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="confirm-password">
                                    <Form.Label column sm={3}>
                                        {t('user-management.confirm-password')}<span className='text-danger ml-1'>*</span>
                                    </Form.Label>
                                    <Col sm={9}>
                                        <Form.Control
                                            type="password"
                                            placeholder={t('user-management.confirm-password')}
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                        />
                                    </Col>
                                </Form.Group>
                            </>
                        )}
                        <fieldset>
                            <Form.Group as={Row}>
                                <Form.Label as="legend" column sm={3}>
                                    {t('user-management.role')}<span className='text-danger ml-1'>*</span>
                                </Form.Label>
                                <Col sm={9}>
                                    {userRoles.map(({ id, roleType }: IRole) => (
                                        <Form.Check
                                            key={`${id}-${roleType}`}
                                            type="radio"
                                            label={getRoleText(id)}
                                            name="roleRadios"
                                            id={`${id}-${roleType}`}
                                            checked={id === selectedRole}
                                            onClick={() => setSelectedRole(id)}
                                        />
                                    ))}
                                </Col>
                            </Form.Group>
                        </fieldset>
                    </Form>
                    <Row>
                        <Col sm={3}>{t('user-management.groups')}</Col>
                        <Col sm={9}>
                            {userGroups?.map(({ id, group }: IGroup) => {
                                const isGroupSelected = selectedUserGroups.findIndex((userGroup) => userGroup === id) >= 0;
                                const wrapperClassName = `badge badge-sm ${isGroupSelected ? 'badge-primary' : 'badge-secondary'
                                    } badge-pill text-uppercase mx-1`;
                                return (
                                    <span
                                        key={`${id}-${group}`}
                                        className={wrapperClassName}
                                        style={{
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => selectUserGroups(id, !isGroupSelected)}
                                    >
                                        <i className="fa fa-users mr-1" />
                                        {group}
                                    </span>
                                );
                            })}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="btn btn-sm"
                        onClick={() => {
                            // eslint-disable-next-line no-unused-expressions
                            editingUser < 0 ? createUser() : updateUser();
                        }}
                    >
                        <i className="fa fa-save mr-2" />
                        {t('user-management.save')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Row className="px-3">
                <Col className="col-auto">
                    <Card>
                        <Card.Header className="py-2 px-3">
                            <Row>
                                <Col>
                                    <form className="has-validation">
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            placeholder={t('user-management.search-email-name-placeholder') as string}
                                            value={searchKey}
                                            onChange={filterUser}
                                        />
                                    </form>
                                </Col>
                                <Col className="col-auto">
                                    <Button
                                        variant="primary"
                                        className="btn btn-sm btn-primary"
                                        onClick={() => {
                                            setModalVisible(true);
                                            setFullName('');
                                            setEmail('');
                                            setPassword('');
                                            setConfirmPassword('');
                                            setSelectedRole(userRoles[0]?.id || 0);
                                            setSelectedUserGroups([]);
                                            setEditingUser(-1);
                                        }}
                                    >
                                        <i className="fa fa-user-plus mr-2" />
                                        {t('user-management.new-user') as string}
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body className="overflow-hidden py-0">
                            <Row>
                                <Table hover responsive size="xs">
                                    <thead>
                                        <tr>
                                            <th className="w-10 text-center">{t('user-management.action')}</th>
                                            <th>{t('user-management.name')}</th>
                                            <th>{t('user-management.email')}</th>
                                            <th className="text-center">{t('user-management.group-users')}</th>
                                            <th className="text-center">{t('user-management.role')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {users
                                            .slice((pageNumber - 1) * 10, pageNumber * 10)
                                            // eslint-disable-next-line no-shadow
                                            .map(({ id, name, email, role, assignedGroups }: IUser) => (
                                                <tr key={id}>
                                                    <th scope="row" className="text-center">
                                                        <div className="btn-group">
                                                            <Button
                                                                variant="outline-primary"
                                                                className="btn btn-outline-primary btn-sm border-0"
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                title={t('user-management.edit-user') as string}
                                                                onClick={() => {
                                                                    setEditingUser(id);
                                                                    setFullName(name);
                                                                    setEmail(email);
                                                                    setSelectedRole(role?.id);
                                                                    setSelectedUserGroups(
                                                                        assignedGroups?.map((group: IGroup) => group.id) || []
                                                                    );
                                                                    setModalVisible(true);
                                                                }}
                                                            >
                                                                <i className="fa fa-pen" />
                                                            </Button>
                                                            {myProfile.id !== id ? (
                                                                <button
                                                                    aria-label='delete-user'
                                                                    className="btn btn-outline-primary btn-sm border-0"
                                                                    data-toggle="tooltip"
                                                                    data-placement="bottom"
                                                                    title={t('user-management.delete-user') as string}
                                                                    onClick={() => deleteUser(id)}
                                                                >
                                                                    <i className="fas fa-trash-alt" />
                                                                </button>
                                                            ) : null}
                                                        </div>
                                                    </th>
                                                    <td>{name}</td>
                                                    <td>{email}</td>
                                                    <td className="text-center">
                                                        {assignedGroups?.map(({ group }) => (
                                                            <span
                                                                key={`${email}-${group}`}
                                                                className="badge badge-sm badge-secondary badge-pill text-uppercase mx-1"
                                                            >
                                                                <i className="fa fa-users mr-1" />
                                                                {group}
                                                            </span>
                                                        ))}
                                                    </td>
                                                    <td className="text-center">{renderRoleBadge(role.id)}</td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </Table>
                            </Row>
                            <Row className="d-flex justify-content-center align-items-center">
                                <ReactPagination
                                    pagination={{
                                        totalPages: Math.ceil(users.length / 10),
                                        page: pageNumber
                                    }}
                                    onPageChange={handleNextPage}
                                />
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
}
export default UsersPad;

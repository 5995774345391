import _ from 'lodash';
import { TranslatedContainer, Verse } from '../../views/viewTypes/task.types';
import {
    ADD_SELECTED_WORD,
    BOOK_DETAILS,
    CREATE_NEW_CONTAINER,
    CURRENT_CONTAINER_MODAL,
    CURRENT_INDEX,
    CURRENT_TASK_FOR_MODEL,
    CURRENT_WORD_FOR_MODEL,
    CURRENT_WORD_FOR_SOURCE_MODAL,
    FETCH_TRELLO,
    GET_GRAMMAR_LIST,
    HISTORY_MODAL,
    MEANING_MODEL,
    MODEL_ACTION,
    RESET_BOOK,
    SELECTED_VERSE,
    SET_AVAILABLE_FILTERS,
    SET_CURRENT_TASK,
    SHOW_VERSE_TOPICS,
    VERSE_MODEL,
    WORKSPACE_LOADER
} from '../actions/types';
import { actionTypes } from './reducerTypes';
export let eventKeys = {
    translate: 'Translate',
    edit: 'Edit',
    mapping: 'Mapping',
    structure: 'Structure'
};
export let ActionType = {
    reject: 'Reject',
    done: 'Done'
};
export let emptyModalObj = {
    showHistory: false,
    showPopup: false,
    cIndex: '',
    vIndex: '',
    tIndex: '',
    wIndex: '',
    forNew: true,
    taskDetails: {},
    historyDetails: {},
    access: false,
    showVerseModel: false,
    verseModelDetail: { verseDetails: {}, verseAccess: false, cIndex: '', vIndex: '', showVerseModel: false }
};
export interface InitialResetBook {
    resetWordSpace: boolean;
    resetTrello: boolean;
}
export let initialResetBook: InitialResetBook = {
    resetWordSpace: false,
    resetTrello: false
};
export let workspaceInitialState: any = {
    isCreatingContainer: false,
    selectedVerse: {},
    selectedVerseIndex: -1,
    translatedVerse: {},
    bookDetails: {
        totalPages: 0,
        page: 1,
        book: '',
        chapters: []
    },
    availableFilters: {},
    workspaceLoader: false,
    currentWordForModel: emptyModalObj,
    currentContainerForModel: emptyModalObj,
    currentWordForSourceModal: emptyModalObj,
    historyModal: emptyModalObj,
    currentTask: {},
    grammarList: [],
    currentTaskForModel: { id: 0 },
    modelAction: { cIndex: '', vIndex: '', tIndex: '', wIndex: '', access: false },
    selectedWords: { languageId: 0, verseId: 0, wordIds: [] },
    showVerseTopics: false,
    meaningPopup: { showMeaning: false, taskId: 0, meaningObj: {} },
    resetBook: initialResetBook,
    fetchTrello: true,
    currentIndex: { cIndex: '', vIndex: '', tIndex: '', wIndex: '' },
    translatedContainer: { containerDetail: {}, cIndex: '', vIndex: '', tIndex: '', wIndex: '' },
    verseModelDetail: { ...emptyModalObj.verseModelDetail },
    translatedContainers: []
};
export const workspaceReducer = (state = workspaceInitialState, action: actionTypes) => {
    switch (action.type) {
        case MEANING_MODEL:
            return {
                ...state,
                meaningPopup: action.payload
            };
        case VERSE_MODEL:
            return {
                ...state,
                verseModelDetail: action.payload
            };

        case SHOW_VERSE_TOPICS:
            return {
                ...state,
                showVerseTopics: action.payload
            };
        case GET_GRAMMAR_LIST:
            return {
                ...state,
                grammarList: action.payload
            };
        case ADD_SELECTED_WORD:
            let wordIds: number[] = handleWordCheck(action.payload, state.selectedWords);

            return {
                ...state,
                selectedWords: { languageId: action.payload.languageId, verseId: action.payload.verseId, wordIds }
            };
        case SET_CURRENT_TASK:
            return {
                ...state,
                currentTask: action.payload
            };
        case CURRENT_INDEX:
            return {
                ...state,
                currentIndex: action.payload
            };
        case CURRENT_TASK_FOR_MODEL:
            return {
                ...state,
                currentTaskForModel: action.payload
            };
        case MODEL_ACTION:
            return {
                ...state,
                modelAction: action.payload
            };
        case CURRENT_CONTAINER_MODAL:
            return {
                ...state,
                currentContainerForModel: action.payload
            };
        case HISTORY_MODAL:
            return {
                ...state,
                historyModal: action.payload
            };
        case CURRENT_WORD_FOR_MODEL:
            let payload = { ...state.currentWordForModel, ...action.payload };
            return {
                ...state,
                currentWordForModel: payload
            };
        case CURRENT_WORD_FOR_SOURCE_MODAL:
            let modal = { ...state.currentWordForSourceModal, ...action.payload };
            return {
                ...state,
                currentWordForSourceModal: modal
            };
        case RESET_BOOK:
            return {
                ...state,
                resetBook: action.payload
            };
        case FETCH_TRELLO:
            return {
                ...state,
                fetchTrello: action.payload
            };
        case BOOK_DETAILS:
            let bookDetails = _.isEmpty(action.payload) ? action.payload : { ...state.bookDetails, ...action.payload };
            return {
                ...state,
                bookDetails
            };
        case WORKSPACE_LOADER:
            return {
                ...state,
                workspaceLoader: action.payload
            };
        case SET_AVAILABLE_FILTERS:
            return {
                ...state,
                availableFilters: action.payload
            };
        case SELECTED_VERSE:
            let selectedVerse: Verse = action.payload || state.selectedVerse;
            let translatedVerse = {};
            let translatedContainers: TranslatedContainer[] = [];
            const verses = (state.bookDetails && state.bookDetails?.chapters && state.bookDetails?.chapters[0]?.verses) || [];
            let selectedVerseIndex = verses.findIndex((verseObj: Verse) => verseObj.id === selectedVerse.id);
            if (selectedVerse?.translatedVerses) {
                translatedVerse = selectedVerse?.translatedVerses[0];
                translatedContainers = selectedVerse.translatedContainers
            }
            return {
                ...state,
                selectedVerse,
                selectedVerseIndex,
                translatedVerse,
                translatedContainers
            };
        case CREATE_NEW_CONTAINER:
            return {
                ...state,
                isCreatingContainer: action.payload
            };

        default:
            return state;
    }
};

export default workspaceReducer;

const handleWordCheck = (payload: any, selectedWords: typeof workspaceInitialState.selectedWords) => {
    let wordDetail: { id: number; checked: boolean; verseId: number } = payload;
    if (selectedWords.languageId !== 0 && selectedWords.languageId !== payload.languageId) {
        selectedWords.wordIds = [];
    }
    let wordIds: number[] = selectedWords.wordIds;

    if (selectedWords.verseId === 0 || selectedWords.verseId === wordDetail.verseId) {
        if (wordDetail.checked) {
            wordIds.push(wordDetail.id);
        } else {
            wordIds = _.filter(wordIds, (id) => id !== wordDetail.id);
        }
    } else {
        wordIds = wordDetail.id ? [wordDetail.id] : [];
    }
    return wordIds;
};

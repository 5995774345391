/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import React, { FormEvent, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import successMessages from 'utils/successMessages';
import { IGroup, IUser } from '.';
import { ReactPagination } from '../../components/common';
import {
    addRemoveUserToGroup,
    createUserGroups,
    deleteUserGroup,
    fetchUserGroups,
    getUserByGroupId,
    updateUserGroups
} from '../../store/actions/index';
import { RootState } from '../../store/reducer';
import errorMessages from '../../utils/errorMessages';
import { handleToast } from '../../utils/handler';

let searchFilter: ReturnType<typeof setTimeout>;
const userActionTypes = {
    add: 'Add',
    remove: 'Remove'
};
function TwoColumnDragDrop(props: { currentUserGroup: { id: number } }) {
    const { currentUserGroup } = props;
    const [groupUsers, setGroupUsers] = useState<{ pagination?: { totalPages?: number; page?: number; limit?: number }; users?: IUser[] }>({
        users: []
    });
    const [search, setSearch] = useState('');
    const [showGroupUsers, setShowGroupUsers] = useState<boolean>(true);
    const { t } = useTranslation();
    const getGroupUsers = (filter: { showGroupUsers?: boolean; page?: number; limit?: number; search?: string } = { showGroupUsers }) => {
        filter.showGroupUsers = showGroupUsers;
        getUserByGroupId(currentUserGroup.id, filter).then((groupUsersRes) => {
            if (groupUsersRes) {
                groupUsersRes.users.forEach((user: IUser) => {
                    user.groupUser = !!_.find(user.assignedGroups, { id: currentUserGroup.id });
                });
                setGroupUsers(groupUsersRes);
            }
        });
    };
    useEffect(() => {
        getGroupUsers({ page: 1, search, showGroupUsers });
    }, [currentUserGroup.id, showGroupUsers]);
    const handleUserPadNextPage = (pgNumber: number) => {
        getGroupUsers({ page: pgNumber });
    };
    const AddOrRemoveUser = async (userId: number, userGroupId: number, type = userActionTypes.add) => {
        // eslint-disable-next-line no-alert
        if (type === userActionTypes.add || window.confirm(t(successMessages.removeUserFromGroup))) {
            addRemoveUserToGroup({ userGroupId, userId }).then((result) => {
                if (result) {
                    const userIndex = _.findIndex(groupUsers?.users, (user) => user.id === userId);
                    const users = groupUsers?.users ?? [];
                    if (users.length > 0 && users[userIndex]) users[userIndex].groupUser = type === userActionTypes.add;
                    setGroupUsers({ ...groupUsers, users });
                    handleToast(
                        {
                            message: t(
                                type === userActionTypes.add
                                    ? 'user-management.user-added-to-usergroup'
                                    : 'user-management.user-removed-from-usergroup'
                            )
                        },
                        'success'
                    );
                }
            });
        }
    };
    // add setTimeOut to avoid drastic events
    const searchUser = (searchValue: string) => {
        if (searchFilter) {
            clearTimeout(searchFilter);
        }
        searchFilter = setTimeout(() => {
            getGroupUsers({ search: searchValue, page: 1 });
        }, 500);
        setSearch(searchValue);
    };
    return (
        <Row>
            <Col className="col-12 col-sm-12 col-md-12 col-lg-auto">
                <Card>
                    <Card.Header className="py-2">
                        <Row>
                            <Col className="col-auto pt-2">
                                <h5 className="text-uppercase">{t('user-project-management.users')}</h5>
                            </Col>
                        </Row>
                        <Row className="pt-2 d-flex justify-content-between">
                            <Col className="col-12 col-sm-12 col-md-6 col-lg-6">
                                <input
                                    className="form-control"
                                    placeholder={t('user-management.search-email-name-placeholder') as string}
                                    value={search}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => searchUser(e.target.value)}
                                />
                            </Col>
                            <Col className="col-12 col-sm-12 col-md-6 col-lg-6 d-flex justify-content-center align-items-center">
                                <input
                                    className="mr-2"
                                    checked={showGroupUsers}
                                    type="checkbox"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setShowGroupUsers(e.target.checked);
                                    }}
                                />{' '}
                                {t('user-group.show-only-group-users')}
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body className="overflow-hidden py-0">
                        <Row>
                            <Table hover responsive size="xs" id="data-table-zero">
                                <thead>
                                    <tr>
                                        <th>{t('user-management.action')}</th>
                                        <th>{t('user-management.name')}</th>
                                        <th>{t('user-management.email')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {groupUsers?.users?.map((user: IUser) => (
                                        <tr
                                            key={user.id}
                                            className="c-pointer"
                                            onClick={() =>
                                                AddOrRemoveUser(
                                                    user.id,
                                                    currentUserGroup.id,
                                                    user.groupUser ? userActionTypes.remove : userActionTypes.add
                                                )
                                            }
                                        >
                                            <td>
                                                {user.groupUser ? (
                                                    <button
                                                        className="btn btn-outline-primary btn-sm border-0 text-danger"
                                                        data-toggle="tooltip"
                                                        data-placement="bottom"
                                                        title={t('user-management.remove-from-group') as string}
                                                    >
                                                        {t('user-group.remove')} <i className="fas fa-minus" />
                                                    </button>
                                                ) : (
                                                    <button
                                                        className="btn btn-outline-primary btn-sm border-0 text-success"
                                                        data-toggle="tooltip"
                                                        data-placement="bottom"
                                                        title={t('user-management.add-from-group') as string}
                                                    >
                                                        {t('user-group.add')} <i className="fas fa-plus" />
                                                    </button>
                                                )}
                                            </td>
                                            <td>{user.name}</td>
                                            <td>{user.email}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Row>
                        <Row className="d-flex justify-content-center align-items-center">
                            <ReactPagination
                                pagination={{
                                    totalPages: groupUsers?.pagination?.totalPages,
                                    page: groupUsers?.pagination?.page
                                }}
                                onPageChange={handleUserPadNextPage}
                            />
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}
function UserGroupPad() {
    const [isMultiTarget, setIsMultiTarget] = useState<string[]>([]);
    const { userGroups } = useSelector((state: RootState) => state.userReducer);
    const [isUserGroupCreateModalVisible, setUserGroupCreateModalVisible] = useState<boolean>(false);
    const [groupName, setGroupName] = useState('');
    const [editingUserGroup, setEditingUserGroup] = useState(-1);
    const [filterGroup, setFilterGroup] = useState('');
    const { t } = useTranslation();
    const [currentUserGroup, setCurrentUserGroup] = useState<IGroup>();
    const targetHandler = (target: string) => {
        if (isMultiTarget.findIndex((item) => item === target) > -1) {
            setIsMultiTarget((prevState) => prevState.filter((item) => item !== target));
        } else {
            setIsMultiTarget((prevState) => [...prevState, target]);
        }
    };
    const createUserGroup = async (e: React.FormEvent) => {
        e.preventDefault();
        if (groupName) {
            createUserGroups({ groupName }).then((res) => {
                if (res) {
                    handleToast({ message: t('user-management.user-group-created-notification') }, 'success');
                }
                setUserGroupCreateModalVisible(false);
                fetchUserGroups();
            });
        } else {
            handleToast({ message: errorMessages.missingMandatoryField });
        }
    };
    const updateUserGroup = async (e: React.FormEvent) => {
        e.preventDefault();
        if (groupName) {
            updateUserGroups({ groupName }, editingUserGroup).then(() => {
                handleToast({ message: t('user-management.group-update-success-message') }, 'success');
                fetchUserGroups();
                setUserGroupCreateModalVisible(false);
            });
        } else {
            handleToast({ message: errorMessages.missingMandatoryField });
        }
    };
    const removeUserGroup = async (groupId: number) => {
        // eslint-disable-next-line no-alert
        if (window.confirm(t(successMessages.deleteUserGroupConfirmation))) {
            deleteUserGroup(groupId).then(() => {
                handleToast({ message: t('user-management.user-group-removed-message') }, 'success');
                fetchUserGroups();
                if (currentUserGroup?.id === groupId) {
                    setCurrentUserGroup(undefined);
                }
            });
        }
    };
    const formSubmit = (e: FormEvent) => {
        if (editingUserGroup < 0) { createUserGroup(e); }
        else updateUserGroup(e);
    };
    return (
        <>
            <Modal backdrop="static" centered show={isUserGroupCreateModalVisible} onHide={() => setUserGroupCreateModalVisible(false)}>
                <Modal.Header closeButton>
                    <Modal.Title as="h5">{`${editingUserGroup < 0 ? t('user-group.create-user-group') : t('user-management.update-user-group')
                        }`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        onSubmit={formSubmit}
                    >
                        <Form.Group as={Row} controlId="name">
                            <Form.Label column sm={3}>
                                {t('user-group.group-name')}
                            </Form.Label>
                            <Col sm={9}>
                                <Form.Control
                                    type="text"
                                    placeholder={t('user-group.name-of-group')}
                                    value={groupName}
                                    onChange={(e) => setGroupName(e.target.value)}
                                    required
                                />
                            </Col>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="btn btn-sm"
                        onClick={formSubmit}
                    >
                        <i className="fa fa-save mr-2" />
                        {t('user-group.save')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Row className="px-3">
                <Col className="col-6 col-sm-6 col-md-6 col-lg-auto">
                    <Card>
                        <Card.Header className="py-2 px-3">
                            <Row>
                                <Col>
                                    <form className="has-validation">
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            placeholder={t('user-group.search-group-name') as string}
                                            value={filterGroup}
                                            onChange={(e) => setFilterGroup(e.target.value)}
                                        />
                                    </form>
                                </Col>
                                <Col className="col-auto">
                                    <Button
                                        variant="primary"
                                        className="btn btn-sm btn-primary"
                                        onClick={() => {
                                            setGroupName('');
                                            setUserGroupCreateModalVisible(true);
                                            setEditingUserGroup(-1);
                                        }}
                                    >
                                        <i className="fa fa-plus mr-2" />
                                        {t('user-group.new-user-group')}
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body className="p-0 overflow-hidden">
                            <Table responsive hover size="xs" className="overdlow-hidden">
                                <thead>
                                    <tr>
                                        <th className="w-10 text-center">{t('user-group.action')}</th>
                                        <th>{t('user-group.group-name')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userGroups
                                        .filter((user: { id: number; group: string }) => user.group.toLocaleLowerCase().includes(filterGroup.toLocaleLowerCase()))
                                        .map((userGroup: IGroup) => (
                                            <tr
                                                key={userGroup.id}
                                                style={{
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                <th aria-label='user-group' scope="row" className="text-center">
                                                    <div className="btn-group">
                                                        <Button
                                                            variant="outline-primary"
                                                            className="btn btn-outline-primary btn-sm border-0"
                                                            data-toggle="tooltip"
                                                            data-placement="bottom"
                                                            title={t('user-group.update-user-group') as string}
                                                            onClick={() => {
                                                                setGroupName(userGroup.group);
                                                                setEditingUserGroup(userGroup.id);
                                                                setUserGroupCreateModalVisible(true);
                                                            }}
                                                        >
                                                            <i className="fa fa-pen" />
                                                        </Button>
                                                        <button
                                                            className="btn btn-outline-primary btn-sm border-0"
                                                            data-toggle="tooltip"
                                                            data-placement="bottom"
                                                            title={t('user-group.delete-user-group') as string}
                                                            onClick={() => removeUserGroup(userGroup.id)}
                                                        >
                                                            <i className="fas fa-trash-alt" />
                                                        </button>
                                                    </div>
                                                </th>
                                                <th
                                                    onClick={() => {
                                                        setCurrentUserGroup(userGroup);
                                                        targetHandler('target5');
                                                    }}
                                                    aria-expanded={isMultiTarget.some((target) => target === 'target5')}
                                                    style={{ color: currentUserGroup?.id === userGroup.id ? 'red' : 'black' }}
                                                >
                                                    {userGroup.group}
                                                </th>
                                            </tr>
                                        ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className="col-6 col-sm-6 col-md-6 col-lg-auto">
                    {currentUserGroup && <TwoColumnDragDrop currentUserGroup={currentUserGroup} />}
                </Col>
            </Row>
        </>
    );
}
export default UserGroupPad;

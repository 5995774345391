import axios from 'axios';
import { baseUrl, requestHeader } from '../../config';
import { getCurrentProjectId } from '../../utils/handler';
import { UpdateTranslatedWordType } from '../../views/types';
import { UpdateContainerOrderType } from '../../views/viewTypes/translatedContainer.type';
import { CatchReturnError } from './actionHandler';

interface TranslatedWords {
    translatedVerse: string;
    languageVersionId: number;
    versesId: number;
    translatedVerseId: number;
    projectId?: number
}
export const translatedWordsFreeEdit = async (payload: TranslatedWords) => {
    payload.translatedVerse = payload.translatedVerse.split('\t').join(' ');
    payload.projectId = getCurrentProjectId()
    return await axios
        .post(`${baseUrl}/translatedWordsFreeEdit`, payload, requestHeader)
        .then((res) => {
            return res['data'];
        })
        .catch((err) => {
            CatchReturnError(err);
        });
};

export const saveTranslatedVerse = async (payload: TranslatedWords) => {
    payload.projectId = getCurrentProjectId()
    payload.translatedVerse = payload.translatedVerse.split('\t').join(' ');
    return await axios
        .post(`${baseUrl}/saveTranslatedVerse`, payload, requestHeader)
        .then((res) => {
            return res['data'];
        })
        .catch((err) => {
            CatchReturnError(err);
        });
};

export const updateTranslatedWord = async (id: number, payload: UpdateTranslatedWordType) => {
    return await axios
        .patch(`${baseUrl}/translatedWord/` + id, payload, requestHeader)
        .then((res) => {
            return res['data'];
        })
        .catch((err) => {
            CatchReturnError(err);
        });
};

export const createTranslatedWord = async (payload: UpdateTranslatedWordType) => {
    return await axios
        .post(`${baseUrl}/translatedWord`, payload, requestHeader)
        .then((res) => {
            return res['data'];
        })
        .catch((err: any) => {
            CatchReturnError(err);
        });
};

// export const saveContainerAndDestination = async (payload: CreateContainerType) => {
//     payload.word = payload.word.split("\t").join(" ")
//     return await axios
//         .post(`${baseUrl}/createTranslatedContainerWord`, payload, requestHeader)
//         .then((res) => {
//             return res['data'];
//         })
//         .catch((err) => {
//             CatchReturnError(err);
//         });
// };

export const createOrUpdateTranslatedWord = async (id: number = 0, payload: UpdateTranslatedWordType) => {
    if (id === 0) {
        return await createTranslatedWord(payload);
    } else {
        return await updateTranslatedWord(id, payload);
    }
};

export const deleteTranslatedWord = async (id: number) => {
    return await axios
        .delete(`${baseUrl}/translatedWord/${id}`, requestHeader)
        .then((res) => {
            return res['data'];
        })
        .catch((err) => {
            CatchReturnError(err);
        });
};

export const deleteSourceWordById = async (wordId: number) => {
    return await axios
        .delete(`${baseUrl}/deleteSourceWord/${wordId}`, requestHeader)
        .then((res) => {
            return res['data'];
        })
        .catch((err) => {
            CatchReturnError(err);
        });
};

export const updateContainerOrder = async (payload: UpdateContainerOrderType) => {
    return await axios
        .patch(`${baseUrl}/updateContainerOrder`, payload, requestHeader)
        .then((res) => {
            return res['data'];
        })
        .catch((err) => {
            CatchReturnError(err);
        });
};

export const updateTranslatedContainer = async (id: number, payload: UpdateContainerOrderType) => {
    return await axios
        .patch(`${baseUrl}/translatedContainer/${id}`, payload, requestHeader)
        .then((res) => {
            return res['data'];
        })
        .catch((err) => {
            CatchReturnError(err);
        });
};

export const overwriteContainerWords = async (containerId: number, payload: any) => {
    return await axios
        .post(`${baseUrl}/overwriteContainerWords/${containerId}`, payload, requestHeader)
        .then((res) => {
            return res['data'];
        })
        .catch((err) => {
            CatchReturnError(err);
        });
};

// export const createEmptyTranslatedContainer = async (payload: {versesId: number; languageId: number}) => {
//     return await axios
//         .post(`${baseUrl}/createEmptyTranslatedContainer/`, payload, requestHeader)
//         .then((res) => {
//             return res['data'];
//         })
//         .catch((err) => {
//             CatchReturnError(err);
//         });
// };

export const createTranslatedContainer = async (id: number, payload: UpdateContainerOrderType) => {
    return await axios
        .patch(`${baseUrl}/translatedContainer/${id}`, payload, requestHeader)
        .then((res) => {
            return res['data'];
        })
        .catch((err) => {
            CatchReturnError(err);
        });
};

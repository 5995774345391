/* eslint-disable @typescript-eslint/no-explicit-any */
import Papa from 'papaparse';
import { CatchReturnError } from '../store/actions/actionHandler';
import { IDataset } from '../views/aligned-sentences';

export type SentenceStatusType = 'Incomplete' | 'Completed' | 'Rejected';
export function exportToTsv(filename: string, rows: Array<string[]>) {
    function processRow(row: any) {
        let finalVal = '';
        for (let j = 0; j < row.length; j += 1) {
            let innerValue = row[j] === null ? '' : row[j].toString();
            if (row[j] instanceof Date) {
                innerValue = row[j].toLocaleString();
            }
            let result = innerValue.replace(/"/g, '""');
            if (result.search(/("|,|\n)/g) >= 0) result = `"${result}"`;
            if (j > 0) finalVal += '\t';
            finalVal += result;
        }
        return `${finalVal}\n`;
    };
    let tsvFile = '';
    for (let i = 0; i < rows.length; i += 1) {
        tsvFile += processRow(rows[i]);
    }
    const blob = new Blob([tsvFile], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}
export function importSentenceDatasetFromCsv(fileContent: string, fileName: string, languageId: number) {
    const dataset: IDataset = {
        id: 0,
        languageId: 0,
        title: 'Dataset Title',
        description: 'Dataset Description',
        sentences: []
    };
    try {
        const csv = Papa.parse(fileContent).data as Array<Array<any>>;
        dataset.title = fileName;
        dataset.description = fileName;
        dataset.languageId = languageId;
        for (let i = 1; i < csv.length; i += 1) {
            const chunks = csv[i][0].split('\t');
            if (chunks.length === 2) {
                dataset.sentences.push({
                    id: i,
                    source: String(chunks[0]),
                    destination: String(chunks[1]),
                    status: 'Incomplete'
                });
            }
        }
    } catch (err) {
        CatchReturnError(err);
    }
    return dataset;
}

import { VERSE_LIST } from '../actions/types';
import { actionTypes } from './reducerTypes';

let initialState = {
    count: 10,
    verseList: [],
    limit: 10,
    page: 1,
    totalPages: 0,
    verseActionByLanguage: {}
};

export const verseReducer = (state = initialState, action: actionTypes) => {
    switch (action.type) {
        case VERSE_LIST:
            return {
                ...state,
                count: action.payload.count,
                limit: action.payload.limit,
                page: action.payload.page,
                totalPages: action.payload.totalPages,
                verseList: action.payload.data
            };

        default:
            return state;
    }
};

export default verseReducer;

import Axios from 'axios';
import { baseUrl } from '../../config';
import store from '../store';
import { CatchReturnError } from './actionHandler';
import { ACCOUNT_LOCKED, LANGUAGE_LIST } from './types';

export const resetPassword = (payload: any) => {
    return Axios.patch(baseUrl + '/resetPassword', payload)
        .then((res) => {
            return res['data'];
        })
        .catch((err) => {
            CatchReturnError(err);
        });
};

export const forgotPassword = (payload: any) => {
    return Axios.patch(baseUrl + '/forgotPassword', payload)
        .then((res) => {
            return res['data'];
        })
        .catch((err) => {
            CatchReturnError(err);
        });
};
export const getLanguages = () => {
    return Axios.get(baseUrl + '/getLanguages')
        .then((res) => {
            let data = res['data'];
            store.dispatch({ type: LANGUAGE_LIST, payload: data });
            return data;
        })
        .catch((err) => {
            CatchReturnError(err);
        });
};
export const login = (payload: any) => {
    return Axios.post(baseUrl + '/login', payload)
        .then((res) => {
            store.dispatch({ type: ACCOUNT_LOCKED, payload: false })
            return res['data'];
        }).catch((error: { response: { status: number } }) => {
            if (error?.response?.status === 423) {
                store.dispatch({ type: ACCOUNT_LOCKED, payload: true })
            } else {
                store.dispatch({ type: ACCOUNT_LOCKED, payload: false })
            }
            CatchReturnError(error);
        });
};

export const signup = (payload: any) => {
    return Axios.post(baseUrl + '/signup', payload)
        .then((res) => {
            return res['data'];
        })
        .catch((err) => {
            CatchReturnError(err);
        });
};

export const passwordResetRequest = (payload: any) => {
    return Axios.post(baseUrl + '/forgot-password', payload)
        .then((res) => {
            return res['data'];
        })
        .catch((err) => {
            CatchReturnError(err);
        });
};

export const resetPasswordWithResetToken = (payload: any) => {
    return Axios.post(baseUrl + '/reset-password', payload)
        .then((res) => {
            return res['data'];
        })
        .catch((err) => {
            CatchReturnError(err);
        });
};

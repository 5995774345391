const DEMO = {
    BLANK_LINK: '#'
};

export const FILTER_LOADING_TYPE = {
    PROJECT: 1,
    BOOK: 2,
    CHAPTER: 3,
    VERSE: 4
};

export default DEMO;

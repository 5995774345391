import { MenuItemType } from '../../../../../../menu-items';

interface NavIconProps {
    items: MenuItemType;
}
function NavIcon(props: NavIconProps) {
    return props.items.icon ? (
        <span className="pcoded-micon">
            {/* <i className="fas fa-bars"></i> */}
            <i className={props.items.icon} />
        </span>
    ) : null;
}
export default NavIcon;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { UPDATE_BREADCRUMBS } from '../store/actions/types';
import { checkRole, handleToast, rolesObject } from '../utils/handler';

function Dashboard() {
    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({
            type: UPDATE_BREADCRUMBS,
            payload: []
        });
    }, []);
    const { t } = useTranslation();
    const navigation = (path: string) => {
        if (path === '/task-view') {
            if (checkRole(rolesObject.admin)) {
                history.push(path);
            } else {
                handleToast({ message: 'Permission denied' }, 'error');
            }
        } else {
            history.push(path);
        }
    };
    return (
        <>
            <Row>
                <Col>
                    <div className="bg-white px-3 px-lg-4 py-3 mb-4">
                        <h2 className="text-center text-lg-left">{t('landing.title')}</h2>
                        <p className="text-center text-lg-left">{t('landing.page-description')}</p>
                    </div>
                </Col>
            </Row>
            <Row className="px-0 px-sm-4">
                <Col className="col-12 col-sm-12 col-md-6 col-xl-3">
                    <Card className="bg-primary order-card text-body">
                        <Card.Body className="min-h-170 p-4 bg-primary">
                            <Row>
                                <Col>
                                    <h3 className="text-white font-weight-bold">{t('landing.administrator-title')}</h3>
                                    <p className="font-weight-light text-white text-3 p-0">{t('landing.administrator-description')}</p>
                                </Col>
                                <Col className="col-auto d-none d-sm-block">
                                    <i className="fas fa-user-tie fa-4x text-white card-icon" />
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer>
                            <button
                                className="btn btn-outline-light border-0 rounded-pill"
                                disabled={!checkRole(rolesObject.admin)}
                                onClick={() => navigation('/task-view')}
                            >
                                {t('landing.getstarted-button')}
                                <i className="fa fa-chevron-right ml-2" />
                            </button>
                        </Card.Footer>
                    </Card>
                </Col>
                <Col className="col-12 col-sm-12 col-md-6 col-xl-3">
                    <Card className="bg-info order-card text-body">
                        <Card.Body className="min-h-170 p-4 bg-info">
                            <Row>
                                <Col>
                                    <h3 className="text-white font-weight-bold">{t('landing.contributor-title')}</h3>
                                    <p className="font-weight-light text-white text-3 p-0">{t('landing.contributor-description')}</p>
                                </Col>
                                <Col className="col-auto d-none d-sm-block">
                                    <i className="fas fa-address-card fa-4x text-white card-icon" />
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer>
                            <button
                                className="btn btn-outline-light border-0 rounded-pill"
                                disabled={!checkRole(rolesObject.contributor)}
                                onClick={() => navigation('/task-board')}
                            >
                                {t('landing.getstarted-button')}
                                <i className="fa fa-chevron-right ml-2" />
                            </button>
                        </Card.Footer>
                    </Card>
                </Col>
                <Col className="col-12 col-sm-12 col-md-6 col-xl-3">
                    <Card className="bg-warning order-card text-body">
                        <Card.Body className="min-h-170 p-4 bg-warning">
                            <Row>
                                <Col>
                                    <h3 className="text-white font-weight-bold">{t('landing.datascientist-title')}</h3>
                                    <p className="font-weight-light text-white text-3 p-0">{t('landing.datascientist-description')}</p>
                                </Col>
                                <Col className="col-auto d-none d-sm-block">
                                    <i className="fas fa-flask fa-4x text-white card-icon" />
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer>
                            <button
                                className="btn btn-outline-light border-0 rounded-pill"
                                disabled={!checkRole(rolesObject.dataScientist)}
                                onClick={() => navigation('/export')}
                            >
                                {t('landing.getstarted-button')}
                                <i className="fa fa-chevron-right ml-2" />
                            </button>
                        </Card.Footer>
                    </Card>
                </Col>
                <Col className="col-12 col-sm-12 col-md-6 col-xl-3">
                    <Card className="bg-success order-card text-body">
                        <Card.Body className="min-h-170 p-4 bg-success">
                            <Row>
                                <Col>
                                    <h3 className="text-white font-weight-bold">{t('landing.reviewer-title')}</h3>
                                    <p className="font-weight-light text-white text-3 p-0">{t('landing.reviewer-description')}</p>
                                </Col>
                                <Col className="col-auto d-none d-sm-block">
                                    <i className="fas fa-user-magnifying-glass fa-4x text-white card-icon" />
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer>
                            <button
                                className="btn btn-outline-light border-0 rounded-pill"
                                disabled={!checkRole(rolesObject.reviewer)}
                                onClick={() => navigation('/task-board')}
                            >
                                {t('landing.getstarted-button')}
                                <i className="fa fa-chevron-right ml-2" />
                            </button>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
        </>
    );
}
export default Dashboard;

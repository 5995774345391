import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function NewModal(props: any) {
    const { t } = useTranslation();
    return (
        <Modal centered show={props?.showModal}>
            <Modal.Header>
                <Modal.Title as="h5">{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{props.children}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onCancel}>
                    {t('workspace.close')}
                </Button>
                <Button className="bg-primary" variant="primary" onClick={props.onConfirm} disabled={props.confirmDisabled}>
                    {props.confirmText || t('workspace.confirm')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
export default NewModal;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import logoDark from '../assets/images/logo-dark.png';
import '../assets/scss/style.scss';
import { resetPasswordWithResetToken } from '../store/actions/index';
import { handleToast } from '../utils/handler';

interface loginForm {
    password: string;
    confirmPassword: string;
}
export function ResetPassword() {
    const [userDetails, setUserDetails] = useState<loginForm>({
        password: '',
        confirmPassword: ''
    });
    const { resetToken } = useParams<{ resetToken?: string }>();
    const history = useHistory();
    const { t } = useTranslation();
    useEffect(() => {
        if (!resetToken) {
            history.push('/login');
        }
    }, [resetToken]);
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name } = e.target;
        const { value } = e.target;
        setUserDetails({
            ...userDetails,
            [name]: value
        });
    };
    const resetPassword = async (e: React.FormEvent) => {
        e.preventDefault();
        const { password = '', confirmPassword = '' } = userDetails;
        const result = await resetPasswordWithResetToken({ resetToken, password, confirmPassword });
        if (result) {
            handleToast({ message: 'Password reset successfully' }, 'success');
            history.push('/login');
        }
    };
    return (
        <div className="auth-wrapper">
            <div className="auth-content">
                <div className="card">
                    <div className="row align-items-center text-center">
                        <div className="col-md-12">
                            <div className="card-body">
                                <form noValidate onSubmit={resetPassword}>
                                    <img src={logoDark} alt="" className="img-fluid mb-4" />
                                    <h4 className="mb-3 f-w-400">{t('reset-password.title')}</h4>
                                    <div className="form-group fill">
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="password"
                                            placeholder={t('reset-password.password-placeholder') as string}
                                            name="password"
                                            value={userDetails.password}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="form-group fill mb-4">
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="confirmPassword"
                                            placeholder={t('reset-password.password-confirm-placeholder') as string}
                                            name="confirmPassword"
                                            value={userDetails.confirmPassword}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <button className="btn btn-block btn-primary mt-4" onSubmit={resetPassword}>
                                        {t('reset-password.submit')}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ResetPassword;

/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Card, Col, Form, InputGroup, Row, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import NewModal from '../components/Modal';
import { ReactPagination } from '../components/common';
import {
    createDataset,
    getDatasets,
    loadDataset,
    saveDataset,
    saveSentence
} from '../store/actions/sentence.action';
import { UPDATE_BREADCRUMBS } from '../store/actions/types';
import { RootState } from '../store/reducer';
import { SentenceStatusType, exportToTsv, importSentenceDatasetFromCsv } from '../utils/csvUtils';
import extractNames from '../utils/extractNames';
import { handleToast } from '../utils/handler';

declare type FormControlElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
export interface ISentence {
    id: number;
    source: string;
    destination: string;
    datasetId?: number;
    status: SentenceStatusType;
}
export interface IDataset {
    id: number;
    languageId: number;
    title: string;
    description: string;
    sentences: ISentence[];
    active?: boolean;
}
export interface ILanguage {
    id: number;
    language: string;
}
const DEFAULT_DATASET: IDataset = {
    id: 0,
    languageId: 0,
    title: 'No Dataset Loaded',
    description: 'Import, create, or open a dataset',
    sentences: []
};
const ITEMS_IN_PAGE = 10;
const alignedSentenceStatus = ["Incomplete", "Completed", "Rejected"];
function Default() {
    const { t } = useTranslation();
    const { languageList } = useSelector((state: RootState) => state.mainReducer);
    const [dataset, setDataset] = useState<IDataset>({
        ...DEFAULT_DATASET,
        title: t('aligned-sentences.no-dataset-loaded'),
        description: t('aligned-sentences.page-description')
    });
    const [tempDataset, setTempDataset] = useState<IDataset>({
        ...DEFAULT_DATASET,
        title: t('aligned-sentences.no-dataset-loaded'),
        description: t('aligned-sentences.page-description')
    });
    const [isDatasetSave, setDatasetSave] = useState(false);
    const [isDatasetExport, setDatasetExport] = useState(false);
    const [isDatasetOpen, setDatasetOpen] = useState(false);
    const [datasetList, setDatasetList] = useState([] as IDataset[]);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [datasetPageNumber, setDatasetPageNumber] = useState<number>(1);
    const [datasetSearchFilter, setDatasetSearchFilter] = useState<number>(0);
    const [titleKeyword, setTitleKeyword] = useState<string>('');
    const [descriptionKeyword, setDescriptionKeyword] = useState<string>('');
    const [showSelectLanguageModal, setSelectLanguageModal] = useState<boolean>(false);
    const [importLanguage, setImportLanguage] = useState<number>(0);
    const fileRef = useRef<HTMLInputElement>(null);
    const topRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const [datasetExportList, setDatasetExportList] = useState<IDataset[]>([]);
    useEffect(() => {
        dispatch({
            type: UPDATE_BREADCRUMBS,
            payload: []
        });
    }, []);
    useEffect(() => {
        if (isDatasetOpen || isDatasetExport) {
            getDatasets().then((datasets) => {
                if (datasets) { setDatasetList(datasets); }
            });
        }
    }, [isDatasetOpen]);
    const selectLanguage = (e: React.ChangeEvent<FormControlElement>) => {
        setTempDataset({ ...tempDataset, languageId: Number(e.target.value) });
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSentenceChange = (value: any, sentence: ISentence, field: string) => {
        let newSentence: ISentence = sentence;
        switch (field) {
            case 'source':
                newSentence = {
                    ...sentence,
                    ...{ source: value }
                };
                break;
            case 'destination':
                newSentence = {
                    ...sentence,
                    ...{ destination: value }
                };
                break;
            case 'status':
                newSentence = { ...sentence, status: value };
                break;
            default:
                newSentence = sentence;
                break;
        }
        const newSentences = dataset.sentences.map((item) => {
            if (item.id === sentence.id) return newSentence;
            return item;
        });
        setDataset({ ...dataset, sentences: newSentences });
    };
    const onSentenceSave = (sentence: ISentence) => {
        sentence.status = sentence.status || alignedSentenceStatus[0];
        saveSentence(sentence, dataset.id).then((res) => {
            if (res) { handleToast({ message: t('aligned-sentences.sentence-saved') }, 'success'); }
        });
    };
    const onDatasetFieldChange = (e: React.ChangeEvent<FormControlElement>, field: string) => {
        setTempDataset({ ...tempDataset, [field]: e.target.value });
    };
    const onDatasetCancel = () => {
        setDatasetExport(false);
        setDatasetSave(false);
        setDatasetOpen(false);
    };
    const onDatasetSave = () => {
        const result = isDatasetSave ? saveDataset({ ...tempDataset, sentences: dataset.sentences }) : createDataset(tempDataset);
        result.then((res) => {
            setDatasetSave(false);
            const savedDataset = { ...tempDataset };
            if (res.id) savedDataset.id = res.id;
            if (isDatasetSave) {
                savedDataset.sentences = dataset.sentences;
            }
            setDataset(savedDataset);
            handleToast({ message: t('aligned-sentences.dataset-saved-message') }, 'success');
        });
    };
    const filterItem = (item: IDataset) => {
        const lowerTitle = item.title.toLocaleLowerCase();
        const lowerDescription = item.description.toLocaleLowerCase();
        const lowerTitleKeyword = titleKeyword.toLocaleLowerCase();
        const lowerDescriptionKeyword = descriptionKeyword.toLocaleLowerCase();
        const title = lowerTitleKeyword ? lowerTitle.includes(lowerTitleKeyword) : true;
        const description = lowerDescriptionKeyword ? lowerDescription.includes(lowerDescriptionKeyword) : true;
        const language = datasetSearchFilter <= 0 ? true : item.languageId === datasetSearchFilter;
        return title && description && language;
    };
    const onDatasetSelect = () => {
        const selectedDataset = datasetList.find((item) => item.active);
        if (!selectedDataset || !filterItem(selectedDataset)) {
            handleToast({ message: t('aligned-sentences.choose-dataset-warning') }, 'warn');
            return;
        }
        loadDataset(selectedDataset).then((res) => {
            if (res) {
                setDataset(res);
                setPageNumber(1);
                handleToast({ message: t('aligned-sentences.dataset-load-message') }, 'success');
                setDatasetOpen(false);
            }
        });
    };
    const onDatasetSaveClick = () => {
        setDatasetSave(true);
        setTempDataset({ ...dataset, sentences: [] });
    };
    const onDatasetOpenClick = () => {
        setDatasetPageNumber(1);
        setDatasetSearchFilter(-1);
        setDatasetOpen(true);
    };
    const onDatasetImportClick = () => {
        setImportLanguage(0);
        setSelectLanguageModal(true);
    };
    const onDatasetExportClick = () => {
        setDatasetExportList([]);
        setDatasetExport(true);
    };
    const onDatasetCandidateClick = (candidateDataset: IDataset) => {
        const newDatasetList = datasetList.map((item) => {
            if (item.id === candidateDataset.id) return { ...item, active: true };
            return { ...item, active: false };
        });
        setDatasetList(newDatasetList);
    };
    const onFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files?.length) return;
        for (let i = 0; i < e.target.files?.length; i += 1) {
            const inputFile = e.target.files[i];
            const reader = new FileReader();
            reader.onload = async ({ target }) => {
                const datasetName = extractNames(inputFile.name);
                const datasetFromFile = importSentenceDatasetFromCsv(String(target?.result), extractNames(inputFile.name), importLanguage);
                setDataset(datasetFromFile);
                const shouldUpdate = datasetList?.find((datasetObj) => datasetObj.title === datasetName);
                const result = shouldUpdate
                    ? saveDataset({ ...datasetFromFile, sentences: datasetFromFile.sentences })
                    : createDataset(datasetFromFile);
                result.then((res) => {
                    setDatasetSave(false);
                    const savedDataset = { ...datasetFromFile };
                    if (res.id) savedDataset.id = res.id;
                    if (isDatasetSave) {
                        savedDataset.sentences = dataset.sentences;
                    }
                    setDataset(savedDataset);
                    setPageNumber(1);
                    handleToast({ message: t('aligned-sentences.dataset-saved-message') }, 'success');
                });
            };
            reader.readAsText(inputFile);
        }
    };
    const handleNextPage = (pgNumber: number) => {
        setPageNumber(pgNumber);
        if (topRef.current) {
            topRef.current.scrollIntoView();
        }
    };
    const handleNextPageDatasetList = (pgNumber: number) => {
        setDatasetPageNumber(pgNumber);
    };
    const getLanguage = (id: number) => {
        const result = languageList.find((language: ILanguage) => language.id === id);
        return result ? result.language : '';
    };
    const handleOkay = () => {
        fileRef?.current?.click();
        setSelectLanguageModal(false);
    };
    const onClickExportListItem = (item: IDataset, shouldAddToList: boolean) => {
        if (shouldAddToList) {
            setDatasetExportList([...datasetExportList, item]);
        } else {
            setDatasetExportList(datasetExportList.filter((it: IDataset) => it.id !== item.id));
        }
    };
    const ExportData = async () => {
        // Export to CSV file
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-restricted-syntax
        for await (const datasetObj of datasetExportList) {
            const data = await loadDataset(datasetObj);
            const rows: Array<Array<string>> = [];
            const language = languageList.find((item: { id: number }) => item.id === datasetObj.languageId);
            rows.push(['Source', 'Destination']);
            rows.push(...data.sentences.map((item: ISentence) => [item.source, item.destination]));
            exportToTsv(`${datasetObj.title} (${language.language}).tsv`, rows);
        }
    };
    return (
        <>
            {isDatasetOpen && (
                <Card className="shadow-none">
                    <Card.Header>
                        <span className="card-title">{t('aligned-sentences.card-title')}</span>
                    </Card.Header>
                    <Card.Body className="px-4 pt-3">
                        <Table responsive hover bordered className="text-center">
                            <thead>
                                <tr>
                                    <th className="py-2 w-10">{t('aligned-sentences.index')}</th>
                                    <th className="py-2">{t('aligned-sentences.title')}</th>
                                    <th className="py-2">{t('aligned-sentences.description')}</th>
                                    <th className="py-2">{t('aligned-sentences.language')}</th>
                                </tr>
                                <tr>
                                    <th aria-label="table-head"
                                        className="py-2 bg-white" />
                                    <th aria-label="table-head" className="py-2 bg-white">
                                        <Form.Control
                                            type="text"
                                            placeholder={t('aligned-sentences.title-keyword')}
                                            onChange={(e) => setTitleKeyword(e.target.value)}
                                            value={titleKeyword}
                                        />
                                    </th>
                                    <th aria-label="table-head" className="py-2 bg-white">
                                        <Form.Control
                                            type="text"
                                            placeholder={t('aligned-sentences.description-keyword')}
                                            onChange={(e) => setDescriptionKeyword(e.target.value)}
                                            value={descriptionKeyword}
                                        />
                                    </th>
                                    <th className="py-2 bg-white">
                                        <InputGroup>
                                            <select
                                                className="form-control form-control-sm"
                                                value={datasetSearchFilter}
                                                name="languageId"
                                                onChange={(e) => {
                                                    setDatasetSearchFilter(Number(e.target.value));
                                                    setDatasetPageNumber(1);
                                                }}
                                            >
                                                <option selected key={-1} value={-1} disabled>
                                                    {t('aligned-sentences.select-language')}
                                                </option>
                                                <option selected key={0} value={0}>
                                                    {t('aligned-sentences.select-all')}
                                                </option>
                                                {languageList.map((language: { language: string; id: number }) => (
                                                    <option key={language.id} value={language.id}>
                                                        {language.language}
                                                    </option>
                                                ))}
                                            </select>
                                        </InputGroup>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {datasetList.filter((item) => filterItem(item)).map((item, index) => {
                                    if (index >= ITEMS_IN_PAGE * (datasetPageNumber - 1) && index < ITEMS_IN_PAGE * datasetPageNumber) {
                                        return (
                                            <tr
                                                className={`${item.active ? 'bg-secondary text-white' : ''}`}
                                                key={item.id}
                                                onClick={() => onDatasetCandidateClick(item)}
                                            >
                                                <td className="py-1">{index + 1}</td>
                                                <td className="py-1 font-weight-bold">{item.title}</td>
                                                <td className="py-1">{item.description}</td>
                                                <td className="py-1">{getLanguage(item.languageId)}</td>
                                            </tr>
                                        );
                                    }
                                    return <span />;
                                })}
                            </tbody>
                        </Table>
                    </Card.Body>
                    <Card.Footer>
                        <Row>
                            <Col className="d-flex justify-content-center">
                                <ReactPagination
                                    pagination={{
                                        totalPages: Math.ceil(datasetList.filter((item) => filterItem(item)).length / 10)
                                    }}
                                    onPageChange={handleNextPageDatasetList}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-right">
                                <button type="button" className="btn btn-sm btn-success mr-2" onClick={onDatasetSelect}>
                                    <i className="fa fa-check mr-2" />
                                    {t('aligned-sentences.select')}
                                </button>
                                <button type="button" className="btn btn-sm btn-danger" onClick={onDatasetCancel}>
                                    <i className="fa fa-times mr-2" />
                                    {t('aligned-sentences.cancel')}
                                </button>
                            </Col>
                        </Row>
                    </Card.Footer>
                </Card>
            )}
            {isDatasetSave && (
                <Card className="shadow-none">
                    <Card.Header>
                        <span className="card-title">{isDatasetSave && (t('aligned-sentences.save-dataset'))}</span>
                    </Card.Header>
                    <Card.Body className="px-4 pt-3">
                        <Row>
                            <Col className="col-12 col-sm-12 col-md-6 col-lg-6">
                                <Form.Group controlId="datasetTitle">
                                    <Form.Label>{t('aligned-sentences.title')}</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        type="text"
                                        placeholder={t('aligned-sentences.title-placeholder')}
                                        name="datasetTitle"
                                        value={tempDataset.title}
                                        onChange={(e) => onDatasetFieldChange(e, 'title')}
                                    />
                                </Form.Group>
                            </Col>
                            <Col className="col-12 col-sm-12 col-md-4 col-lg-4">
                                <Form.Group controlId="datasetLanguage">
                                    <Form.Label>{t('aligned-sentences.role')}</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        as="select"
                                        placeholder={t('aligned-sentences.description-placeholder')}
                                        name="datasetLanguage"
                                        value={tempDataset.languageId}
                                        onChange={(e) => selectLanguage(e)}
                                    >
                                        <option selected key={0} value={0} disabled>
                                            {t('aligned-sentences.select-language')}
                                        </option>
                                        {languageList.map((language: { language: string; id: number }) => (
                                            <option key={language.id} value={language.id}>
                                                {language.language}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <Form.Group controlId="datasetDescription">
                                    <Form.Label>{t('aligned-sentences.description')}</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        type="text"
                                        placeholder={t('aligned-sentences.description-placeholder')}
                                        name="datasetDescription"
                                        value={tempDataset.description}
                                        onChange={(e) => onDatasetFieldChange(e, 'description')}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Card.Body>
                    <Card.Footer>
                        <Row>
                            <Col className="text-right">
                                <button type="button" className="btn btn-sm btn-success mr-2" onClick={onDatasetSave}>
                                    <i className="fa fa-save mr-2" />
                                    {t('aligned-sentences.save')}
                                </button>
                                <button type="button" className="btn btn-sm btn-danger" onClick={onDatasetCancel}>
                                    <i className="fa fa-times mr-2" />
                                    {t('aligned-sentences.cancel')}
                                </button>
                            </Col>
                        </Row>
                    </Card.Footer>
                </Card>
            )}
            {isDatasetExport && (
                <Card className="shadow-none">
                    <Card.Header>
                        <span className="card-title">{t('aligned-sentences.card-title')}</span>
                    </Card.Header>
                    <Card.Body className="px-4 pt-3">
                        <Table responsive hover bordered className="text-center">
                            <thead>
                                <tr>
                                    <th className="py-2 w-10">{t('aligned-sentences.index')}</th>
                                    <th className="py-2">{t('aligned-sentences.title')}</th>
                                    <th className="py-2">{t('aligned-sentences.description')}</th>
                                    <th className="py-2">{t('aligned-sentences.language')}</th>
                                </tr>
                                <tr>
                                    <th aria-label='table-head' className="py-2 bg-white" />
                                    <th aria-label='table-head' className="py-2 bg-white">
                                        <Form.Control
                                            type="text"
                                            placeholder={t('aligned-sentences.title-keyword')}
                                            onChange={(e) => setTitleKeyword(e.target.value)}
                                            value={titleKeyword}
                                        />
                                    </th>
                                    <th aria-label='table-head' className="py-2 bg-white">
                                        <Form.Control
                                            type="text"
                                            placeholder={t('aligned-sentences.description-keyword')}
                                            onChange={(e) => setDescriptionKeyword(e.target.value)}
                                            value={descriptionKeyword}
                                        />
                                    </th>
                                    <th className="py-2 bg-white">
                                        <InputGroup>
                                            <select
                                                className="form-control form-control-sm"
                                                value={datasetSearchFilter}
                                                name="languageId"
                                                onChange={(e) => {
                                                    setDatasetSearchFilter(Number(e.target.value));
                                                    setDatasetPageNumber(1);
                                                }}
                                            >
                                                <option selected key={-1} value={-1} disabled>
                                                    {t('aligned-sentences.select-language')}
                                                </option>
                                                <option selected key={0} value={0}>
                                                    {t('aligned-sentences.select-all')}
                                                </option>
                                                {languageList.map((language: { language: string; id: number }) => (
                                                    <option key={language.id} value={language.id}>
                                                        {language.language}
                                                    </option>
                                                ))}
                                            </select>
                                        </InputGroup>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {datasetList
                                    .filter((item) => filterItem(item))
                                    .map((item, index) => {
                                        if (
                                            index >= ITEMS_IN_PAGE * (datasetPageNumber - 1) &&
                                            index < ITEMS_IN_PAGE * datasetPageNumber
                                        ) {
                                            const isItemSelected =
                                                datasetExportList.findIndex((it: IDataset) => it.id === item.id) >= 0;
                                            return (
                                                <tr
                                                    className={`${isItemSelected ? 'bg-secondary text-white' : ''}`}
                                                    key={item.id}
                                                    onClick={() => onClickExportListItem(item, !isItemSelected)}
                                                >
                                                    <td className="py-1">{index + 1}</td>
                                                    <td className="py-1 font-weight-bold">{item.title}</td>
                                                    <td className="py-1">{item.description}</td>
                                                    <td className="py-1">{getLanguage(item.languageId)}</td>
                                                </tr>
                                            );
                                        }
                                        return <span />;
                                    })}
                            </tbody>
                        </Table>
                    </Card.Body>
                    <Card.Footer>
                        <Row>
                            <Col className="d-flex justify-content-center">
                                <ReactPagination
                                    pagination={{
                                        totalPages: Math.ceil(datasetList.filter((item) => filterItem(item)).length / 10)
                                    }}
                                    onPageChange={handleNextPageDatasetList}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-right">
                                <button
                                    type='button'
                                    className="btn btn-sm btn-success mr-2"
                                    onClick={ExportData}
                                    disabled={datasetExportList.length === 0}
                                >
                                    <i className="fa fa-check mr-2" />
                                    {t('aligned-sentences.export')}
                                </button>
                                <button type='button'
                                    className="btn btn-sm btn-danger" onClick={onDatasetCancel}>
                                    <i className="fa fa-times mr-2" />
                                    {t('aligned-sentences.cancel')}
                                </button>
                            </Col>
                        </Row>
                    </Card.Footer>
                </Card>
            )}
            {!isDatasetOpen && !isDatasetSave && !isDatasetExport && (
                <>
                    <Row className="search-header d-flex justify-content-center justify-content-md-start" ref={topRef}>
                        <Col className="col-auto mb-2 mb-sm-2 mb-md-0 mb-lg-0 mb-xl-0">
                            <Card className="m-0">
                                <Card.Body className="px-1">
                                    <div className="btn-group btn-group-sm">
                                        <input
                                            ref={fileRef}
                                            type="file"
                                            onChange={onFileSelect}
                                            accept=".csv"
                                            className="d-none mx-1"
                                            multiple
                                        />
                                        <button
                                            type='button'
                                            className="btn btn-outline-primary border-0 rounded-pill d-none d-sm-block mx-1"
                                            onClick={onDatasetImportClick}
                                        >
                                            <i className="fa fa-file-import mr-2" />
                                            {t('aligned-sentences.import')}
                                        </button>
                                        <button type='button' aria-label='file-export'
                                            className="btn btn-outline-primary border-0 rounded-pill d-block d-sm-none btn-block mx-1"
                                            onClick={onDatasetImportClick}
                                        >
                                            <i className="fa fa-file-import" />
                                        </button>
                                        <button type='button'
                                            className="btn btn-outline-primary border-0 rounded-pill d-none d-sm-block mx-1"
                                            onClick={onDatasetOpenClick}
                                        >
                                            <i className="fa fa-folder-open mr-2" />
                                            {t('aligned-sentences.open')}
                                        </button>
                                        <button type='button'
                                            aria-label='folder-open'
                                            className="btn btn-outline-primary border-0 rounded-pill d-block d-sm-none btn-block mx-1"
                                            onClick={onDatasetOpenClick}
                                        >
                                            <i className="fa fa-folder-open" />
                                        </button>
                                        <button type='button'
                                            className="btn btn-outline-primary border-0 rounded-pill d-none d-sm-block mx-1"
                                            onClick={onDatasetSaveClick}
                                        >
                                            <i className="fas fa-save mr-2" />
                                            {t('aligned-sentences.save')}
                                        </button>
                                        <button type='button'
                                            aria-label='save'
                                            className="btn btn-outline-primary border-0 rounded-pill d-block d-sm-none btn-block mx-1"
                                            onClick={onDatasetSaveClick}
                                        >
                                            <i className="fa fa-save" />
                                        </button>
                                        <button type='button'
                                            className="btn btn-outline-primary border-0 rounded-pill d-none d-sm-block mx-1"
                                            onClick={onDatasetExportClick}
                                        >
                                            <i className="fa fa-file-export mr-2" />
                                            {t('aligned-sentences.export')}
                                        </button>
                                        <button type='button'
                                            aria-label='file-export'
                                            className="btn btn-outline-primary border-0 rounded-pill d-block d-sm-none btn-block mx-1"
                                            onClick={onDatasetExportClick}
                                        >
                                            <i className="fa fa-file-export" />
                                        </button>
                                    </div >
                                </Card.Body >
                            </Card >
                        </Col >
                    </Row >
                    <Card className="shadow-none">
                        <Card.Header className="">
                            <Row>
                                <Col>
                                    <h3 className="mb-0">{dataset.title}</h3>
                                    <small className="">{dataset.description}</small>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <Row>
                                    <Col>
                                        {dataset.sentences
                                            .map((sentence, index) => {
                                                if (index >= ITEMS_IN_PAGE * (pageNumber - 1) && index < ITEMS_IN_PAGE * pageNumber) {
                                                    return (
                                                        <table
                                                            key={sentence.id}
                                                            className="table table-sm table-hover table-borderless text-4"
                                                        >
                                                            <thead>
                                                                <tr>
                                                                    <th colSpan={2} className="bg-primary py-0 px-0 text-right">
                                                                        <div className="px-4 d-flex justify-content-between align-items-center gap-5">
                                                                            <div className="d-flex gap-5">
                                                                                <select
                                                                                    value={sentence.status}
                                                                                    onChange={(e) => {
                                                                                        onSentenceChange(
                                                                                            e.target.value,
                                                                                            sentence, "status"
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    {alignedSentenceStatus.map((status) => <option value={status}>{status}</option>)}
                                                                                </select>
                                                                            </div>
                                                                            <div className="btn-group btn- group-sm">
                                                                                {!!dataset.id && (
                                                                                    <button
                                                                                        className="btn btn-primary"
                                                                                        onClick={() => onSentenceSave(sentence)}
                                                                                    >
                                                                                        {t('aligned-sentences.save')}
                                                                                    </button>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td className="py-1 px-2 text-right table-info font-weight-bold w-10">
                                                                        {t('aligned-sentences.source')}
                                                                    </td>
                                                                    <td
                                                                        aria-label='source-text' className="py-1 px-2">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control form-control-sm"
                                                                            placeholder={
                                                                                t('aligned-sentences.source-text-placeholder') as string
                                                                            }
                                                                            onChange={(e) =>
                                                                                onSentenceChange(e.target.value, sentence, 'source')
                                                                            }
                                                                            value={sentence.source}
                                                                            readOnly
                                                                        />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="py-1 px-2 text-right table-primary font-weight-bold">
                                                                        {t('aligned-sentences.destination')}
                                                                    </td>
                                                                    <td aria-label="destination" className="py-1 px-2">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control form-control-sm"
                                                                            placeholder={
                                                                                t('aligned-sentences.destination-text-placeholder') as string
                                                                            }
                                                                            onChange={(e) =>
                                                                                onSentenceChange(e.target.value, sentence, 'destination')
                                                                            }
                                                                            value={sentence.destination}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    );
                                                }
                                                return <span />;
                                            })}
                                    </Col>
                                </Row>
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer>
                            <Row>
                                <Col className="d-flex justify-content-center">
                                    <ReactPagination
                                        pagination={{
                                            totalPages: Math.ceil(dataset.sentences.length / 10)
                                        }}
                                        onPageChange={handleNextPage}
                                    />
                                </Col>
                            </Row>
                        </Card.Footer>
                    </Card>
                </>
            )
            }
            <NewModal
                title={t('aligned-sentences.modal-title') as string}
                showModal={showSelectLanguageModal}
                confirmText={t('aligned-sentences.import') as string}
                confirmDisabled={importLanguage === 0}
                onConfirm={handleOkay}
                onCancel={() => {
                    setSelectLanguageModal(false);
                }}
            >
                <Row className="px-2">{t('aligned-sentences.select-language-message')}</Row>
                <Row className="search-header">
                    <Col className="col-auto mb-2 mb-sm-2 mb-md-0 mb-lg-0 mb-xl-0">
                        <InputGroup>
                            <select
                                className="form-control form-control-sm"
                                value={importLanguage}
                                name="languageId"
                                onChange={(e) => {
                                    setImportLanguage(Number(e.target.value));
                                }}
                            >
                                <option selected key={0} value={0} disabled>
                                    {t('aligned-sentences.select-language')}
                                </option>
                                {languageList.map((language: { language: string; id: number }) => (
                                    <option key={language.id} value={language.id}>
                                        {language.language}
                                    </option>
                                ))}
                            </select>
                        </InputGroup>
                    </Col>
                </Row>
            </NewModal>
        </>
    );
}
export default Default;

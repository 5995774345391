/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
import { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Router } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
// import '../../node_modules/font-awesome/scss/font-awesome.scss';
import store from 'store/store';
import { LoadingPage } from '../components/common';
import routesOnePage, { RouteObject } from '../route';
import routes from '../routes';
import { getMyProfile, getTrelloTicketFilters } from '../store/actions/index';
import { TASK_FILTER } from '../store/actions/types';
import { RootState } from '../store/reducer';
import { Logout, history, isUserLoggedIn } from '../utils/handler';
import { UserDetail } from '../views/viewTypes/user.types';
import { HistoryModal } from '../views/workspace-wizard/modals/historyModal';
import AdminLayout from './layout/AdminLayout';
import Loader from './layout/Loader';
import ScrollToTop from './layout/ScrollToTop';

function App() {
    const { filter } = useSelector((state: RootState) => state.taskReducer);
    const { showLoader } = useSelector((state: RootState) => state.mainReducer);
    const dispatch = useDispatch();
    useEffect(() => {
        if (isUserLoggedIn()) {
            getTrelloTicketFilters(filter);
        }
    }, [filter]);
    useEffect(() => {
        history.listen((location: any) => {
            // eslint-disable-next-line no-shadow
            const { filter } = store.getState().taskReducer;
            const params = new URLSearchParams(location.search);
            if (location.pathname === '/workspace') {
                const bookListId = Number(params.get('bookListId')) || filter.bookListId;
                const chapterId = Number(params.get('chapterId')) || filter.chapterId;
                const verseId = Number(params.get('verseId')) || filter.verseId;
                if (bookListId && chapterId && verseId) {
                    const payload = {
                        bookListId,
                        chapterId,
                        verseId
                    };
                    dispatch({ type: TASK_FILTER, payload: { ...payload, isWizard: true } });
                } else {
                    dispatch({ type: TASK_FILTER, payload: { ...filter, isWizard: true } });
                }
            } else {
                dispatch({ type: TASK_FILTER, payload: { ...filter, isWizard: false } });
            }
        });
    }, []);
    useEffect(() => {
        const { href } = window.location;
        const tmpSplit = href.split('/');
        if (isUserLoggedIn()) {
            getMyProfile().then((profile: UserDetail) => {
                if (!profile) {
                    Logout();
                }
            });
        } else {
            if (tmpSplit.includes('forgot-password') || tmpSplit.includes('reset-password')) return;
            Logout();
        }
    }, []);
    return (
        <>
            {showLoader ? <LoadingPage /> : null}
            <HistoryModal />
            <ScrollToTop>
                <Suspense fallback={<Loader />}>
                    <Router history={history}>
                        <Route path={routesOnePage.map((x: RouteObject) => x.path)}>
                            {routesOnePage.map((route) => route.component ? (
                                <Route
                                    key={Math.random()}
                                    path={route.path}
                                    exact={route.exact}
                                    render={(props: any) => <route.component {...props} />}
                                />
                            ) : null)}
                        </Route>
                        <Route exact path={routes.map((x) => x.path)}>
                            <AdminLayout />
                        </Route>
                    </Router>
                </Suspense>
            </ScrollToTop>
            <div className="backdrop" />
        </>
    );
}
export default App;

import { RouteObject } from './route';
import { loadValues } from './utils/handler';
import Dashboard from './views/dashboard';
import DefinitionManagement from './views/definition-management';
import ProfileSettings from './views/profile';
import UserManagement from './views/user-project-management/index';

import AlignedSentences from './views/aligned-sentences';
import ExportComponent from './views/export';
import TaskView from './views/task';
import Trello from './views/trello';
import { WorkspaceLayout } from './views/workspace-wizard/index';

loadValues();

export const routes: RouteObject[] = [
    { path: '/', exact: true, name: 'Analytics', component: Dashboard },
    { path: '/profile', exact: true, name: 'Profile Page', component: ProfileSettings },
    { path: '/definition-management', exact: true, name: 'Definition', component: DefinitionManagement, isAdminOrDS: true },
    { path: '/aligned-sentences', exact: true, name: 'Aligned Sentences', component: AlignedSentences },
    { path: '/export', exact: true, name: 'Exports', component: ExportComponent },

    { path: '/workspace', exact: true, name: 'Workspace Wizard', component: WorkspaceLayout, checkProjectAssigned: true },

    { path: '/task-board', exact: true, name: 'Tasks Board', component: Trello, checkProjectAssigned: true },
];

routes.push({ path: '/task-view', exact: true, name: 'Task Page', component: TaskView, isAdminOrDS: true });
routes.push({ path: '/user-project-management', exact: true, name: 'User & Project Management', component: UserManagement, isAdminOrDS: true, isReviewer: false });

export default routes;

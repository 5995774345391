import Axios from 'axios';
import { baseUrl, requestHeader } from '../../config';
import { filterComponent, getCurrentProject } from '../../utils/handler';
import { UpdateBulkVerseType, VerseActionType } from '../../views/viewTypes/verse.type';
import { UpdateTaskAssignedToType, UpdateTaskByReviewerType } from '../../views/viewTypes/words.type';
import store from '../store';
import { CatchReturnError } from './actionHandler';
import { VERSE_LIST } from './types';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getVerseList = (filtersArgs?: any) => {
    const filters = {
        ...{
            assignedTo: 0,
            bookListId: 13,
            statusId: 0,
            chapterId: 0,
            verseId: 0,
            search: '',
            filterBy: '',
            filterType: '',
            orderBy: 'createdAt',
            orderType: 'ASC',
            limit: 10,
            page: 1
        },
        ...filtersArgs
    };
    let URL =
        `${baseUrl
        }/getVerse?search=${filters.search}
  &assignedTo=${filters.assignedTo}
  &bookListId=${Number(filters.bookListId)}
  &chapterId=${Number(filters.chapterId)}
  &verseId=${filters.verseId}
  &statusId=${filters.statusId}
  &projectId=${getCurrentProject(filterComponent.taskManagement).id || 1}
  &orderBy=${filters.orderBy}&orderType=${filters.orderType}`;
    // if (globalTaskUrl !== URL) {
    //   getTaskPagination(URL)
    // }
    URL += `&limit=${filters.limit}&page=${filters.page}`;
    return Axios.get(URL, requestHeader)
        .then((res) => {
            const response = res.data;
            store.dispatch({ type: VERSE_LIST, payload: response });
            return response;
        })
        .catch((err) => {
            CatchReturnError(err);
        });
};
export const getVerseDetailById = (verseId: string, projectId: number) => Axios.get(`${baseUrl}/getVerseDetails/${verseId}/${projectId}`, requestHeader)
    .then((response) => response.data)
    .catch((err) => {
        CatchReturnError(err);
    });
export const updateVerseBulkAction = (payload: UpdateBulkVerseType) => Axios.patch(`${baseUrl}/updateVerseBulkAction`, payload, requestHeader)
    .then((res) => res.data)
    .catch((err) => {
        CatchReturnError(err);
    });
export const updateVerseAction = (payload: VerseActionType) => {
    payload.projectId = getCurrentProject().id;
    return Axios.patch(`${baseUrl}/updateVerseAction`, payload, requestHeader)
        .then((res) => res.data)
        .catch((err) => {
            CatchReturnError(err);
        });
};
export const updateTaskAssignedTo = (body: UpdateTaskAssignedToType) => Axios.post(`${baseUrl}/updateTaskAssignedTo`, body, requestHeader)
    .then((res) => res.data)
    .catch((err) => {
        CatchReturnError(err);
    });
export const updateTaskByReviewer = (body: UpdateTaskByReviewerType) => Axios.post(`${baseUrl}/updateTaskByReviewer`, body, requestHeader)
    .then((res) => res.data)
    .catch((err) => {
        CatchReturnError(err);
    });

/* eslint-disable no-undef */
import { TargetLanguageCell } from 'components/common';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SortableElement } from 'react-sortable-hoc';
import successMessages from 'utils/successMessages';
import {
    createContainer, deleteTask,
    mergeWordsIntoContainer, splitContainer
} from '../../store/actions/index';
import { CREATE_NEW_CONTAINER, SELECTED_VERSE, SHOW_LOADING_WINDOW } from '../../store/actions/types';
import { createContainerWord } from '../../store/actions/word.action';
import { RootState } from '../../store/reducer';
import { getTargetLanguageVersionId, handleToast, scrollDoc } from '../../utils/handler';
import { Task, TranslatedContainer, wordType } from '../viewTypes/task.types';
import {
    checkIsEmptyHasWords,
    containerActions,
    getTaskWordIdFromDragEvent,
    onDragEnd,
    onDragEnter,
    onDragOver,
    onDragStart,
    removeDropAreaOverlay,
    replaceBookDetail,
    saveDestinationWord,
    sourceContainerUpdate,
    updateSourceContainerDetail,
} from './handler';

const SortableItem = SortableElement(({ containerInfo, index }: { containerInfo: Task, index: number }) =>
    <td className="px-3 py-2 text-center" key={index} id={`TC${containerInfo?.id?.toString()}`}>
        {containerInfo?.words?.map((word: wordType) => (
            <span key={word.id} className="ml-1">{word?.word}</span>
        ))}
    </td>
);
function DestinationRow({ modifyMode }: { modifyMode: number }) {
    const [newContainerContent, setNewContainerContent] = useState<string>('');
    const [editingContainer, setEditingContainer] = useState(-1);
    const { selectedVerse, selectedVerseIndex,
        translatedContainers, translatedVerse, isCreatingContainer } = useSelector(
            (state: RootState) => state.workspaceReducer
        );
    const [currentWordIndex, setCurrentWordIndex] = useState(-1);
    const [currentWord, setCurrentWord] = useState<wordType | undefined>();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const deleteSourceContainer = (taskDetail: Task, isSource = false) => {
        if (
            taskDetail.id &&
            (!taskDetail?.words?.length ||
                // eslint-disable-next-line no-alert
                window.confirm(t(successMessages.deleteContainerConfirmation)))
        ) {
            deleteTask(taskDetail.id, false, false).then((result) => {
                if (result) {
                    sourceContainerUpdate(
                        { cIndex: 0, vIndex: selectedVerseIndex, containerDetail: taskDetail, isSource },
                        containerActions.delete
                    );
                }
            });
        }
    };
    const splitContainers = (containerDetail: Task, containerIndex: number) => {
        if (containerDetail.id) {
            splitContainer(containerDetail.id).then((result) => {
                if (result) {
                    const containers = selectedVerse.translatedContainers;
                    containers.forEach((container: Task, index: number) => {
                        if (index >= containerIndex) {
                            container.wordLocation = container.wordLocation + result.length + 1;
                        }
                    });
                    const newSelectedVerse = selectedVerse;
                    containers.splice(containerIndex, 1);
                    newSelectedVerse.translatedContainers = _.sortBy([...containers, ...result], 'wordLocation');
                    replaceBookDetail({ cIndex: 0, vIndex: selectedVerseIndex, data: newSelectedVerse });
                    dispatch({ type: SELECTED_VERSE, payload: newSelectedVerse });
                }
            });
        }
    };
    useEffect(() => {
        if (isCreatingContainer) {
            setNewContainerContent('');
            scrollDoc(`target-${0}`);
        }
    }, [isCreatingContainer]);
    const onDrop = (event: React.DragEvent<HTMLElement>) => {
        event.preventDefault();
        removeDropAreaOverlay();
        const targetWord = getTaskWordIdFromDragEvent(event);
        const sourceWord = JSON.parse(event.dataTransfer.getData('text'));
        const sourceContainerIndex = _.findIndex(selectedVerse.translatedContainers, { id: sourceWord.taskId });
        const targetContainerIndex = _.findIndex(selectedVerse.translatedContainers, { id: targetWord.taskId });
        if (
            targetWord.isSource !== sourceWord.isSource ||
            selectedVerseIndex !== sourceWord.vIndex ||
            targetWord.wordId === sourceWord.id ||
            sourceWord.taskId === targetWord.taskId
        ) { return; }
        if (targetWord.taskId) {
            mergeWordsIntoContainer({
                tasksId: targetWord.taskId,
                wordId: sourceWord.id, versesId: translatedVerse.id
            }).then((result) => {
                if (result) {
                    const targetTask = selectedVerse.translatedContainers[targetContainerIndex];
                    targetTask.words = targetTask.words || [];
                    if (!_.find(targetTask.words, { id: sourceWord.id })) {
                        sourceWord.tasksId = targetWord.taskId;
                        targetTask.words.push(sourceWord);
                        updateSourceContainerDetail(
                            { containerDetail: targetTask, cIndex: 0, vIndex: selectedVerseIndex, tIndex: targetContainerIndex },
                            false
                        );
                    }
                    const sourceTask = selectedVerse.translatedContainers[sourceContainerIndex];
                    if (sourceContainerIndex >= 0 && sourceTask) {
                        sourceTask.words = _.filter(sourceTask.words, (obj) => obj?.id !== sourceWord.id);
                        updateSourceContainerDetail(
                            { containerDetail: sourceTask, cIndex: 0, vIndex: selectedVerseIndex, tIndex: sourceContainerIndex },
                            false
                        );
                    }
                    handleToast({ message: t('workspace.success-message') }, 'success');
                }
            });
        } else {
            const payload = {
                word: sourceWord.word,
                wordId: sourceWord.id,
                versesId: translatedVerse.id,
                languageVersionId: translatedVerse.languageVersionId
            };
            createContainer(payload).then((result) => {
                const sourceTask = translatedContainers[sourceContainerIndex];
                sourceTask.words = _.filter(sourceTask.words, (obj) => obj?.id !== sourceWord.id);
                updateSourceContainerDetail(
                    {
                        containerDetail: result,
                        cIndex: 0,
                        vIndex: selectedVerseIndex,
                        containerAction: containerActions.addNewContainer
                    },
                    false
                );
                dispatch({ type: CREATE_NEW_CONTAINER, payload: false });
            });
        }
    };
    const saveWord = (containerInfo: TranslatedContainer, index: number) => {
        if (newContainerContent && containerInfo?.id) {
            if (!currentWord?.id) {
                dispatch({ type: SHOW_LOADING_WINDOW, payload: true });
                createContainerWord({ tasksId: containerInfo.id, word: newContainerContent }).then((wordDetail) => {
                    if (wordDetail) {
                        updateSourceContainerDetail(
                            {
                                containerDetail: containerInfo,
                                wordDetail,
                                cIndex: 0,
                                vIndex: selectedVerseIndex
                            },
                            false
                        );
                    }
                    dispatch({ type: SHOW_LOADING_WINDOW, payload: false });
                });
            } else {
                saveDestinationWord({
                    cIndex: 0,
                    vIndex: selectedVerseIndex,
                    tcIndex: index,
                    versesId: translatedVerse.id,
                    containerId: containerInfo.id,
                    word: newContainerContent,
                    currentWord
                });
                if (containerInfo.words[currentWordIndex]) containerInfo.words[currentWordIndex].word = newContainerContent;
                updateSourceContainerDetail(
                    {
                        containerDetail: containerInfo,
                        cIndex: 0,
                        vIndex: selectedVerseIndex
                    },
                    false
                );
            }
        } else if (newContainerContent) {
            createContainer({
                word: newContainerContent,
                versesId: translatedVerse.id,
                languageVersionId: translatedVerse.languageVersionId
            }).then((result: TranslatedContainer) => {
                if (result) {
                    updateSourceContainerDetail(
                        {
                            containerDetail: result,
                            cIndex: 0,
                            vIndex: selectedVerseIndex,
                            containerAction: containerActions.addNewContainer
                        },
                        false
                    );
                }
                dispatch({ type: CREATE_NEW_CONTAINER, payload: false });
            });
        }
        setEditingContainer(-1);
    };
    const checkHasMultipleWord = (container: Task) => {
        if (container?.word) return container?.words && (container?.words?.length > 1 || container?.word.split(' ').length > 1);
        return false;
    };
    return (
        <tr>
            <TargetLanguageCell />
            {_.sortBy(translatedContainers, 'wordLocation').map((containerInfo: TranslatedContainer, index: number) =>
                modifyMode === 1 ? (
                    <SortableItem key={`item-${`${containerInfo.word}${containerInfo.id}`}`} index={index} containerInfo={containerInfo} />
                ) : (
                    <td
                        id={`target-${containerInfo?.id?.toString()}`}
                        data-verseId={selectedVerse.id}
                        data-vIndex={selectedVerseIndex}
                        data-taskId={containerInfo.id}
                        data-source={false}
                        data-languageVersionId={getTargetLanguageVersionId()}
                        className="px-3 py-2 text-center "
                        key={containerInfo.id}
                        data-tIndex={index}
                        onDrop={(event) => {
                            if (modifyMode === 2) onDrop(event);
                        }}
                        onDragOver={onDragOver}
                        onDragEnter={onDragEnter}
                        onDragEnd={onDragEnd}
                        onClick={() => {
                            if (modifyMode === 2 && checkIsEmptyHasWords(containerInfo)) {
                                setCurrentWord(undefined);
                                setEditingContainer(containerInfo.id);
                                setNewContainerContent('');
                                setCurrentWordIndex(0);
                            }
                        }}
                    >
                        {modifyMode === 2 && (containerInfo.id === editingContainer || containerInfo.id === 0) ? (
                            <input
                                id={`target${containerInfo.id}`}
                                type="text"
                                className="border-0"
                                value={newContainerContent}
                                onChange={(e) => {
                                    setNewContainerContent(e.target.value);
                                }}
                                onBlur={() => newContainerContent && saveWord(containerInfo, index)}
                                onKeyDown={async (e) => {
                                    if (newContainerContent && (e.key === 'Enter' || e.keyCode === 27)) {
                                        saveWord(containerInfo, index);
                                    } else if (e.key === 'Escape') {
                                        setEditingContainer(-1);
                                    }
                                }}
                            />
                        ) : (
                            containerInfo.words?.map((word: wordType, wordIndex: number) => (
                                <div
                                    role="presentation"
                                    className="d-inline ml-2"
                                    key={word.id}
                                    onClick={() => {
                                        if (modifyMode === 2) {
                                            setCurrentWord(word);
                                            setEditingContainer(containerInfo.id);
                                            setNewContainerContent(word.word || '');
                                            setCurrentWordIndex(wordIndex);
                                        }
                                    }}
                                >
                                    <span
                                        key={word.id}
                                        data-verseId={selectedVerse.id}
                                        data-vIndex={selectedVerseIndex}
                                        data-taskId={containerInfo.id}
                                        data-wordId={word.id}
                                        data-languageVersionId={getTargetLanguageVersionId()}
                                        draggable={selectedVerse.verseAccess}
                                        data-source={false}
                                        onDragStart={(event) => {
                                            if (modifyMode === 2) {
                                                onDragStart({
                                                    event,
                                                    cIndex: 0,
                                                    vIndex: selectedVerseIndex,
                                                    word,
                                                    isSource: false,
                                                    tIndex: index,
                                                    taskId: containerInfo.id
                                                });
                                            }
                                        }}
                                    >
                                        {word.word}
                                    </span>
                                </div>
                            ))
                        )}
                        {selectedVerse.verseAccess && modifyMode === 3 ? (
                            <span>
                                <span className="ml-2">
                                    <i
                                        onClick={() => deleteSourceContainer(containerInfo)}
                                        className="fas fa-trash text-danger c-pointer"
                                        data-toggle="tooltip"
                                        data-placement="bottom"
                                        title={t('workspace.delete-container') as string}
                                    />
                                </span>
                                {checkHasMultipleWord(containerInfo) ? (
                                    <span className="ml-2">
                                        <i
                                            role="presentation"
                                            onClick={() => splitContainers(containerInfo, index)}
                                            className="fas fa-hand-scissors text-primary c-pointer"
                                            data-toggle="tooltip"
                                            data-placement="bottom"
                                            title={t('workspace.Split Container') || 'workspace.Split Container'}
                                        />
                                    </span>
                                ) : null}
                            </span>
                        ) : null}
                    </td>
                )
            )}
        </tr>
    );
}
export default DestinationRow;

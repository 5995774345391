const errorMessages = {
    bookNotFound: "Book Not Found",
    selectOtherLang: 'Please select another language.',
    userNotFound: 'User not found',
    passwordInvalidContent: 'Password must have a minimum length of 8 characters containing at least one number, an uppercase, and a special character',
    passwordOldPasswordIsSame: 'Old password and new password cannot be the same',
    mailInvalidContent: 'Invalid email format',
    confirmPasswordMsg: 'Passwords must match',
    passwordIsMissing: 'Password field is missing',
    confirmPasswordIsMissing: 'Confirm Password field is missing',
    emailAlreadyExist: 'This account is already registered. Please login',
    authorizationMissing: 'Authorization is missing',
    authFailed: 'Authorization failed',
    notAuthorizedMsg: 'You are not authorized to access this',
    userAlreadyExist: 'User already exists',
    userAlreadyExistLogin: 'User already exists, please login',
    fieldIsEmpty: 'Field is empty',
    requiredFieldIsMissing: 'Required field is missing',
    permissionDenied: 'Permission denied',
    fieldsMissingMessage: 'Fields are missing',
    payloadEmpty: 'Payload is empty',
    errorParsingData: 'Error in parsing request',
    imageOnlyError: 'Please upload only .jpg, .jpeg, .png type files',
    requestBodyIsInvalid: 'The request body is invalid',
    unexpectedPayloadInput: 'Unexpected input field',
    somethingWentWrong: 'Something went wrong',
    tokenExpired: 'Session has expired. Please login again',
    inputValueIsTooLong: 'Input value is too long',
    emptyEmail: 'Email field should not be empty',
    duplicateEmail: 'Email is assigned to another user',
    dataNotFound: 'Data not found',
    notEnoughWordsToSplit: 'Minimum two words are required in the container',
    dataAlreadyExist: 'Data already exists',
    invalidEmail: 'Email is not valid. Please enter a valid email address',
    updateFailed: 'Update failed',
    missingMandatoryField: 'Field should not be empty',
    noProjectAssigned: "We couldn't find any projects assigned to you. Please contact your lead or admin.",
    userExistInThisGroup: "User already exists in this group.",
    projectNotFound: 'Project not found',
    languageVersionExist: 'Language version already exists',
    freeEditError: 'Error in saving free edit',
    languageNotFound: 'Language Not Found',
    meaningNotUpdated: 'Meaning not updated properly',
    loginFailed: "Login Failed",
    accountLocked: "Looks like you have made 6 unsuccessful attempts. Please login after 24 hours.",
    invalidUserCredentials: "Invalid user credentials",
    selectRule: "Please select at least one rule.",
    noReplacementRule: "Please type the replacement word.",
    noData: "No records found",
    noDataChooseDifferentFilter: "No records were found. Please choose a different filter.",
    selectTranslation: "Please select the translation verse.",
    emptyTranslation: "Translation should not be empty",
    alreadyEmptyContainer: "Empty container already exists",
    pageNotFound: "Page not found",
    selectTargetLanguage: "Target Language should be selected",
    selectSourceLanguage: "Source Language should be selected",
    linkHasExpired: "This link has expired, get a new link and try again"
};
export default errorMessages;

/* eslint-disable no-undef */
import _ from 'lodash';
import React from 'react';
import { updateSourceWord } from '../../store/actions/task.action';
import { BOOK_DETAILS, SELECTED_VERSE, TASK_FILTER } from '../../store/actions/types';
import store from '../../store/store';
import { checkIsDataScientist, getStatusPercentage, handleToast } from '../../utils/handler';
import { WordLocation } from "../viewTypes/definition-management.type";
import {
    Chapter,
    IBookDetails,
    SelectObj,
    Task,
    TaskActions,
    TranslatedContainer,
    TranslatedWordsNew,
    Verse,
    WordMappings,
    grammars,
    wordType,
} from '../viewTypes/task.types';
import { SourceCreate } from "../viewTypes/words.type";
/* eslint-disable react-hooks/exhaustive-deps */
export const checkUnMappableCount = (tasks: Task[]) => tasks.length > 30;
export const getVerseStatusObject = (
    verseAction?: TaskActions,
    verse?: Verse,
) => getStatusPercentage(verseAction?.statusId
    || (checkUnMappableCount(verse?.tasks || []) ? -1 : 1));
export const checkVerseIsUnMappable = (
    verseAction?: TaskActions,
    verse?: Verse,
) => getVerseStatusObject(verseAction, verse).status
=== (-1 || 10) && !checkIsDataScientist();
export const workspaceTypes = {
    input: 'input',
    word: 'word',
    grammar: 'grammar',
    action: 'action',
};
export const containerActions = {
    addNewContainer: 'addNewContainer',
    create: 'Create',
    delete: 'Delete',
    container: 'container',
    destination: 'destination',
};
export const removeMapping = (
    mappings: WordMappings[],
    removingId: number = 1,
    type: string = containerActions.container,
): WordMappings[] => {
    let mappingsList: WordMappings[] = mappings;
    if (type === containerActions.container) {
        mappingsList = _.filter(mappings, (mapping) => removingId !== mapping.sourceContainerId) || [];
    }
    if (type === containerActions.destination) {
        mappingsList = _.filter(mappings, (mapping) => removingId
            !== mapping.translatedContainerId) || [];
    }
    return mappingsList;
};
export const updateSourceContainerDetail = (sourceContainer: {
    containerDetail: TranslatedContainer,
    wordDetail?: wordType,
    containerId?: number,
    wIndex?: number,
    wordId?: number,
    cIndex: number,
    vIndex: number,
    tIndex?: number
    containerAction?: string,
}, isSource = true) => {
    const { bookDetails } = store.getState().workspaceReducer;
    const {
        containerDetail,
        wordDetail,
        cIndex,
        vIndex,
        containerAction = containerActions.create,
    } = sourceContainer;
    let {
        containerId = null,
        wIndex = -1,
        wordId = null,
    } = sourceContainer;
    if (cIndex > -1 && vIndex > -1) {
        try {
            containerId = containerId || containerDetail.id;
            if (wordDetail) wordId = wordId || wordDetail.id;
            const bookDetailsTemp: IBookDetails = bookDetails;
            const verse = bookDetailsTemp.chapters[cIndex].verses[vIndex];
            let sourceContainers = bookDetailsTemp.chapters[cIndex].verses[vIndex].tasks;
            if (!isSource) {
                sourceContainers = bookDetailsTemp.chapters[cIndex].verses[vIndex].translatedContainers;
            }
            const scIndex = _.findIndex(sourceContainers, { id: containerId });
            if (wordId && wordId > 0 && scIndex >= 0 && sourceContainers.length) {
                wIndex = _.findIndex(sourceContainers[scIndex].words, { id: wordId });
            }
            if (containerAction === containerActions.delete) {
                if (wIndex >= 0) {
                    sourceContainers[scIndex]?.words?.splice(wIndex, 1);
                } else if (scIndex >= 0) {
                    const mappings = verse.wordMappings;
                    verse.wordMappings = removeMapping(mappings, containerId, containerActions.destination);
                    sourceContainers.splice(scIndex, 1);
                }
            } else if (!_.isEmpty(wordDetail)) {
                if (sourceContainers.length > 0 && sourceContainers[scIndex]) {
                    const wordsList = sourceContainers[scIndex].words || [];
                    if (wIndex >= 0) {
                        wordsList[wIndex] = wordDetail;
                    } else {
                        wordsList.push(wordDetail);
                    }
                    sourceContainers[scIndex].words = wordsList;
                }
            } else if (!_.isEmpty(containerDetail)) {
                if (containerAction === containerActions.addNewContainer) {
                    sourceContainers[sourceContainers.length - 1] = containerDetail;
                } else if (scIndex >= 0) {
                    sourceContainers[scIndex] = { ...sourceContainers[scIndex], ...containerDetail };
                } else {
                    sourceContainers.push(containerDetail);
                }
            }
            if (sourceContainers[scIndex]
                && sourceContainers[scIndex].words) {
                sourceContainers[scIndex].word = sourceContainers[scIndex]
                    .words?.map((word) => word.word).join(' ');
            }
            store.dispatch({ type: BOOK_DETAILS, payload: bookDetailsTemp });
        } catch (error) {
            handleToast({ message: 'Something went wrong' });
        }
    }
};
export const toggleVerseAction = (payload: { verseId: number, vIndex?: number, key: string, cIndex: number }) => {
    const { bookDetails } = store.getState().workspaceReducer;
    const { key = '', cIndex } = payload;
    let { vIndex } = payload;
    if (cIndex > -1 && vIndex && vIndex > -1) {
        try {
            const bookDetailsTemp: IBookDetails = bookDetails;
            if (payload.verseId) vIndex = _.findIndex(bookDetailsTemp.chapters[cIndex].verses, { id: payload.verseId });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const verseDetails: any = bookDetailsTemp.chapters[cIndex].verses[vIndex];
            if (key) {
                verseDetails[key] = !verseDetails[key];
            }
            bookDetailsTemp.chapters[cIndex].verses[vIndex] = verseDetails;
            store.dispatch({ type: BOOK_DETAILS, payload: bookDetailsTemp });
        } catch (error) {
            handleToast({ message: 'Something went wrong' });
        }
    }
};
export const sourceContainerUpdate = (
    containerPayload: {
        containerDetail: Task | { id: number; languageVersionId: number };
        cIndex: number;
        vIndex: number;
        isSource: boolean;
    },
    containerAction = containerActions.create,
) => {
    const { bookDetails } = store.getState().workspaceReducer;
    const { containerDetail = { id: 0, languageVersionId: 0 }, cIndex, vIndex } = containerPayload;
    if (cIndex > -1 && vIndex > -1) {
        try {
            const bookDetailsTemp: IBookDetails = bookDetails;
            const verse = bookDetailsTemp.chapters[cIndex].verses[vIndex];
            const translatedVerse = bookDetailsTemp.chapters[cIndex].verses[vIndex]?.translatedVerses[0];
            let { tasks } = bookDetailsTemp.chapters[cIndex].verses[vIndex];
            if (!containerPayload.isSource) {
                tasks = bookDetailsTemp.chapters[cIndex].verses[vIndex].translatedContainers;
            }
            if (containerAction === containerActions.create) {
                containerDetail.languageVersionId = verse.languageVersionId;
                tasks[tasks.length - 1] = containerDetail;
            } else {
                const tcIndex = _.findIndex(tasks, { id: containerDetail.id });
                const mappings = verse.wordMappings;
                verse.wordMappings = removeMapping(mappings, containerDetail.id);
                if (tcIndex >= 0) tasks.splice(tcIndex, 1);
            }
            if (tasks) {
                if (containerPayload.isSource) verse.verse = tasks.map((task) => task.word).join(' ');
                if (!containerPayload.isSource
                    && translatedVerse) translatedVerse.verse = tasks.map((task) => task.word).join(' ');
            }
            store.dispatch({ type: BOOK_DETAILS, payload: bookDetailsTemp });
        } catch (error) {
            handleToast({ message: 'Something went wrong' });
        }
    }
};
export const containerUpdate = (containerPayload: {
    containerDetail: TranslatedContainer,
    wordDetail?: wordType,
    containerId?: number,
    wIndex?: number,
    wordId?: number,
    cIndex: number,
    vIndex: number,
    containerAction?: string,
}, containerAction = containerActions.create) => {
    const { bookDetails } = store.getState().workspaceReducer;
    const { containerDetail, cIndex, vIndex } = containerPayload;
    if (cIndex > -1 && vIndex > -1 && containerDetail) {
        try {
            const bookDetailsTemp: IBookDetails = bookDetails;
            const verse = bookDetailsTemp.chapters[cIndex].verses[vIndex];
            const { translatedContainers } = bookDetailsTemp.chapters[cIndex].verses[vIndex];
            const tcIndex = _.findIndex(verse.translatedContainers, { id: containerDetail.id });
            if (containerAction === containerActions.delete) {
                if (tcIndex >= 0) {
                    const mappings = verse.wordMappings;
                    verse.wordMappings = removeMapping(mappings, containerDetail.id, containerActions.destination);
                    // need to delete multiple word in translation
                    // remove translated container if it's empty
                    verse.translatedContainers.splice(tcIndex, 1);
                }
            } else if (tcIndex >= 0) {
                translatedContainers[tcIndex] = containerDetail;
            } else {
                translatedContainers[translatedContainers.length - 1] = containerDetail;
            }
            store.dispatch({ type: BOOK_DETAILS, payload: bookDetailsTemp });
        } catch (error) {
            handleToast({ message: 'Something went wrong' });
        }
    }
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toggleTaskAction = (payload: any) => {
    const { bookDetails } = store.getState().workspaceReducer;
    const {
        newTaskInfo = {}, key = '', cIndex = '', vIndex = '', tIndex = '', wIndex = '', wordDetails = {},
    } = payload;
    if (cIndex > -1 && vIndex > -1) {
        try {
            const bookDetailsTemp: IBookDetails = bookDetails;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let taskDetails: any = bookDetailsTemp.chapters[cIndex].verses[vIndex].tasks[tIndex];
            if (key) {
                taskDetails[key as keyof Task] = !taskDetails[key];
            }
            if (newTaskInfo) {
                taskDetails = { ...taskDetails, ...newTaskInfo };
            }
            if (wIndex >= 0 && !_.isEmpty(wordDetails)) {
                taskDetails.words = taskDetails.words || [];
                taskDetails.words[wIndex] = wordDetails;
            }
            bookDetailsTemp.chapters[cIndex].verses[vIndex].tasks[tIndex] = taskDetails;
            store.dispatch({ type: BOOK_DETAILS, payload: bookDetailsTemp });
        } catch (error) {
            console.log(error);
            handleToast({ message: 'Something went wrong' });
        }
    }
};
export const handleTaskView = (key: string, newTaskInfo?: Task) => {
    const { currentContainerForModel } = store.getState().workspaceReducer;
    const { cIndex, vIndex, tIndex } = currentContainerForModel;
    toggleTaskAction({
        newTaskInfo, key, cIndex, vIndex, tIndex,
    });
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const replaceBookDetail = (payload: { cIndex?: number, vIndex?: number, tIndex?: number, wIndex?: number, data: any }) => {
    const { bookDetails } = store.getState().workspaceReducer;
    let {
        cIndex = -1, vIndex = -1, tIndex = -1
    } = payload;
    const { data } = payload;
    tIndex = Number(tIndex);
    vIndex = Number(vIndex);
    cIndex = Number(cIndex);
    if (cIndex > -1 && vIndex > -1) {
        try {
            const bookDetailsTemp: IBookDetails = bookDetails;
            if (tIndex >= 0) {
                bookDetailsTemp.chapters[cIndex].verses[vIndex].tasks[tIndex] = data;
            } else if (vIndex >= 0) {
                bookDetailsTemp.chapters[cIndex].verses[vIndex] = data;
            } else if (cIndex >= 0) {
                bookDetailsTemp.chapters[cIndex] = data;
            }
            store.dispatch({ type: BOOK_DETAILS, payload: bookDetailsTemp });
        } catch (error) {
            handleToast({ message: 'Something went wrong' });
        }
    }
};
export const getMultiSelectValues = (data: (grammars | SelectObj)[]) => data.map((obj: SelectObj) => (obj.label ? { value: obj.value, label: obj.label } : { value: obj.id, label: obj.topic || obj.grammar }));
export const appendChapterInBook = (newChapters: Chapter[]) => {
    const { bookDetails } = store.getState().workspaceReducer;
    if (newChapters) {
        const chapters = [...bookDetails.chapters, ...newChapters];
        bookDetails.chapters = chapters;
    }
    return bookDetails;
};
export const getGrammar = (data: Task | wordType | TranslatedWordsNew | TranslatedContainer) => {
    const { grammarList } = store.getState().workspaceReducer;
    let grammarObj = { grammar: '' };
    if (data?.grammarId) {
        grammarObj = _.find(grammarList, { id: data.grammarId });
    }
    return grammarObj;
};
export const saveDestinationWord = async (payload: {
    currentWord: wordType | undefined;
    versesId: number;
    containerId?: number;
    word: string;
    cIndex: number;
    vIndex: number;
    tcIndex: number;
}) => {
    const { word = '', currentWord } = payload;
    const payloadObj: SourceCreate = {
        word: word || '',
        versesId: payload.versesId,
        grammarId: currentWord?.grammarId,
        meaningId: currentWord?.meaningId,
    };
    let currentWordId = 0;
    if (currentWord) {
        if (Number(currentWord.id)) {
            currentWordId = Number(currentWord.id);
        }
    }
    if (payloadObj.word !== '' && payloadObj.word !== currentWord?.word) {
        updateSourceWord(currentWordId, payloadObj).then((result) => {
            if (result) {
                if (currentWord) {
                    result.meanings = currentWord.meanings;
                    result.topics = currentWord.topics;
                }
                handleToast({ message: 'Successfully Updated' }, 'success');
            }
        });
    }
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let draggingWordDetail: any = {};
// drag and drop
export const onDragStart = (payload: {
    event: React.DragEvent<HTMLElement>;
    word: wordType;
    isSource: boolean;
    cIndex: number;
    vIndex: number;
    tIndex: number;
    taskId?: number;
    dragWord?: string;
}) => {
    const {
        event, word, isSource, cIndex, vIndex, tIndex, taskId, dragWord,
    } = payload;
    event.stopPropagation();
    event.dataTransfer.effectAllowed = 'move';
    draggingWordDetail = {
        ...word, isSource, cIndex, vIndex, tIndex, taskId, dragWord,
    };
    event.dataTransfer.setData('text', JSON.stringify(draggingWordDetail));
};
export const onDragStartLocation = (payload: {
    event: React.DragEvent<HTMLElement>; eventPayload: {
        wordLocations: WordLocation[],
        sourceTreeWord: string,
        sourceUniqueMeaningId: number,
        sourceTasksId: number[]
        sourceWord: string
    }
}) => {
    const { event, eventPayload } = payload;
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.setData('definition', JSON.stringify(eventPayload));
};
export const removeDropAreaOverlay = () => {
    document.querySelectorAll('.drop-area-over').forEach((dom) => {
        const element = dom as HTMLElement;
        element.classList.remove('drop-area-over');
        element.classList.remove('no-drop');
    });
};
export const getTaskWordIdFromDragEvent = (event: React.DragEvent<HTMLElement>) => {
    let target = event.target as HTMLElement;
    const parentTarget = event.currentTarget as HTMLElement;
    let wordId = 0;
    let taskId = 0;
    let tcID = 0;
    let isSource = false;
    let verseId = 0;
    let vIndex;
    let languageVersionId;
    let tIndex;
    while (target !== parentTarget) {
        wordId = wordId || Number(target.getAttribute('data-wordId')?.toString());
        taskId = taskId || Number(target.getAttribute('data-taskId')?.toString());
        verseId = verseId || Number(target.getAttribute('data-verseId')?.toString());
        vIndex = vIndex || Number(target.getAttribute('data-vIndex')?.toString());
        tIndex = tIndex || Number(target.getAttribute('data-tIndex')?.toString());
        tcID = tcID || Number(target.getAttribute('data-tcID')?.toString());
        languageVersionId = languageVersionId || Number(target.getAttribute('data-languageVersionId')?.toString());
        isSource = isSource || target.getAttribute('data-source')?.toString() === 'true';
        target = target.parentElement!;
    }
    while (target) {
        wordId = wordId || Number(target.getAttribute('data-wordId')?.toString());
        taskId = taskId || Number(target.getAttribute('data-taskId')?.toString());
        verseId = verseId || Number(target.getAttribute('data-verseId')?.toString());
        vIndex = vIndex || Number(target.getAttribute('data-vIndex')?.toString());
        tIndex = tIndex || Number(target.getAttribute('data-tIndex')?.toString());
        tcID = tcID || Number(target.getAttribute('data-tcID')?.toString());
        languageVersionId = languageVersionId || Number(target.getAttribute('data-languageVersionId')?.toString());
        isSource = isSource || target.getAttribute('data-source')?.toString() === 'true';
        if (!target.childElementCount) break;
        target = target.children.item(0) as HTMLElement;
    }
    return {
        wordId,
        taskId,
        isSource,
        verseId,
        tcID,
        vIndex: vIndex || 0,
        tIndex: tIndex || 0,
    };
};
export const showDragAnimations = (event: React.DragEvent<HTMLElement>) => {
    const dragOverColumnDetail = getTaskWordIdFromDragEvent(event);
    let sameVerseAndDifferentTask = false;
    if (
        dragOverColumnDetail.isSource === draggingWordDetail.isSource
        && draggingWordDetail.vIndex === dragOverColumnDetail.vIndex
        && (draggingWordDetail.tIndex !== dragOverColumnDetail.tIndex || draggingWordDetail.tcID !== dragOverColumnDetail.tcID)
    ) sameVerseAndDifferentTask = true;
    return sameVerseAndDifferentTask;
};
export const checkIsEmptyHasWords = (container: Task) => !container.words || container.words.length === 0;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const onDragEnter = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    removeDropAreaOverlay();
    const target = event.currentTarget as HTMLElement;
    if (showDragAnimations(event)) target.classList.add('drop-area-over');
};
export const onDragLeave = (event: React.DragEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    const target = event.currentTarget as HTMLElement;
    const relatedTarget = event.relatedTarget as HTMLElement;
    if (relatedTarget && relatedTarget.parentElement && !target.contains(relatedTarget)) target.classList.remove('drop-area-over');
};
export const onDragOver = (event: React.DragEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    event.dataTransfer.dropEffect = 'move';
};
export const onDragEnd = (event: React.DragEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    removeDropAreaOverlay();
    const target = event.currentTarget as HTMLElement;
    target.style.opacity = '1';
    const childDom = target.childNodes.item(0) as HTMLElement;
    childDom.style.opacity = '1';
};
export const nextVerse = (verseId: number = 0) => {
    const { filter, versesList } = store.getState().taskReducer;
    const { selectedVerse } = store.getState().workspaceReducer;
    let nextVerseId = versesList[0]?.id;
    const selectedVerseIndex = _.findIndex(versesList, { id: selectedVerse.id });
    if (verseId) nextVerseId = verseId;
    else if (versesList[selectedVerseIndex + 1]) nextVerseId = versesList[selectedVerseIndex + 1].id;
    store.dispatch({ type: TASK_FILTER, payload: { ...filter, verseId: nextVerseId } });
};
export const getVersesFromBookDetails = (bookDetails: IBookDetails) => (bookDetails?.chapters && bookDetails?.chapters[0]?.verses) || [];
export const loadSelectedVerse = (verseId: number = 0) => {
    const { filter } = store.getState().taskReducer;
    const { bookDetails } = store.getState().workspaceReducer;
    const verses: Verse[] = getVersesFromBookDetails(bookDetails);
    const versesId = verseId || filter.verseId;
    const nextVerseObject = _.find(verses, { id: versesId });
    store.dispatch({ type: SELECTED_VERSE, payload: nextVerseObject });
};

import { encryptData } from '../../utils/handler';
import * as types from '../actions/types';
import { actionTypes } from './reducerTypes';
let userInitialState = {
    myProfile: {},
    userList: [],
    userGroups: [],
    userRoles: [],
    isAccountLocked: false
};

export const userReducer = (state = userInitialState, action: actionTypes) => {
    switch (action.type) {
        case types.ACCOUNT_LOCKED:
            return {
                ...state,
                isAccountLocked: action.payload
            };
        case types.USER_ROLES:
            return {
                ...state,
                userRoles: action.payload
            };
        case types.GET_MY_PROFILE:
            // -- should update latest profile in user list
            localStorage.setItem('myProfile', encryptData(action.payload));
            return {
                ...state,
                myProfile: action.payload
            };
        case types.USER_LIST:
            return {
                ...state,
                userList: action.payload
            };
        case types.USER_GROUPS:
            return {
                ...state,
                userGroups: action.payload
            };

        case types.UPDATE_MY_PROFILE:
            return {
                ...state,
                myProfile: action.payload
            };
        default:
            return state;
    }
};

export default userReducer;

import { useState } from 'react';
import Avatar from 'react-avatar';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CURRENT_LOCAL_LANGUAGE } from 'store/actions/types';
import { RootState } from '../../../../../store/reducer';
import { Logout, localStorageKey, setLocalStorageItem } from '../../../../../utils/handler';

interface NavRightProps {
    rtlLayout: boolean;
}
function NavRight(props: NavRightProps) {
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const { myProfile } = useSelector((state: RootState) => state.userReducer);
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
    const dispatch = useDispatch();
    const changeLanguage = (value: string) => {
        dispatch({ type: CURRENT_LOCAL_LANGUAGE, payload: value });
        setLocalStorageItem(localStorageKey.localLanguage, value);
        i18n.changeLanguage(value);
        setSelectedLanguage(value);
    };
    return (
        <>
            <div className='navbar-nav '>
                <i className="fas fa-globe p-2" />
                <select
                    className="form-control form-control-sm"
                    disabled={false}
                    value={selectedLanguage}
                    onChange={(e) => {
                        changeLanguage(e.target.value);
                    }}
                >
                    <option selected key={"en"} value={"en"}>
                        {"English"}
                    </option>
                    <option selected key={"id"} value={"id"} >
                        {"Indonesian"}
                    </option>
                </select>
            </div>
            <ul className="navbar-nav ">
                <li>
                    <Dropdown drop={!props.rtlLayout ? 'left' : 'right'} className="dropdown" alignRight={!props.rtlLayout}>
                        <Dropdown.Toggle variant={'link'} id="dropdown-basic">
                            <i className="icon feather icon-user" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu alignRight className="profile-notification">
                            <div className="pro-head bg-dark ">
                                <div className="row center-aligned mb-3">
                                    <Avatar
                                        className="mr-2 avatar-profile"
                                        size={'50'}
                                        textSizeRatio={3}
                                        round
                                        src={'getUserImageURL(myProfile.image)'}
                                        name={myProfile.name}
                                    />
                                </div>
                                <div className="row center-aligned">
                                    <div className="col-12 center-aligned">
                                        <div className="text-4 font-weight-bold">{myProfile.name}</div>
                                    </div>
                                    <div className="col-12 center-aligned">
                                        <div className="text-2 font-weight-light text-uppercase">{t(`roles.${myProfile?.role?.roleType}`)}</div>
                                    </div>
                                </div>
                            </div>
                            <ul className="pro-body">
                                <li className="dropdown-item">
                                    <div
                                        onClick={() => {
                                            history.push('/profile');
                                        }}
                                        className="dropdown-item dropdown-item-span"
                                    >
                                        <i className="feather icon-user" /> {t('landing.view-profile')}
                                    </div>
                                </li>
                                <li className="dropdown-item">
                                    <div onClick={() => Logout()} className="dropdown-item dropdown-item-span">
                                        <i className="feather icon-log-out" /> {t('landing.logout')}
                                    </div>
                                </li>
                            </ul>
                        </Dropdown.Menu>
                    </Dropdown>
                </li>
            </ul>
        </>
    );
}
export default NavRight;

import * as types from '../actions/types';
import {actionTypes} from './reducerTypes';
export let definitionInitialState = {
    meaningModelAction: {showModel: false, meaning: ''},
    definitionSingleData: {definitionData: []},
    definitionPhraseData: {definitionData: []}
};

export const definitionManagementReducer = (state = definitionInitialState, action: actionTypes) => {
    switch (action.type) {
        case types.DEFINITION_MANAGEMENT_SINGLE:
            return {
                ...state,
                definitionSingleData: action.payload
            };
        case types.DEFINITION_MANAGEMENT_PHRASE:
            console.log(action.payload)
            return {
                ...state,
                definitionPhraseData: action.payload
            };

        case types.DEF_MEANING_MODEL:
            return {
                ...state,
                meaningModelAction: action.payload
            };

        default:
            return state;
    }
};

export default definitionManagementReducer;

import { Card, Col, Nav, Row, Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ProjectsPad from './ProjectsPad';
import UserGroupPad from './UserGroupPad';
import UsersPad from './UsersPad';

export interface IRole {
    id: number;
    roleType: string;
}
export interface IGroup {
    id: number;
    group: string;
    users: {
        id: number;
        name: string;
        email: string;
        role: IRole;
        groupUser?: boolean
    }
}
export interface IUser {
    id: number;
    name: string;
    email: string;
    role: IRole;
    assignedGroups?: IGroup[],
    groupUser?: boolean
}
function UserManagement() {
    const { t } = useTranslation();
    return (
        <Tab.Container defaultActiveKey="users">
            <Row className="px-3 pt-3">
                <Col>
                    <Card>
                        <Card.Header className="pt-1">
                            <Nav variant="tabs">
                                <Nav.Item className="text-3 font-weight-bold text-uppercase">
                                    <Nav.Link eventKey="users">
                                        <i className="fa fa-user mt-2 mb-3 mr-2 fa-lg" />
                                        {t('user-project-management.users')}
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="text-3 font-weight-bold text-uppercase">
                                    <Nav.Link eventKey="user-groups">
                                        <i className="fa fa-users mt-2 mb-3 mr-2 fa-lg" />
                                        {t('user-project-management.user-groups')}
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="text-3 font-weight-bold text-uppercase">
                                    <Nav.Link eventKey="projects">
                                        <i className="fa fa-clipboard-list mt-2 mb-3 mr-2 fa-lg" />
                                        {t('user-project-management.projects')}
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Card.Header>
                    </Card>
                </Col>
            </Row>
            <Tab.Content>
                <Tab.Pane eventKey="users">
                    <UsersPad />
                </Tab.Pane>
                <Tab.Pane eventKey="user-groups">
                    <UserGroupPad />
                </Tab.Pane>
                <Tab.Pane eventKey="projects">
                    <ProjectsPad />
                </Tab.Pane>
            </Tab.Content>
        </Tab.Container>
    );
}
export default UserManagement;

// store without redux-tolkit.
import { createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { localStorageKey, setLocalStorageItem } from '../utils/handler';
import reducer from './reducer';
import { taskManagementInitialState, taskReducerInitialState } from './reducers';
var persistedState = {};
let localStorageStoreValue = localStorage.getItem('reduxStore');
if (localStorageStoreValue !== null && localStorageStoreValue !== '') {
  let localStorageStore = JSON.parse(localStorageStoreValue);
  let taskReducer = localStorageStore.taskReducer || taskReducerInitialState
  let taskManagementReducer = localStorageStore.taskManagementReducer || taskManagementInitialState
  if (taskReducer)
    persistedState = {
      taskManagementReducer,
      taskReducer: {
        ...taskReducer,
        filter: taskReducer.filter,
        filter_item: taskReducer.filter_item,
      }
    };
}
export const store = createStore(reducer, persistedState, composeWithDevTools());
window.onbeforeunload = () => {
  setLocalStorageItem(localStorageKey.reduxStore, JSON.stringify(store.getState()));
};
export default store;

import NavSearch from './NavSearch';

function NavLeft() {
    return (
        <ul className="navbar-nav mr-auto">
            <li className="nav-item">
                <NavSearch />
            </li>
        </ul>
    );
}
export default NavLeft;

import * as types from '../actions/types';
import { actionTypes } from './reducerTypes';

let initialState = {
    pagePath: []
};

export const breadcrumbsReducer = (state = initialState, action: actionTypes) => {
    switch (action.type) {
        case types.UPDATE_BREADCRUMBS:
            return {
                ...state,
                pagePath: action.payload
            };
        default:
            return state;
    }
};

export default breadcrumbsReducer;

//  External Dependencies
import Xarrow from 'react-xarrows';
//  Internal Dependencies
import { Mapping } from '../viewTypes/task.types';

interface IArrowGroup {
    connections: Array<Mapping>;
    sourceTaskId: Array<number>;
    destinationTaskId: Array<number>;
}
function ArrowGroup({ connections, sourceTaskId, destinationTaskId }: IArrowGroup) {
    return (
        <>
            {connections.map((item: Mapping) => {
                // eslint-disable-next-line no-undef
                const endpoint = document.getElementById(`TC${item.end.toString()}`);
                // eslint-disable-next-line no-undef
                const startPoint = document.getElementById(`source-${item.start}`);
                if (endpoint && startPoint) {
                    return (
                        <Xarrow
                            key={`arrow-${Date.now()}-${(item.start + item.end) * Math.random()}`}
                            start={`source-${item.start}`}
                            end={`TC${item.end.toString()}`}
                            curveness={0}
                            strokeWidth={3}
                            dashness={(sourceTaskId.includes(item.start) || destinationTaskId.includes(item.end))}
                            lineColor={item.color}
                            showHead={false}
                            showTail={false}
                            startAnchor="bottom"
                            endAnchor="top"
                            zIndex={0}
                        />
                    );
                }
                return (<span />);
            })}
        </>
    );
}
export default ArrowGroup;

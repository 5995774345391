import Axios from 'axios';
import { baseUrl, requestHeader } from '../../config';
import { getCurrentProject, getCurrentProjectId } from '../../utils/handler';
import { CatchReturnError } from './actionHandler';

export const getStatistics = ({ page = 1, rowsPerPage = 10 }: { page: number; rowsPerPage: number }) => {
    return Axios.get(baseUrl + `/getStatistics?page=${page}&limit=${rowsPerPage}&projectId=${getCurrentProjectId()}`, requestHeader)
        .then((res) => {
            return res['data'];
        })
        .catch((err) => {
            CatchReturnError(err);
        });
};

export const getAnalyticsByBook = (bookId: number, projectsId: number) => {
    return Axios.get(baseUrl + `/verse-analysis/byProject/${bookId}/${projectsId}`, requestHeader)
        .then((res) => {
            return res['data'];
        })
        .catch((err) => {
            CatchReturnError(err);
        });
};

// export const getAnalyticsByChapter = (bookListId: number, chpaterId: number) => {
//     return Axios.get(baseUrl + `/verse-analysis/byChpater/${bookListId}/${chpaterId}`, requestHeader)
//         .then((res) => {
//             return res['data'];
//         })
//         .catch((err) => {
//             CatchReturnError(err);
//         });
// };

// export const getManualChanges = () => {
//     return Axios.get(gtsBaseUrl + `/statistics/`, requestHeader)
//         .then((res) => {
//             return res['data'];
//         })
//         .catch((err) => {
//             CatchReturnError(err);
//         });
// };

export const getAutomatedChanges = () => {
    let targetLanguageVersionId = getCurrentProject().targetLanguageVersionId;
    return Axios.get(baseUrl + `/statistics/getChanges/${targetLanguageVersionId || 2}`, requestHeader)
        .then((res) => {
            return res['data'];
        })
        .catch((err) => {
            CatchReturnError(err);
        });
};

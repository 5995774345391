//Login
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const ACCOUNT_LOCKED = 'ACCOUNT_LOCKED';
export const LOGGED_USER = 'LOGGED_USER';
// General
export const CATCH_ERROR = 'CATCH_ERROR';
export const ROBOT_PAGE = 'ROBOT_PAGE';
// profile
export const GET_MY_PROFILE = 'GET_MY_PROFILE';
export const UPDATE_MY_PROFILE = 'UPDATE_MY_PROFILE';
// COMMON
export const PREVIOUS_ACTION = 'PREVIOUS_ACTION';
// TASK
export const UPDATE_CURRENT_TASK = 'UPDATE_CURRENT_TASK';
export const UPDATE_ACTIVE_TASK = 'UPDATE_ACTIVE_TASK';
export const TASK_FILTER = 'TASK_FILTER';
export const TASK_MANAGEMENT_FILTER = 'TASK_MANAGEMENT_FILTER';
export const TASK_MANAGEMENT_FILTER_DATA = 'TASK_MANAGEMENT_FILTER_DATA';
export const TASK_LIST = 'TASK_LIST';
export const BIBLE_FILTER = 'BIBLE_FILTER';
export const TASK_PAGINATION = 'TASK_PAGINATION';
export const TASK_PAGINATION_LOADER = 'TASK_PAGINATION_LOADER';
export const SELECTED_VERSE = 'SELECTED_VERSE';
export const SELECTED_VERSE_INDEX = 'SELECTED_VERSE_INDEX';
export const CREATE_NEW_CONTAINER = 'CREATE_NEW_CONTAINER';
export const FILTER_LOADING = 'FILTER_LOADING';
export const FILTER_ITEM = 'FILTER_ITEM';
export const REVIEWER_FILTER = 'REVIEWER_FILTER';
//BREADCRUMBS
export const UPDATE_BREADCRUMBS = 'UPDATE_BREADCRUMBS ';
// USER
export const USER_LIST = 'USER_LIST ';
export const USER_GROUPS = 'USER_GROUPS';
// ROLE
export const USER_ROLES = 'USER_ROLES';
// STATUS
export const STATUS_LIST = 'STATUS_LIST';
// TOPICS
export const TOPIC_LIST = 'TOPIC_LIST';
// Definition Management
export const GET_ALL_WORDS = 'GET_ALL_WORDS';
export const ALL_WORDS = 'ALL_WORDS';
// Trello Page
export const GET_TRELLO_DATA = 'GET_TRELLO_DATA';
export const TRELLO_DATA = 'TRELLO_DATA';
// WORKSPACE
export const BOOK_DETAILS = 'BOOK_DETAILS';
export const FETCH_TRELLO = 'FETCH_TRELLO';
export const RESET_BOOK = 'RESET_BOOK';
export const WORKSPACE_LOADER = 'WORKSPACE_LOADER';
export const CURRENT_WORD_FOR_MODEL = 'CURRENT_WORD_FOR_MODEL';
export const CURRENT_CONTAINER_MODAL = 'CURRENT_CONTAINER_MODAL';
export const CURRENT_TASK_FOR_MODEL = 'CURRENT_TASK_FOR_MODEL';
export const CURRENT_INDEX = 'CURRENT_INDEX';
export const MODEL_ACTION = 'MODEL_ACTION';
export const MEANING_MODEL = 'MEANING_MODEL';
export const DEF_MEANING_MODEL = 'DEF_MEANING_MODEL';
export const SET_CURRENT_TASK = 'SET_CURRENT_TASK';
export const GET_GRAMMAR_LIST = 'GET_GRAMMAR_LIST';
export const CURRENT_WORD_FOR_SOURCE_MODAL = 'CURRENT_WORD_FOR_SOURCE_MODAL';
export const HISTORY_MODAL = 'HISTORY_MODAL';
export const TRANSLATED_HISTORY_MODAL = 'TRANSLATED_HISTORY_MODAL';
export const ADD_SELECTED_WORD = 'ADD_SELECTED_WORD';
export const SHOW_VERSE_TOPICS = 'SHOW_VERSE_TOPICS';
export const SET_AVAILABLE_FILTERS = 'SET_AVAILABLE_FILTERS';
// Tree
export const TREE_VIEW = 'TREE_VIEW';
export const LANGUAGE_LIST = 'LANGUAGE_LIST';
export const CURRENT_LANGUAGE = 'CURRENT_LANGUAGE';
export const CURRENT_PROJECT = 'CURRENT_PROJECT';
export const TASK_MANAGEMENT_CURRENT_PROJECT = 'TASK_MANAGEMENT_CURRENT_PROJECT';
export const SHOW_LOADING_WINDOW = 'SHOW_LOADING_WINDOW';
// Chapter
export const CHAPTER_LIST = 'CHAPTER_LIST';
export const CHAPTER_ACCESS = 'CHAPTER_ACCESS';
// Verse
export const VERSE_LIST = 'VERSE_LIST';
export const VERSE_MODEL = 'VERSE_MODEL';
// Project
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const PROJECT_LIST = 'PROJECT_LIST';
// Language Version
export const LANGUAGE_VERSION_LIST = 'LANGUAGE_VERSION_LIST';
export const CURRENT_LOCAL_LANGUAGE = 'CURRENT_LOCAL_LANGUAGE';
// definitionManagement Version
export const DEFINITION_MANAGEMENT_SINGLE = 'DEFINITION_MANAGEMENT_SINGLE';
export const DEFINITION_MANAGEMENT_PHRASE = 'DEFINITION_MANAGEMENT_PHRASE';

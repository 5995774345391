import { useState } from 'react';
import { Button, Card, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import errorMessages from 'utils/errorMessages';
import successMessages from 'utils/successMessages';
import { IGroup } from '.';
import {
    assignNewGroupToProject,
    createProjectAction,
    removeAssignedGroupFromProject,
    removeProjectAction,
    updateProjectAction
} from '../../store/actions/projects.actions';
import { RootState } from '../../store/reducer';
import { handleToast } from '../../utils/handler';
import { LanguageVersion, ProjectListType } from '../viewTypes/project.type';

function ProjectsPad() {
    const [isModalVisible, setModalVisible] = useState<boolean>(false);
    const [isAssignModalVisible, setAssignModalVisible] = useState<boolean>(false);
    const [projectName, setProjectName] = useState('');
    const [sourceLanguageVersion, setSourceLanguageVersion] = useState(0);
    const [targetLanguage, setTargetLanguage] = useState(0);
    const [editingProject, setEditingProject] = useState(0);
    const [newGroup, setNewGroup] = useState('');
    const [searchKey, setSearchKey] = useState('');
    const { t } = useTranslation();
    const { languageList, projectList, languageVersionList } = useSelector((state: RootState) => state.mainReducer);
    const { userGroups } = useSelector((state: RootState) => state.userReducer);
    const selectedProject = projectList.find(({ id }: ProjectListType) => id === editingProject);
    const createProject = (e: React.FormEvent) => {
        e.preventDefault();
        const sourceLanguageVersionId = Number(sourceLanguageVersion);
        const targetLanguageId = Number(targetLanguage);
        if (!(sourceLanguageVersionId >= 1)) {
            handleToast({ message: errorMessages.selectSourceLanguage }, 'error');
            return;
        }
        if (!(targetLanguageId >= 1)) {
            handleToast({ message: errorMessages.selectTargetLanguage }, 'error');
            return;
        }
        createProjectAction(projectName, sourceLanguageVersionId, targetLanguageId).then((res) => {
            if (res) {
                handleToast({ message: successMessages.projectCreated }, 'success');
                setModalVisible(false);
            }
        });
    };
    const updateProject = (e: React.FormEvent) => {
        e.preventDefault();
        const sourceLanguageVersionId = Number(sourceLanguageVersion);
        const targetLanguageId = Number(targetLanguage);
        if (!(sourceLanguageVersionId >= 1)) {
            handleToast({ message: errorMessages.selectSourceLanguage }, 'error');
            return;
        }
        if (!(targetLanguageId >= 1)) {
            handleToast({ message: errorMessages.selectSourceLanguage }, 'error');
            return;
        }
        updateProjectAction(editingProject, projectName, sourceLanguageVersionId, targetLanguageId).then((res) => {
            if (res) {
                handleToast({ message: successMessages.projectUpdated }, 'success');
                setModalVisible(false);
            }
        });
    };
    const removeProject = (id: number) => {
        // eslint-disable-next-line no-alert
        if (window.confirm(t(successMessages.deleteProjectConfirmation))) {
            removeProjectAction(id).then((res) => {
                if (res) {
                    setEditingProject(0);
                    handleToast({ message: successMessages.projectRemoved }, 'success');
                }
            });
        }
    };
    const assignNewGroup = () => {
        assignNewGroupToProject(editingProject, Number(newGroup)).then((res) => {
            if (res) {
                handleToast({ message: successMessages.userGroupAssigned }, 'success');
                setAssignModalVisible(false);
            }
        });
    };
    const removeAssignment = (id: number) => {
        removeAssignedGroupFromProject(editingProject, id).then((res) => {
            if (res) {
                handleToast({ message: successMessages.deleteUserGroupConfirmation }, 'success');
            }
        });
    };
    return (
        <>
            <Modal backdrop="static" centered show={isModalVisible} onHide={() => setModalVisible(false)}>
                <Modal.Header closeButton>
                    <Modal.Title as="h5">{t('projects.project')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        onSubmit={(e) => {
                            if (editingProject <= 0) {
                                createProject(e);
                            } else {
                                updateProject(e);
                            }
                        }}
                    >
                        <Form.Group as={Row} controlId="name">
                            <Form.Label column sm={4}>
                                {t('projects.project-name')}
                            </Form.Label>
                            <Col sm={8}>
                                <Form.Control
                                    type="text"
                                    placeholder={t('projects.project-name')}
                                    value={projectName}
                                    onChange={(e) => setProjectName(e.target.value)}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="sourceLanguageVersion" className="align-items-center">
                            <Form.Label column sm={4}>
                                {t('projects.source-language-version')}
                            </Form.Label>
                            <Col sm={8}>
                                <select
                                    className="form-control form-control-sm "
                                    value={Number(sourceLanguageVersion)}
                                    name="source-langid"
                                    onChange={(e) => {
                                        setSourceLanguageVersion(Number(e.target.value));
                                    }}
                                >
                                    <option selected key={0} value={0} disabled>
                                        {t('filter.select-language')}
                                    </option>
                                    {languageVersionList
                                        .filter((languageVersion: LanguageVersion) => languageVersion.isSourceReady === true)
                                        .map((language: LanguageVersion) => (
                                            <option key={language.id} value={language.id}>
                                                {language.versionCode}
                                            </option>
                                        ))}
                                </select>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="targetLanguage" className="align-items-center">
                            <Form.Label column sm={4}>
                                {t('projects.target-language')}
                            </Form.Label>
                            <Col sm={8}>
                                <select
                                    className="form-control form-control-sm "
                                    value={Number(targetLanguage)}
                                    name="target-langid"
                                    onChange={(e) => {
                                        setTargetLanguage(Number(e.target.value));
                                    }}
                                >
                                    <option selected key={0} value={0} disabled>
                                        {t('projects.select-target-language-version')}
                                    </option>
                                    {languageList.map((language: { language: string; id: number }) => (
                                        <option key={language.id} value={language.id}>
                                            {language.language}
                                        </option>
                                    ))}
                                </select>
                            </Col>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="btn btn-sm"
                        onClick={(e) => {
                            if (editingProject <= 0) {
                                createProject(e);
                            } else {
                                updateProject(e);
                            }
                        }}
                    >
                        <i className="fa fa-save mr-2" /> {t('projects.project')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal backdrop="static" centered show={isAssignModalVisible} onHide={() => setAssignModalVisible(false)}>
                <Modal.Header closeButton>
                    <Modal.Title as="h5"> {t('projects.project')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group as={Row} controlId="targetLanguage" className="align-items-center">
                            <Form.Label column sm={4}>
                                {t('projects.group-name')}
                            </Form.Label>
                            <Col sm={8}>
                                <select
                                    className="form-control form-control-sm "
                                    value={Number(newGroup)}
                                    name="target-langid"
                                    onChange={(e) => {
                                        setNewGroup(e.target.value);
                                    }}
                                >
                                    <option selected key={0} value={0} disabled>
                                        {t('projects.select-group')}
                                    </option>
                                    {userGroups
                                        .filter((group: IGroup) => {
                                            if (!selectedProject?.assignedGroups) return true;
                                            const existingItem = selectedProject.assignedGroups.find(
                                                (assignedGroup: IGroup) => assignedGroup.id === group.id
                                            );
                                            if (!existingItem) return true;
                                            return false;
                                        })
                                        .map((group: IGroup) => (
                                            <option key={group.id} value={group.id}>
                                                {group.group}
                                            </option>
                                        ))}
                                </select>
                            </Col>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" className="btn btn-sm" onClick={() => assignNewGroup()}>
                        <i className="fa fa-save mr-2" /> {t('projects.save')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Row className="px-3">
                <Col className="col-6 col-sm-6 col-md-6 col-lg-auto">
                    <Card>
                        <Card.Header className="py-2 px-3">
                            <Row>
                                <Col>
                                    <form className="has-validation">
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            placeholder={t('projects.search-projects') as string}
                                            value={searchKey}
                                            onChange={(e) => setSearchKey(e.target.value)}
                                        />
                                    </form>
                                </Col>
                                <Col className="col-auto">
                                    <Button
                                        variant="primary"
                                        className="btn btn-sm btn-primary"
                                        onClick={() => {
                                            setEditingProject(0);
                                            setModalVisible(true);
                                            setSourceLanguageVersion(0);
                                            setTargetLanguage(0);
                                            setProjectName('');
                                        }}
                                    >
                                        <i className="fa fa-plus mr-2" /> {t('projects.new-project')}
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body className="p-0 overflow-hidden">
                            <Table responsive size="xs" className="overdlow-hidden">
                                <thead>
                                    <tr>
                                        <th className="w-10 text-center"> {t('projects.action')}</th>
                                        <th> {t('projects.project-name')}</th>
                                        <th>{t('projects.source-language')}</th>
                                        <th>{t('projects.target-language')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {projectList
                                        .filter(({ name }: ProjectListType) =>
                                            name?.toLocaleLowerCase().includes(searchKey?.toLocaleLowerCase())
                                        )
                                        .map(
                                            ({
                                                id,
                                                name,
                                                sourceLanguageVersionId,
                                                // eslint-disable-next-line no-shadow
                                                sourceLanguageVersion,
                                                targetLanguageVersion
                                            }: ProjectListType) => {
                                                const targetLanguageObj =
                                                    languageList.find(
                                                        (language: { id: number; language: string }) =>
                                                            language.id === targetLanguageVersion?.targetLanguageId
                                                    ) || '';
                                                return (
                                                    <tr
                                                        key={id}
                                                        onClick={() => {
                                                            setEditingProject(id);
                                                        }}
                                                        style={{ color: id === editingProject ? 'red' : 'black', cursor: 'pointer' }}
                                                    >
                                                        <th scope="row" className="text-center">
                                                            <div className="btn-group">
                                                                <Button
                                                                    variant="outline-primary"
                                                                    className="btn btn-outline-primary btn-sm border-0"
                                                                    data-toggle="tooltip"
                                                                    data-placement="bottom"
                                                                    title={t('projects.edit-project') as string}
                                                                    onClick={() => {
                                                                        setProjectName(name);
                                                                        setSourceLanguageVersion(sourceLanguageVersionId);
                                                                        setTargetLanguage(targetLanguageObj.id);
                                                                        setEditingProject(id);
                                                                        setModalVisible(true);
                                                                    }}
                                                                >
                                                                    <i className="fa fa-pen" />
                                                                </Button>
                                                                <button
                                                                    className="btn btn-outline-primary btn-sm border-0"
                                                                    data-toggle="tooltip"
                                                                    data-placement="bottom"
                                                                    title={t('projects.delete-project') as string}
                                                                    onClick={() => removeProject(id)}
                                                                >
                                                                    <i className="fas fa-trash-alt" />
                                                                </button>
                                                            </div>
                                                        </th>
                                                        <th>{name}</th>
                                                        <th>{sourceLanguageVersion?.versionCode}</th>
                                                        <th>{targetLanguageObj.language}</th>
                                                    </tr>
                                                );
                                            }
                                        )}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
                {editingProject > 0 && (
                    <Col className="col-5 col-sm-5 col-md-5 col-lg-auto">
                        <Card>
                            <Card.Header className="py-2">
                                <Row>
                                    <Col className="col-auto pt-2">
                                        <h5 className="text-uppercase">{t('projects.user-groups-assigned-to-this-project')}</h5>
                                    </Col>
                                    <Col className="col-auto">
                                        <Button
                                            variant="primary"
                                            className="btn btn-sm btn-primary"
                                            onClick={() => {
                                                setNewGroup('');
                                                setAssignModalVisible(true);
                                            }}
                                        >
                                            <i className="fa fa-plus mr-2" /> {t('projects.assign-new-group')}
                                        </Button>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body className="overflow-hidden py-0">
                                <Row>
                                    <Table hover responsive size="xs" id="data-table-zero">
                                        <thead>
                                            <tr>
                                                <th className="w-10 text-center">{t('projects.action')}</th>
                                                <th>{t('projects.group-name')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectedProject?.assignedGroups?.map(({ id, group }: IGroup) => (
                                                <tr key={id}>
                                                    <th>
                                                        <button
                                                            className="btn btn-outline-primary btn-sm border-0"
                                                            data-toggle="tooltip"
                                                            data-placement="bottom"
                                                            title={t('projects.remove-group-from-project') as string}
                                                            onClick={() => removeAssignment(id)}
                                                        >
                                                            <i className="fas fa-trash-alt" />
                                                        </button>
                                                    </th>
                                                    <th>{group}</th>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                )}
            </Row>
        </>
    );
}
export default ProjectsPad;

import React, { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import '../assets/scss/style.scss';
import * as Actions from '../store/actions/index';
import { useSelector } from '../store/reducer';
import { handleToast } from '../utils/handler';

const initialUserObject = {
    email: '',
    image: '',
    name: ''
};
function ProfileSettings() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { myProfile } = useSelector((state: any) => state.userReducer);
    const [userDetails, setUserDetails] = useState<typeof initialUserObject>(initialUserObject);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newConfirmPassword, setNewConfirmPassword] = useState('');
    const [isPasswordUpdating, setPasswordUpdating] = useState(false);
    const [isUsernameUpdating, setUsernameUpdating] = useState(false);
    const { t } = useTranslation();
    useEffect(() => {
        setUserDetails({ email: myProfile.email, image: myProfile.image, name: myProfile.name });
    }, [myProfile]);
    const updateMyProfile = (e: React.FormEvent) => {
        e.preventDefault();
        setUsernameUpdating(true);
        Actions.updateUser(myProfile.id, userDetails)
            .then((message) => {
                setUsernameUpdating(false);
                if (message) {
                    handleToast({ message }, 'success');
                }
            })
            .catch(() => {
                setUsernameUpdating(false);
            });
    };
    const handleUserChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name } = e.target;
        const { value } = e.target;
        setUserDetails({
            ...userDetails,
            [name]: value
        });
    };
    const changePassword = async () => {
        setPasswordUpdating(true);
        const result = await Actions.updatePassword(oldPassword, newPassword, newConfirmPassword);
        if (result) {
            handleToast({ message: t('profile.password-updated-successfully') }, 'success');
            setPasswordUpdating(false);
        } else {
            handleToast({ message: t('profile.error-happened-on-the-server-side') }, '');
            setPasswordUpdating(false);
        }
    };
    return (
        <Row>
            <Col>
                <Card>
                    <div className="row justify-content-center">
                        <div className="profile-card-view card ">
                            <form className="card-body text-center " onSubmit={updateMyProfile} noValidate>
                                <h5 className="mb-4">{t('profile.profile-settings')}</h5>
                                <Avatar
                                    className="mr-2 avatar-profile"
                                    size={'100'}
                                    textSizeRatio={3}
                                    round
                                    src={'getUserImageURL(myProfile.image)'}
                                    name={myProfile.name}
                                />
                                <div className="form-group fill">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={t('profile.name') as string}
                                        name="name"
                                        value={userDetails.name}
                                        onChange={handleUserChange}
                                    />
                                </div>
                                <div className="form-group fill">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="email"
                                        placeholder={t('profile.email') as string}
                                        name="email"
                                        readOnly
                                        value={userDetails.email}
                                        onChange={handleUserChange}
                                    />
                                </div>
                                <hr />
                                <div className="form-group fill">
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="oldPassword"
                                        placeholder={t('profile.old-password') as string}
                                        name="oldPassword"
                                        value={oldPassword}
                                        onChange={(e) => setOldPassword(e.target.value)}
                                    />
                                </div>
                                <div className="form-group fill">
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="newPassword"
                                        placeholder={t('profile.new-password') as string}
                                        name="newPassword"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                </div>
                                <div className="form-group fill">
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="confirmPassword"
                                        placeholder={t('profile.confirm-password') as string}
                                        name="clnfirmPassword"
                                        value={newConfirmPassword}
                                        onChange={(e) => setNewConfirmPassword(e.target.value)}
                                    />
                                </div>
                                <div className="d-flex justify-content-center align-items-center mb-4 px-10">
                                    <button
                                        className="btn btn-primary shadow-2 mr-auto"
                                        onSubmit={updateMyProfile}
                                        disabled={isUsernameUpdating}
                                    >
                                        {t('profile.change-username')}
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-danger shadow-2"
                                        onClick={() => changePassword()}
                                        disabled={isPasswordUpdating}
                                    >
                                        {t('profile.change-password')}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Card>
            </Col>
        </Row>
    );
}
export default ProfileSettings;

import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Alert, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../components/shared/loader';
import { HISTORY_MODAL } from '../../../store/actions/types';
import { RootState } from '../../../store/reducer';
import { emptyModalObj } from '../../../store/reducers';
import { getStatusById } from '../../../utils/handler';
import { TaskHistory } from '../../viewTypes/task.types';

export const HistoryModal = React.memo(() => {
    const dispatch = useDispatch();
    const { historyModal, grammarList } = useSelector((state: RootState) => state.workspaceReducer);
    const { userList, userGroups } = useSelector((state: RootState) => state.userReducer);
    const { languageList } = useSelector((state: RootState) => state.mainReducer);
    const [showModel, setShowModel] = useState(true);
    const [historyDetails, setHistoryDetails] = useState<TaskHistory[]>([]);
    const { t } = useTranslation();
    useEffect(() => {
        setShowModel(historyModal.showHistory);
        setHistoryDetails(historyModal.historyDetails);
    }, [historyModal, historyModal.taskDetails]);
    const closeModel = () => {
        setShowModel(false);
        dispatch({ type: HISTORY_MODAL, payload: emptyModalObj });
    };
    const getTaskChanges = (historyTask: TaskHistory, index: number) => (
        <div key={index}>
            <div>
                {historyTask.statusId ? (
                    <p>
                        {t('workspace.status-changed')}
                        {' '}
                        {getStatusById(historyTask.statusId)}
                    </p>
                ) : null}
            </div>
            <div>
                {historyTask.grammarId ? (
                    <p>
                        {t('workspace.grammar-changed')}
                        {' '}
                        {_.find(grammarList, { id: historyTask.grammarId })?.grammar}
                    </p>
                ) : null}
            </div>
            <div>
                {historyTask.assignedTo ? (
                    <p>
                        {t('workspace.assigned-user-changed')}
                        {' '}
                        {_.find(userList, { id: historyTask.assignedTo })?.name}
                    </p>
                ) : null}
            </div>
            <div>
                {historyTask.grouprelationsId ? (
                    <p>
                        {t('workspace.assigned-group-changed')}
                        {' '}
                        {_.find(userGroups, { id: historyTask.grouprelationsId })?.group}
                    </p>
                ) : null}
            </div>
        </div>
    );
    return (
        <div>
            <Modal
                show={showModel && historyModal.showHistory}
                aria-labelledby="contained-modal-title-vcenter"
                onHide={closeModel}
                backdrop="static"
                centered
            >
                <Modal.Header className="py-2 bg-light-primary" closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t('workspace.history-modal-title')}
                        {' '}
                    </Modal.Title>
                    <span className="badge badge-light-
                    rounded-pill mr-2 ml-1"
                    >
                        {historyModal?.wordDetails?.wordLocation}
                    </span>
                </Modal.Header>
                {historyModal.showHistoryLoader ? (
                    <Loader />
                ) : (
                    <Modal.Body>
                        {historyDetails?.length > 0 ? (
                            historyDetails?.map((history: TaskHistory, index: number) => (
                                <>
                                    <div className="row mb-2">
                                        <div className="col">
                                            <p className="font-weight-bold
                                            text-primary text-4 mb-0 pb-0"
                                            >
                                                {moment(history.createdAt).format('LL')}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-auto text-center">
                                            <div className="badge badge-light-primary mb-1">
                                                {_.find(languageList, {
                                                    id:
                                                        history.languageId,
                                                })?.language}
                                            </div>
                                            <p>
                                                {moment(history.createdAt).format('LT')}
                                                {' '}
                                            </p>
                                        </div>
                                        <div className="col-auto border-left border-primary">
                                            <div className="badge
                                            badge-light-secondary badge-pill mb-1"
                                            >
                                                {history?.changedBy?.name}
                                            </div>
                                            {history.changeText ? (
                                                <p>{history.changeText}</p>
                                            ) : (
                                                <div>{getTaskChanges(history, index) || ''}</div>
                                            )}
                                        </div>
                                    </div>
                                </>
                            ))
                        ) : (
                            <div className="row">
                                <div className="col text-center">
                                    <Alert variant="danger">
                                        <Alert.Heading as="h5" className="m-0 p-0">
                                            {t('workspace.no-history')}
                                        </Alert.Heading>
                                    </Alert>
                                </div>
                            </div>
                        )}
                    </Modal.Body>
                )}
            </Modal>
        </div>
    );
});

interface QueryString<QValue> {
    [key: string]: QValue;
}

export const urlConcatIds = (url: string, programId: string, batchId: string, courseId: string, unitId?: string) => {
    return `${url}?programId=${programId}&batchId=${batchId}&courseId=${courseId}&unitId=${unitId}`;
};

export const getQueryStringParams = (query: string): QueryString<string> => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query).split('&').reduce((params: any, param: any) => {
              const [key, value] = param.split('=');
              params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
              return params;
          }, {})
        : {};
};

import Axios from 'axios';
import moment from 'moment';
import { baseUrl, requestHeader } from '../../config';
import store from '../../store/store';
import { Logout, myProfileObj } from '../../utils/handler';
import { CatchReturnError } from './actionHandler';
import * as types from './types';

export const getMyProfile = async () => {
    return await Axios.get(baseUrl + '/myProfile', requestHeader)
        .then(async (res) => {
            let response = res['data'];
            await store.dispatch({ type: types.GET_MY_PROFILE, payload: response });
            let timeStamp: any = moment();
            localStorage.setItem('lastTimeStamp', timeStamp);
            return response;
        })
        .catch((err) => {
            CatchReturnError(err);
            Logout();
        });
};
export const updateMyProfile = (payload: any) => {
    return Axios.patch(baseUrl + '/users', payload, requestHeader)
        .then((res) => {
            let response = res['data'];
            getMyProfile();
            return response;
        })
        .catch((err) => {
            CatchReturnError(err);
        });
};
export const updateUser = (id: number, payload: any) => {
    return Axios.patch(baseUrl + '/users/' + id, payload, requestHeader)
        .then((res) => {
            let response = res['data'];
            if (myProfileObj().id === id) {
                getMyProfile();
            }
            return response;
        })
        .catch((err) => {
            CatchReturnError(err);
        });
};
export const createUser = (payload: any) => {
    return Axios.post(baseUrl + '/users', payload, requestHeader)
        .then((res) => {
            let response = res['data'];
            return response;
        })
        .catch((err) => {
            CatchReturnError(err);
        });
};
export const usersWithPagination = (payload?: { search?: string; page: number; limit: number }) => {
    let { search = '', page = 1, limit = 10 } = { ...payload };
    return Axios.get(baseUrl + `/usersWithPagination?search=${search}&page=${page}&limit=${limit}`, requestHeader)
        .then((res) => {
            let response = res['data'];
            // getMyProfile();
            return response;
        })
        .catch((err) => {
            CatchReturnError(err);
        });
};
export const getUsersAction = () => {
    return Axios.get(baseUrl + `/users`, requestHeader)
        .then((res) => {
            let response = res['data'];
            store.dispatch({ type: types.USER_LIST, payload: response });
            return response;
        })
        .catch((err) => {
            CatchReturnError(err);
        });
};
export const deleteUserAction = (id: number) => {
    return Axios.delete(baseUrl + '/users/' + id, requestHeader)
        .then((res) => {
            let response = res['data'];
            // to make sure current user is not deleted
            return response;
        })
        .catch((err) => {
            CatchReturnError(err);
        });
};
export const getRolesAction = () => {
    return Axios.get(baseUrl + '/roles', requestHeader)
        .then((res) => {
            let response = res['data'];
            store.dispatch({ type: types.USER_ROLES, payload: response });
            return response;
        })
        .catch((err) => {
            CatchReturnError(err);
        });
};
export const createUserGroups = (payload: any) => {
    return Axios.post(baseUrl + '/user-groups', payload, requestHeader)
        .then((res) => {
            let response = res['data'];
            return response;
        })
        .catch((err) => {
            CatchReturnError(err);
        });
};

export const addRemoveUserToGroup = (payload: { userGroupId: number; userId: number }) => {
    return Axios.patch(baseUrl + `/add-remove-userGroup`, payload, requestHeader)
        .then((res) => {
            let response = res['data'];
            return response;
        })
        .catch((err) => {
            CatchReturnError(err);
        });
};

export const updateUserGroups = (payload: any, groupId: number) => {
    return Axios.patch(baseUrl + `/user-groups/${groupId}`, payload, requestHeader)
        .then((res) => {
            let response = res['data'];
            return response;
        })
        .catch((err) => {
            CatchReturnError(err);
        });
};

export const fetchUserGroups = () => {
    return (
        Axios.get(baseUrl + `/user-groups`, requestHeader)
            // return Axios.get(baseUrl + '/user-groups?search', requestHeader)
            .then((res) => {
                let response = res['data'];
                store.dispatch({ type: types.USER_GROUPS, payload: response });
                return response;
            })
            .catch((err) => {
                CatchReturnError(err);
            })
    );
};

export const deleteUserGroup = (groupId: number) => {
    return Axios.delete(baseUrl + `/user-groups/${groupId}`, requestHeader)
        .then((res) => {
            let response = res['data'];
            return response;
        })
        .catch((err) => {
            CatchReturnError(err);
        });
};

export const getUserByGroupId = (groupId: number, filter: { showGroupUsers?: boolean }) => {
    return Axios.get(`${baseUrl}/getUserByGroupId/` + groupId, { ...requestHeader, params: filter })
        .then((res) => {
            const { data } = res;
            return data;
        })
        .catch((err) => {
            CatchReturnError(err);
        });
};

export const updatePassword = (oldPassword: string, newPassword: string, confirmNewPassword: string) => {
    return Axios.patch(
        `${baseUrl}/users/changePassword`,
        { oldPassword: oldPassword, password: newPassword, confirmPassword: confirmNewPassword },
        requestHeader
    )
        .then((res) => {
            const { data } = res;
            return data;
        })
        .catch((err) => {
            CatchReturnError(err);
        });
};

/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Button, Card, Col, InputGroup, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReactPagination } from '../components/common';
import Loader, { PageLoader } from '../components/shared/loader';
import { TableFilter } from '../components/taskTable';
import i18n from '../internationalization/i18n';
import { getDefinition, getMeaningsByWord } from '../store/actions/definition.action';
import { meaningsUpdateForWord, updateUniqueMeaning } from '../store/actions/task.action';
import { updateTranslatedWord } from '../store/actions/translated.words.action';
import {
    BOOK_DETAILS,
    DEFINITION_MANAGEMENT_PHRASE,
    DEFINITION_MANAGEMENT_SINGLE,
    DEF_MEANING_MODEL,
    UPDATE_BREADCRUMBS
} from '../store/actions/types';
import * as verseAction from '../store/actions/verse.actions';
import { RootState } from '../store/reducer';
import errorMessages from '../utils/errorMessages';
import {
    getAllStatus,
    getCurrentProject,
    getCurrentProjectId,
    getReviewerStatus,
    handleToast,
    removePunctuation,
    updateLocationDragAndDrop
} from '../utils/handler';
import successMessages from '../utils/successMessages';
import { DefinitionObj, ReviewerAction, WordLocation, Words } from './viewTypes/definition-management.type';
import { IUser, Verse } from './viewTypes/task.types';
import { onDragEnd, onDragEnter, onDragOver, onDragStartLocation } from './workspace-wizard/handler';

const definitionManagementTypes = {
    word: 'word',
    sourceWord: 'sourceWord',
    meaning: 'meaning',
    language: 'language',
    location: 'location',
    book: 'book',
    chapter: 'chapter',
    verse: 'verse',
    wordLocation: 'wordLocation'
};
const typeWord = <i className="fa fa-quote-left fa-flip-vertical fa-2x align-middle text-dark" />;
const typeMeaning = <i className="fa fa-book fa-2x align-middle text-primary" />;
const typeBible = <i className="fa fa-bible fa-2x align-middle text-success" />;
const typeVerse = <i className="fa fa-file-alt fa-2x align-middle text-success" />;
const languageIcon = <i className="fa fa-solid fa-language text-success" />;
const tabTypes = {
    singleWord: i18n.t('definition-management.single-word'),
    multiWord: i18n.t('definition-management.phrase')
};
const highlightContentCustom = (content: string, wordLocations: { word: string; wordIndex: number }[]) => {
    if (wordLocations?.length) {
        const isHighlightWord = (word: string, index: number) => _.find(wordLocations, (obj: { word: string; wordIndex: number }) => {
            if (obj.word?.toLocaleLowerCase().includes(removePunctuation(word)?.toLocaleLowerCase()) && obj.wordIndex === index) {
                return obj;
            }
            return null;
        });
        return content.split(' ').map((word, index) =>
            isHighlightWord(word, index + 1) ? (
                <span key={word} className="">
                    {' '}
                    <mark className="word-highlight">{word}</mark>
                </span>
            ) : (
                <span key={word}>{word} </span>
            )
        );
    }
    return content;
};
function AnimatedTreeCustom(props: any) {
    const { content, prefix, wordLocations, id, type, hasChild, extend, showLoader, onClick } = props;
    return (
        <>
            <span
                onClick={() => {
                    onClick(content);
                }}
                className="f-bold"
            >
                {extend && showLoader ? (
                    <i className="fa-solid fa-spinner fa-spin" />
                ) : (
                    <i
                        className={
                            `fa-regular ${!type || hasChild === false
                                ? 'fa-square-xmark text-ash'
                                : extend
                                    ? 'fa-square-minus'
                                    : 'fa-square-plus'}`
                        }
                    />
                )}
            </span>
            <span className="">
                <span className="type-icon">{type || typeVerse}</span>
                <span className="content" id={id}>
                    {prefix}
                    {highlightContentCustom(content, wordLocations,)}
                </span>
            </span>
        </>
    );
}
export function MeaningPopup(props: { addMeaning: any }) {
    const dispatch = useDispatch();
    const [showModel, setShowModel] = useState(false);
    const { meaningModelAction } = useSelector((state: RootState) => state.definitionManagementReducer);
    const [meaning, setMeaning] = useState(meaningModelAction.meaning);
    const { t } = useTranslation();
    const { addMeaning } = props;
    const closeModel = () => {
        setShowModel(false);
        dispatch({ type: DEF_MEANING_MODEL, payload: { ...meaningModelAction, showModel: false } });
    };
    useEffect(() => {
        setShowModel(meaningModelAction.showModel);
        setMeaning(meaningModelAction.meaning);
    }, [meaningModelAction]);
    const addMeaningFC = () => {
        if (meaning) {
            addMeaning(meaning);
        } else {
            handleToast({ message: errorMessages.missingMandatoryField });
        }
        closeModel();
    };
    return (
        <Modal show={showModel} size="lg" aria-labelledby="contained-modal-title-vcenter" onHide={closeModel} backdrop="static" centered>
            <Modal.Header className="py-2 bg-light-primary" closeButton>
                <Modal.Title>{t('definition-management.meaning-action')}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4 py-2 p-3">
                <div className="input-group">
                    <input
                        type="text"
                        className="form-control"
                        id="Meaning"
                        placeholder="Meaning"
                        name="Meaning"
                        value={meaning}
                        onChange={(e) => setMeaning(e.target.value)}
                        required
                    />
                    <Button className=" btn-sm  ml-1" onClick={addMeaningFC}>
                        {meaningModelAction.uniqueMeaningId ? t('task-board.update') : t('user-group.add')}
                    </Button>
                </div>
                <div>
                    <span className="note text-danger">
                        {t('definition-management.note')}
                        {meaningModelAction.uniqueMeaningId
                            ? t('definition-management.this-will-change-all-other-meanings-throughout-the-bible')
                            : t('definition-management.make-sure-you-added-drag-drop-the-verse-to-save-this-meaning')}
                    </span>
                </div>
            </Modal.Body>
        </Modal>
    );
}
function GetAssignedUserAndStatus(props: {
    word: string;
    meaning: string;
    words: Words[];
    uniqueMeaningId: number;
    // eslint-disable-next-line react/require-default-props
    reviewerStatusId?: number;
}) {
    const currentProjectId = getCurrentProject().id;
    const [statusId, setStatusId] = useState(0);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { word, meaning, uniqueMeaningId } = props;
    const updateVerseAction = () => {
        verseAction
            .updateTaskByReviewer({ word, uniqueMeaningId, statusId, projectId: currentProjectId })
            .then((result) => {
                if (result) {
                    dispatch({ type: BOOK_DETAILS, payload: { chapters: [] } });
                    handleToast({ message: successMessages.success }, 'success');
                }
            });
    };
    const { reviewerStatusId } = props;
    useEffect(() => {
        if (reviewerStatusId) setStatusId(reviewerStatusId);
    }, []);
    return (
        <div className=" definition-assignment">
            {props?.words?.length > 0 ? (
                <>
                    <span className="py-2 bg-white">
                        <InputGroup className="">
                            <select
                                className="form-control form-control-sm"
                                disabled={false}
                                value={statusId}
                                onChange={(e) => {
                                    setStatusId(Number(e.target.value));
                                }}
                            >
                                <option selected key={0} value={0} disabled>
                                    {t('task-management.select-to-update')}
                                </option>
                                {getAllStatus('definitionManagement').map((status: { id: number; status: string }) => (
                                    <option key={status.id} value={status.id}>
                                        {status.status}
                                    </option>
                                ))}
                            </select>
                        </InputGroup>
                    </span>
                    <Button onClick={() => updateVerseAction()} className="btn btn-primary btn-sm rounded ml-2 definition-update-btn ">
                        <i className="fa fa-sync-alt" />
                    </Button>
                </>
            ) : null}
            <Button
                onClick={() =>
                    dispatch({
                        type: DEF_MEANING_MODEL,
                        payload: { showModel: true, word, uniqueMeaningId, meaning }
                    })
                }
                className="btn btn-primary btn-sm rounded ml-2 definition-update-btn "
            >
                <i className="fa fa-pencil-alt" />
            </Button>
        </div>
    );
};
const defaultTab = tabTypes.singleWord;
export function DefinitionManagement() {
    const { selectedLanguage, currentProject } = useSelector((state: RootState) => state.mainReducer);
    const { filter } = useSelector((state: RootState) => state.taskReducer);
    const [errorMessage] = useState('');
    const [showSource, setShowSource] = useState(false);
    const [showModel, setShowModel] = useState(false);
    const [pageLoader, setPageLoader] = useState(false);
    const { userList } = useSelector((state: RootState) => state.userReducer);
    const [sourceUniqueMeaningId, setSourceUniqueMeaningId] = useState<number>();
    const [sourceWord, setSourceWord] = useState('');
    const [targetHighlightWord, setTargetHighlightWord] = useState('');
    const [phrasePage, setPhrasePage] = useState(1);
    const [activeTab, setActiveTab] = useState(defaultTab);
    const [verseList, setVerseList] = useState<Verse[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const checkIsSingleTab = () => activeTab === tabTypes.singleWord;
    const { meaningModelAction, definitionPhraseData, definitionSingleData } = useSelector(
        (state: RootState) => state.definitionManagementReducer
    );
    const phrasePageLimit = 50;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { definitionFilter } = useSelector((state: RootState) => state.taskReducer);
    const definitionData = definitionSingleData.definitionData || [];
    const phraseData = definitionPhraseData.definitionData || [];
    const getTreeWords = async (page: number = 1, search: string = '') => {
        if (getCurrentProjectId()) {
            setLoading(true);
            await getDefinition({
                ...filter,
                ...definitionFilter,
                search,
                limit: definitionSingleData.limit || 50,
                filterPhrase: !checkIsSingleTab(),
                page
            });
            setLoading(false);
        }
    };
    const onPageChange = (pageNumber: number) => {
        getTreeWords(pageNumber);
    };
    useEffect(() => {
        const apiCall = true;
        if (apiCall) getTreeWords(1, filter.search);
    }, [filter, currentProject.id]);
    useEffect(() => {
        if (activeTab && !checkIsSingleTab()) {
            getTreeWords();
        }
    }, [activeTab]);
    useEffect(() => {
        dispatch({
            type: UPDATE_BREADCRUMBS,
            payload: []
        });
    }, []);
    const updateMeaning = (meanings: any, word: string, uniqueMeaningId?: number) => {
        let treeData: DefinitionObj[] = [];
        if (checkIsSingleTab()) {
            treeData = definitionData;
        } else {
            treeData = phraseData;
        }
        const wordIndex = _.findIndex(treeData, (obj) => obj.word === word);
        if (wordIndex >= 0) {
            if (uniqueMeaningId) {
                const existingMeanings = treeData[wordIndex]?.meanings || [];
                const meaningIndex = _.findIndex(existingMeanings, { uniqueMeaningId });
                if (meaningIndex >= 0) {
                    const meaning = meanings[0];
                    meaning.extend = existingMeanings[meaningIndex].extend;
                    existingMeanings[meaningIndex].words.sort().forEach((wordObj) => {
                        if (wordObj.extend) {
                            const highlightWordIndex = _.findIndex(meaning.words, { highlightWord: wordObj.highlightWord });
                            if (highlightWordIndex >= 0) meaning.words[highlightWordIndex].extend = true;
                        }
                    });
                    existingMeanings[meaningIndex] = meaning;
                    treeData[wordIndex].meanings = _.uniqBy(existingMeanings, 'uniqueMeaningId');
                }
            } else {
                let existingMeanings = treeData[wordIndex]?.meanings || [];
                existingMeanings = [...(existingMeanings || []), ...(meanings || [])];
                treeData[wordIndex].meanings = _.uniqBy(existingMeanings, 'uniqueMeaningId');
            }
            if (checkIsSingleTab()) {
                dispatch({ type: DEFINITION_MANAGEMENT_SINGLE, payload: { ...definitionSingleData, definitionData: treeData } });
            } else {
                dispatch({ type: DEFINITION_MANAGEMENT_PHRASE, payload: { ...definitionPhraseData, definitionData: treeData } });
            }
        }
    };
    const getMeaningByWord = (word: string = sourceWord) => {
        getMeaningsByWord({ projectId: currentProject.id, word, uniqueMeaningId: sourceUniqueMeaningId }).then(
            (result: any) => {
                if (result) {
                    setPageLoader(false);
                    updateMeaning(result, word, sourceUniqueMeaningId);
                }
            });
    };
    const getWords = (words: Words[]) => {
        const languageVersionId = showSource ? currentProject.sourceLanguageVersionId : currentProject.targetLanguageVersionId;
        return _.filter(_.sortBy(words, 'languageVersionId'), { languageVersionId });
    };
    const updateDefinition = (prop: {
        type: string;
        wordLocationIndex?: number;
        sourceWordIndex: number;
        meaningIndex?: number;
        wordIndex?: number;
        bookIndex?: number;
        chapterIndex?: number;
        verseIndex?: number;
    }) => {
        // make it default to refresh the data on project changing
        const { type = definitionManagementTypes.sourceWord, sourceWordIndex = 0, meaningIndex = -1, wordIndex = -1 } = prop;
        let treeData: DefinitionObj[] = [];
        if (checkIsSingleTab()) {
            treeData = definitionData;
        } else {
            treeData = phraseData;
        }
        if (type === definitionManagementTypes.sourceWord) {
            const { extend } = treeData[sourceWordIndex];
            treeData[sourceWordIndex].extend = !extend;
            if (treeData[sourceWordIndex].extend && !treeData[sourceWordIndex].meanings) getMeaningByWord(treeData[sourceWordIndex].word);
        } else if (type === definitionManagementTypes.meaning) {
            const { extend } = treeData[sourceWordIndex].meanings[meaningIndex];
            treeData[sourceWordIndex].meanings[meaningIndex].extend = !extend;
        } else if (type === definitionManagementTypes.word) {
            const words = getWords(treeData[sourceWordIndex].meanings[meaningIndex].words);
            const { extend } = words[wordIndex];
            words[wordIndex].extend = !extend;
        }
        if (checkIsSingleTab()) {
            dispatch({ type: DEFINITION_MANAGEMENT_SINGLE, payload: { ...definitionSingleData, definitionData: treeData } });
        } else {
            dispatch({ type: DEFINITION_MANAGEMENT_PHRASE, payload: { ...definitionPhraseData, definitionData: treeData } });
        }
    };
    const onAssignToChange = (index: number, assignedTo: number) => {
        if (checkIsSingleTab()) {
            definitionData[index].reviewerAssignedTo = assignedTo;
            dispatch({ type: DEFINITION_MANAGEMENT_SINGLE, payload: { ...definitionSingleData, definitionData } });
        } else {
            phraseData[index].reviewerAssignedTo = assignedTo;
            dispatch({ type: DEFINITION_MANAGEMENT_PHRASE, payload: { ...definitionPhraseData, definitionData: phraseData } });
        }
    };
    const getStatusFromTaskAction = (taskActions: ReviewerAction[]): number => {
        const taskAction = _.find(taskActions, { languageId: selectedLanguage });
        if (taskAction) {
            return taskAction.reviewerStatusId || 6;
        }
        return 6;
    };
    const getMeanings: any = (key: string, value: string | number) => {
        let treeData: DefinitionObj[] = [];
        if (checkIsSingleTab()) {
            treeData = definitionData;
        } else {
            treeData = phraseData;
        }
        const wordIndex = _.findIndex(treeData, { [key]: value });
        if (wordIndex >= 0) {
            return treeData[wordIndex].meanings;
        }
        return [];
    };
    const treeData = () => {
        if (checkIsSingleTab()) {
            return definitionData;
        }
        return phraseData.slice(Math.ceil(phrasePageLimit * (phrasePage - 1)), phrasePageLimit * phrasePage);
    };
    const addMeaning = (meaning: string) => {
        if (meaningModelAction.uniqueMeaningId) {
            meaningsUpdateForWord({
                languageId: selectedLanguage,
                uniqueMeaningId: meaningModelAction.uniqueMeaningId,
                meaning: meaningModelAction.meaning,
                word: meaningModelAction.word,
                newMeaning: meaning
            }).then((result) => {
                if (result) {
                    const meanings = getMeanings('word', meaningModelAction.word);
                    if (meanings.length) {
                        const meaningIndex = _.findIndex(meanings, { uniqueMeaningId: meaningModelAction.uniqueMeaningId });
                        meanings[meaningIndex].meaning = meaning;
                        meanings[meaningIndex].uniqueMeaningId = result.uniqueMeaningId;
                        updateMeaning(meanings, meaningModelAction.word);
                    }
                }
            });
        } else {
            updateMeaning(
                [
                    {
                        meaning,
                        uniqueMeaningId: 0,
                        words: [],
                        extend: false
                    }
                ],
                meaningModelAction.word
            );
        }
    };
    const getVerseDetail = (payload: { verseId: number; targetVerseId: number; taskId: number }[]) => {
        setShowModel(true);
        setVerseList([]);
        verseAction.getVerseDetailById(payload.map((idObj) => idObj.verseId).join(','), currentProject.id).then((verseListResponse) => {
            if (verseListResponse) {
                verseListResponse.forEach((verse: Verse) => {
                    verse.taskId = _.find(payload, { verseId: verse.id })?.taskId;
                    verse.verseAccess = true;
                    verse.definitionView = true;
                });
                setVerseList(verseListResponse);
            } else {
                setShowModel(false);
                handleToast({ message: errorMessages.somethingWentWrong });
            }
        });
    };
    const getHighlightedWordFromTarget = (highlightWord: string, index: number = 0) => highlightWord.split(' ')[index] ? highlightWord.split(' ')[index] : highlightWord.split(' ')[index - 1];
    const onDrop = async (targetPayload: {
        event: any;
        wordLocations?: WordLocation[];
        targetWord?: any;
        targetMeaning: string;
        targetUniqueMeaningId: number;
        targetTreeWord: string;
    }) => {
        const { event, targetUniqueMeaningId, targetWord, targetMeaning, targetTreeWord } = targetPayload;
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        let sourcePayload: any = event.dataTransfer.getData('definition');
        if (!sourcePayload) return;
        sourcePayload = JSON.parse(sourcePayload);
        // eslint-disable-next-line no-shadow
        const { sourceTreeWord, sourceTasksId, sourceUniqueMeaningId, sourceWord } = sourcePayload;
        // dropped location should be same word but different meaning
        if (showSource) {
            if (sourceTreeWord === targetTreeWord && sourceUniqueMeaningId !== targetUniqueMeaningId) {
                updateUniqueMeaning({ tasksId: sourceTasksId, uniqueMeaningId: targetUniqueMeaningId, targetMeaning }).then((res) => {
                    if (res?.uniqueMeaningId) {
                        const meanings = getMeanings('word', sourceTreeWord);
                        const newMeanings = updateLocationDragAndDrop({
                            newUniqueMeaningId: res.uniqueMeaningId,
                            meanings,
                            sourcePayload,
                            targetPayload
                        });
                        if (newMeanings) { updateMeaning(newMeanings, sourceTreeWord); }
                    }
                });
            }
        } else if (targetWord && sourceUniqueMeaningId === targetUniqueMeaningId && sourcePayload.sourceWord !== targetWord.highlightWord) {
            setTargetHighlightWord(targetWord.highlightWord);
            if (sourcePayload.wordLocations.length > 1 || targetWord.highlightWord.split(' ').length > 1) {
                const verseIdList = sourcePayload.wordLocations.map((wordLocation: WordLocation) => ({ verseId: wordLocation.versesId, targetVerseId: wordLocation.targetVerseId, taskId: wordLocation.tasksId }));
                setSourceUniqueMeaningId(sourceUniqueMeaningId);
                setSourceWord(sourceTreeWord);
                getVerseDetail(verseIdList);
            } else {
                for await (const wordLocation of sourcePayload.wordLocations) {
                    let highlightWordCount = 0;
                    for await (const highlight of wordLocation.highlightWords) {
                        const highlightWord = getHighlightedWordFromTarget(targetWord.highlightWord, highlightWordCount);
                        highlightWordCount++;
                        if (highlightWord !== highlight.word && sourceUniqueMeaningId === targetUniqueMeaningId) {
                            updateTranslatedWord(highlight.id, { word: highlightWord }).then((res) => {
                                if (res) {
                                    const meanings = getMeanings('word', targetTreeWord);
                                    const meaningIndex = _.findIndex(meanings, { uniqueMeaningId: targetUniqueMeaningId });
                                    // Removing wordObject from source
                                    const sourceWordObjIndex = _.findIndex(meanings[meaningIndex]?.words, { highlightWord: sourceWord });
                                    const wordLocationIndex = _.findIndex(meanings[meaningIndex]?.words[sourceWordObjIndex]?.wordLocations, {
                                        position: wordLocation.position
                                    });
                                    const sourceWordLocationObj =
                                        meanings[meaningIndex].words[sourceWordObjIndex].wordLocations[wordLocationIndex];
                                    if (sourceWordLocationObj) {
                                        meanings[meaningIndex].words[sourceWordObjIndex].wordLocations.splice(wordLocationIndex, 1);
                                        // adding source into target
                                        const targetWordIndex = _.findIndex(meanings[meaningIndex].words, { highlightWord });
                                        const verseWords: string[] = sourceWordLocationObj.verse.split(' ');
                                        verseWords.forEach((word: string, index: number) => {
                                            const highlightWordIndex = _.findIndex(sourceWordLocationObj.highlightWords, {
                                                wordIndex: index
                                            });
                                            if (highlightWordIndex >= 0) {
                                                const { wordIndex } = sourceWordLocationObj.highlightWords[highlightWordIndex];
                                                verseWords[wordIndex] = highlightWord;
                                                verseWords.push(highlightWord);
                                                sourceWordLocationObj.highlightWords[highlightWordIndex].word = highlightWord;
                                            }
                                        });
                                        sourceWordLocationObj.verse = verseWords.join(' ');
                                        meanings[meaningIndex].words[targetWordIndex]?.wordLocations.push(sourceWordLocationObj);
                                        updateMeaning(meanings, sourceTreeWord);
                                    }
                                }
                            });
                        }
                    }
                }
            }
        }
    };
    const closeModel = (refresh = false) => {
        if (refresh) {
            setVerseList([]);
            setTargetHighlightWord('');
            setPageLoader(true);
            getMeaningByWord();
        }
        setShowModel(false);
    };
    const updateVerseAction = (word: string, reviewerAssignedTo: number | null) => {
        if (reviewerAssignedTo) {
            verseAction.updateTaskAssignedTo({ word, assignedTo: reviewerAssignedTo, projectId: currentProject.id }).then((result) => {
                if (result) {
                    dispatch({ type: BOOK_DETAILS, payload: { chapters: [] } });
                    handleToast({ message: successMessages.success }, 'success');
                }
            });
        } else {
            handleToast({ message: t('definition-management.choose-user') });
        }
    };
    return (
        <>
            {pageLoader ? <PageLoader /> : null}
            <TableFilter statusFilter={'definitionManagement'} />
            <MeaningPopup addMeaning={addMeaning} />
            {showModel ? (
                <div className="popup">
                    <div className="card">
                        <div className="card-header">
                            <div className="h5">{t('definition-management.update-target-container-and-mapping')}</div>
                            <div onClick={() => closeModel()} className=" c-pointer">
                                <i className="fa-solid fa-circle-xmark" />
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="highlighted-word-row">
                                {targetHighlightWord
                                    ? targetHighlightWord?.split(' ').map((word) => (
                                        <span key={word} className="highlighted-word mr-2">
                                            {word}
                                            <i
                                                className="c-pointer ml-1 fa-solid fa-copy text-warning "
                                                title="Copy"
                                                // eslint-disable-next-line no-undef
                                                onClick={() => navigator.clipboard.writeText(word)}
                                            />
                                        </span>
                                    ))
                                    : null}
                            </div>
                            {verseList.length === 0 ? (
                                <Loader />
                            ) : (
                                verseList?.map(() => (
                                    <span>{t('definition-management.new-workspace-wizard-should-show-here-instead-old-workspace')}</span>
                                ))
                            )}
                        </div>
                        <div className="card-footer">
                            <Button variant="outline-secondary px-2 py-1 mx-1" onClick={() => closeModel(true)}>
                                <i className="fa fa-times mr-2" />
                                {t('definition-management.done-and-refresh')}
                            </Button>
                        </div>
                    </div>
                </div>
            ) : null}
            <Row>
                <Col>
                    <Card className="shadow-none">
                        <ul className="nav nav-tabs mb-3" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation" onClick={() => setActiveTab(tabTypes.singleWord)}>
                                <button
                                    className={`nav-link ${checkIsSingleTab() ? ' active' : ''}`}
                                    id="Single Word Tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#singleWord"
                                    type="button"
                                    role="tab"
                                    aria-controls="single-word"
                                    aria-selected="true"
                                >
                                    {tabTypes.singleWord}
                                </button>
                            </li>
                            <li className="nav-item" role="presentation" onClick={() => setActiveTab(tabTypes.multiWord)}>
                                <button
                                    className={`nav-link ${!checkIsSingleTab() ? ' active' : ''}`}
                                    id="Multi Word Tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#multiWords"
                                    type="button"
                                    role="tab"
                                    aria-controls="multi-word"
                                    aria-selected="false"
                                >
                                    {tabTypes.multiWord}
                                </button>
                            </li>
                        </ul>
                        <Card.Body className="card-height-general">
                            {loading ? (
                                <Loader className="overlay-loader" />
                            ) : errorMessage ? (
                                <div className="text-center">
                                    <span>{errorMessage}</span>
                                </div>
                            ) : null}
                            {!loading ? (
                                <div className="definition-container">
                                    <div className="float-right d-flex align-items-center">
                                        <button className="btn btn-primary btn-sm " onClick={() => getTreeWords()}>
                                            {t('resource.english')}
                                        </button>
                                        <div className="ml-2">
                                            <i
                                                className={`fa-solid fa-lg ${showSource ? 'fa-toggle-on' : 'fa-toggle-off'}`}
                                                onClick={() => setShowSource(!showSource)}
                                                title={`${showSource ? 'Show' : 'Hide'} English Locations`}
                                            />
                                        </div>
                                    </div>
                                    {treeData()?.map((tree: DefinitionObj, sourceWordIndex: number) => (
                                        <div key={tree.word} className="tree-container word-container">
                                            <div className="d-flex">
                                                <AnimatedTreeCustom
                                                    showLoader={!tree?.meanings}
                                                    content={tree.word}
                                                    onClick={() =>
                                                        updateDefinition({ type: definitionManagementTypes.sourceWord, sourceWordIndex })
                                                    }
                                                    extend={tree.extend}
                                                    type={typeWord}
                                                />
                                                <div className="bg-white user-action">
                                                    <InputGroup>
                                                        <select
                                                            className="form-control form-control-sm"
                                                            disabled={false}
                                                            value={tree.reviewerAssignedTo || 0}
                                                            onChange={(e) => {
                                                                onAssignToChange(sourceWordIndex, Number(e.target.value));
                                                            }}
                                                        >
                                                            <option selected key={0} value={0} disabled>
                                                                {t('task-management.select-to-update')}
                                                            </option>
                                                            <option key="clear" value="clear">
                                                                {t('task-management.clear-assignment')}
                                                            </option>
                                                            {userList.map((user: IUser,) => (
                                                                <option key={user.id} value={user.id}>
                                                                    {user.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <Button
                                                            onClick={() => updateVerseAction(tree.word, tree.reviewerAssignedTo)}
                                                            className="btn btn-primary btn-sm rounded ml-2 definition-update-btn "
                                                        >
                                                            <i className="fa fa-sync-alt" />
                                                        </Button>
                                                    </InputGroup>
                                                </div>
                                            </div>
                                            {tree.extend
                                                ? tree?.meanings?.map((meaning, meaningIndex: number) => (
                                                    <div
                                                        key={meaning.uniqueMeaningId}
                                                        className="tree-container meaning-container"
                                                        onDrop={(event) =>
                                                            onDrop({
                                                                event,
                                                                targetMeaning: meaning.meaning,
                                                                targetUniqueMeaningId: meaning?.uniqueMeaningId,
                                                                // -------------------------
                                                                targetTreeWord: tree.word
                                                            })
                                                        }
                                                        onDragOver={onDragOver}
                                                        onDragEnter={onDragEnter}
                                                        onDragEnd={onDragEnd}
                                                    >
                                                        <div className="d-flex" id={meaning?.uniqueMeaningId?.toString()}>
                                                            <AnimatedTreeCustom
                                                                content={meaning.meaning}
                                                                hasChild={getWords(meaning?.words).length > 0}
                                                                onClick={() =>
                                                                    updateDefinition({
                                                                        type: definitionManagementTypes.meaning,
                                                                        sourceWordIndex,
                                                                        meaningIndex
                                                                    })
                                                                }
                                                                extend={meaning.extend}
                                                                type={typeMeaning}
                                                            />
                                                            {(showSource || !meaning?.uniqueMeaningId) ? null : (
                                                                <GetAssignedUserAndStatus
                                                                    uniqueMeaningId={meaning?.uniqueMeaningId}
                                                                    reviewerStatusId={meaning.reviewerStatusId}
                                                                    word={tree.word}
                                                                    meaning={meaning.meaning}
                                                                    words={meaning?.words}
                                                                />
                                                            )}
                                                        </div>
                                                        {meaning.extend &&
                                                            getWords(meaning?.words).map((word: Words, wordIndex: number) => (
                                                                <span>
                                                                    {
                                                                        <div
                                                                            key={word.languageVersionId}
                                                                            draggable
                                                                            onDragStart={(event) => {
                                                                                onDragStartLocation({
                                                                                    event,
                                                                                    eventPayload: {
                                                                                        // -----------------------
                                                                                        wordLocations: word.wordLocations,
                                                                                        sourceTreeWord: tree.word,
                                                                                        sourceUniqueMeaningId: meaning?.uniqueMeaningId,
                                                                                        sourceTasksId: word.wordLocations.map(
                                                                                            (wordLocation) => wordLocation.tasksId
                                                                                        ),
                                                                                        sourceWord: word.highlightWord
                                                                                    }
                                                                                });
                                                                            }}
                                                                            onDrop={(event) =>
                                                                                onDrop({
                                                                                    targetWord: word,
                                                                                    event,
                                                                                    targetMeaning: meaning.meaning,
                                                                                    targetUniqueMeaningId: meaning?.uniqueMeaningId,
                                                                                    // -------------------------
                                                                                    targetTreeWord: tree.word
                                                                                })
                                                                            }
                                                                            className="tree-container language-container"
                                                                        >
                                                                            <AnimatedTreeCustom
                                                                                id={`word-${wordIndex}`}
                                                                                content={word?.highlightWord}
                                                                                hasChild={word?.wordLocations?.length > 0}
                                                                                onClick={() =>
                                                                                    updateDefinition({
                                                                                        type: definitionManagementTypes.word,
                                                                                        sourceWordIndex,
                                                                                        meaningIndex,
                                                                                        wordIndex
                                                                                    })
                                                                                }
                                                                                extend={word.extend}
                                                                                type={languageIcon}
                                                                            />
                                                                            {word.extend ? (
                                                                                <span>
                                                                                    {word.extend &&
                                                                                        word.wordLocations.map(
                                                                                            (wordLocation: WordLocation) => (
                                                                                                <div
                                                                                                    key={wordLocation.tasksId}
                                                                                                    className="tree-container -container"
                                                                                                    draggable
                                                                                                    onDragStart={(event) => {
                                                                                                        event.stopPropagation();
                                                                                                        onDragStartLocation({
                                                                                                            event,
                                                                                                            eventPayload: {
                                                                                                                wordLocations: [
                                                                                                                    wordLocation
                                                                                                                ],
                                                                                                                sourceTreeWord: tree.word,
                                                                                                                sourceUniqueMeaningId:
                                                                                                                    meaning?.uniqueMeaningId,
                                                                                                                sourceTasksId: [
                                                                                                                    wordLocation.tasksId
                                                                                                                ],
                                                                                                                sourceWord:
                                                                                                                    word.highlightWord
                                                                                                            }
                                                                                                        });
                                                                                                    }}
                                                                                                >
                                                                                                    <AnimatedTreeCustom
                                                                                                        id={`location-${wordIndex}`}
                                                                                                        prefix={
                                                                                                            `${wordLocation.verseLocation
                                                                                                            } - `
                                                                                                        }
                                                                                                        content={wordLocation.verse}
                                                                                                        // wordLocations={word.wordLocations}
                                                                                                        wordLocations={
                                                                                                            wordLocation.highlightWords
                                                                                                        }
                                                                                                        extend={word.extend}
                                                                                                        hasChild={false}
                                                                                                        type={typeBible}
                                                                                                    />
                                                                                                    <button
                                                                                                        className={
                                                                                                            `btn btn-sm  ml-2  btn-${getReviewerStatus(
                                                                                                                getStatusFromTaskAction(
                                                                                                                    wordLocation.reviewerActions
                                                                                                                )
                                                                                                            ).button}`
                                                                                                        }
                                                                                                        onClick={() => {
                                                                                                            // history.push(`/workspace?bookListId=${wordLocation.bookListId}&chapterId=${wordLocation.chaptersId}&versesId=${wordLocation.versesId}`)
                                                                                                            if (wordLocation.versesId) {
                                                                                                                getVerseDetail([
                                                                                                                    {
                                                                                                                        verseId:
                                                                                                                            wordLocation.versesId,
                                                                                                                        targetVerseId:
                                                                                                                            wordLocation.targetVerseId,
                                                                                                                        taskId: wordLocation.tasksId
                                                                                                                    }
                                                                                                                ]);
                                                                                                                setSourceUniqueMeaningId(
                                                                                                                    meaning?.uniqueMeaningId
                                                                                                                );
                                                                                                                setSourceWord(tree.word);
                                                                                                            }
                                                                                                        }}
                                                                                                    >
                                                                                                        <i
                                                                                                            className={
                                                                                                                'fa fa-pencil-alt c-pointer '
                                                                                                            }
                                                                                                        />
                                                                                                    </button>
                                                                                                </div>
                                                                                            )
                                                                                        )}
                                                                                </span>
                                                                            ) : null}
                                                                        </div>
                                                                    }
                                                                </span>
                                                            ))}
                                                    </div>
                                                ))
                                                : null}
                                            {tree.extend && tree?.meanings ? (
                                                <Button
                                                    className="btn btn-primary btn-sm rounded ml-2 definition-update-btn "
                                                    onClick={() =>
                                                        dispatch({
                                                            type: DEF_MEANING_MODEL,
                                                            payload: { showModel: true, meaning: '', word: tree.word }
                                                        })
                                                    }
                                                >
                                                    <i className="fa fa-plus mr-1" /> {t('workspace.meaning')}
                                                </Button>
                                            ) : null}
                                        </div>
                                    ))}
                                </div>
                            ) : null}
                        </Card.Body>
                        <Card.Footer>
                            <Row>
                                <Col className="d-flex justify-content-center">
                                    {loading
                                        ? null
                                        : checkIsSingleTab()
                                            ? definitionData.length > 0 && (
                                                <ReactPagination
                                                    pagination={{ ...definitionSingleData }}
                                                    onPageChange={onPageChange}
                                                    className="task-pagination"
                                                />
                                            )
                                            : phraseData.length > 0 && (
                                                <ReactPagination
                                                    pagination={{
                                                        count: phraseData.length,
                                                        limit: phrasePageLimit,
                                                        page: phrasePage,
                                                        totalPages: Math.ceil(phraseData.length / phrasePageLimit)
                                                    }}
                                                    onPageChange={(date: number) => setPhrasePage(date)}
                                                    className="task-pagination"
                                                />
                                            )}
                                </Col>
                            </Row>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
        </>
    );
}
export default DefinitionManagement;

import 'font-awesome/css/font-awesome.min.css';
import LogRocket from 'logrocket';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import App from './App/index';
import './assets/scss/style.scss';
import config, { logRocketID } from './config';
import i18n from './internationalization/i18n';
import './scss/index.scss';
import * as serviceWorker from './serviceWorker';
import { store } from './store/store';
import { loadInitialApis } from './utils/handler';

loadInitialApis();
if (logRocketID !== '') {
    LogRocket.init(logRocketID);
}
const app = (
    <React.StrictMode>
        <I18nextProvider i18n={i18n}>
            <Provider store={store}>
                <BrowserRouter basename={config.basename}>
                    <App />
                    <ToastContainer />
                </BrowserRouter>
            </Provider>
        </I18nextProvider>
    </React.StrictMode>
);
ReactDOM.render(app, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

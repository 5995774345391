/* eslint-disable react-hooks/exhaustive-deps */
import NewModal from 'components/Modal';
import { useEffect, useState } from 'react';
import {
    Card, Col, Nav, Row, Tab,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NoVerseDetected } from '../../components/common';
import Loader from '../../components/shared/loader';
import { getWorkspaceData } from '../../store/actions/index';
import { updateVerseAction } from '../../store/actions/verse.actions';
import { RootState } from '../../store/reducer';
import { eventKeys } from '../../store/reducers';
import {
    getActiveTabByStatus, getActualStatus, getStatusById, getTaskStatus,
} from '../../utils/handler';
import { TaskActions } from "../viewTypes/task.types";
import Edit from './Edit';
import MappingPage from './Mapping';
import SideBar from './SideBar';
import Structure from './StructureTab';
import Translation from './Translation';
import { replaceBookDetail } from './handler';

function WorkspaceLayout() {
    const { filter } = useSelector((state: RootState) => state.taskReducer);
    const {
        bookDetails, selectedVerse, selectedVerseIndex, workspaceLoader,
    } = useSelector((state: RootState) => state.workspaceReducer);
    const { currentProject } = useSelector((state: RootState) => state.mainReducer);
    const [eventKey, setEventKey] = useState(eventKeys.translate);
    const [currentTaskStatusId, setCurrentTaskStatusId] = useState(0);
    const [actualTaskStatusId, setActualTaskStatusId] = useState(0);
    const [showPopup, setShowPopup] = useState(false);
    const { t } = useTranslation();
    useEffect(() => {
        const actualStatusId = getActualStatus(selectedVerse);
        const statusId = getTaskStatus(selectedVerse);
        setCurrentTaskStatusId(statusId);
        setActualTaskStatusId(actualStatusId);
        setEventKey(selectedVerse.activeTab);
        if (filter.verseId && actualStatusId !== statusId && selectedVerse.verseAccess) {
            setShowPopup(true);
        }
    }, [currentProject.id, selectedVerse, selectedVerse.activeTab]);
    const switchTab = (tabName: string) => {
        setEventKey(tabName);
        selectedVerse.activeTab = tabName;
        replaceBookDetail({ cIndex: 0, vIndex: selectedVerseIndex, data: selectedVerse });
    };
    const getWorkspaceDataFC = () => {
        const apiCall = bookDetails.bookListId !== Number(filter.bookListId || 13)
            || bookDetails.chapterId !== Number(filter.chapterId || 1)
            || bookDetails.projectId !== currentProject.id
            || bookDetails?.chapters?.length === 0;
        if (apiCall) {
            getWorkspaceData();
        }
    };
    useEffect(() => {
        if (filter.bookListId && filter.chapterId && currentProject.id) getWorkspaceDataFC();
    }, [filter.chapterId, currentProject.id]);
    const changeToActualStatus = () => {
        updateVerseAction({
            versesId: selectedVerse.id,
            projectId: currentProject.id,
            statusId: getActualStatus(selectedVerse),
        }).then((result: TaskActions) => {
            if (result) {
                selectedVerse.taskActions = [result];
                selectedVerse.activeTab = getActiveTabByStatus(getActualStatus(selectedVerse));
                replaceBookDetail({
                    cIndex: 0,
                    vIndex: selectedVerseIndex,
                    data: selectedVerse,
                });
            }
            setShowPopup(false);
        });
    };
    const getTab = () => {
        if (!filter.verseId) {
            return <NoVerseDetected />;
        } if (eventKey === eventKeys.translate) {
            return <Translation />;
        } if (eventKey === eventKeys.edit) {
            return <Edit />;
        } if (eventKey === eventKeys.structure) {
            return <Structure />;
        } if (eventKey === eventKeys.mapping) {
            return <MappingPage />;
        }
        return null;
    };
    return (
        <Tab.Container defaultActiveKey="translate">
            {filter.verseId ? (
                <Row className="px-3 pt-3">
                    <Col>
                        <Card>
                            <Card.Header className="pt-1">
                                <Nav variant="tabs">
                                    <Nav.Item className="text-3 font-weight-bold text-uppercase">
                                        <Nav.Link
                                            className={eventKey === eventKeys.translate ? 'active' : ''}
                                            onClick={() => switchTab(eventKeys.translate)}
                                        >
                                            <i className="fa fa-language mt-2 mb-3 mr-2 fa-lg" />
                                            {t('workspace.translate')}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="text-3 font-weight-bold text-uppercase">
                                        <Nav.Link
                                            disabled={currentTaskStatusId < 2}
                                            className={
                                                (eventKey === eventKeys.edit ? 'active' : '')
                                                + (currentTaskStatusId < 2 ? 'disabled' : '')
                                            }
                                            onClick={() => switchTab(eventKeys.edit)}
                                        >
                                            <i className="fa fa-edit mt-2 mb-3 mr-2 fa-lg" />
                                            {t('workspace.edit')}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="text-3 font-weight-bold text-uppercase">
                                        <Nav.Link
                                            disabled={currentTaskStatusId < 3}
                                            className={
                                                (eventKey === eventKeys.structure ? 'active' : '')
                                                + (currentTaskStatusId < 3 ? 'disabled' : '')
                                            }
                                            onClick={() => switchTab(eventKeys.structure)}
                                        >
                                            <i className="fa fa-edit mt-2 mb-3 mr-2 fa-lg" />
                                            {t('workspace.structure')}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="text-3 font-weight-bold text-uppercase">
                                        <Nav.Link
                                            disabled={currentTaskStatusId < 4}
                                            className={
                                                (eventKey === eventKeys.mapping ? 'active' : '')
                                                + (currentTaskStatusId < 4 ? 'disabled' : '')
                                            }
                                            onClick={() => switchTab(eventKeys.mapping)}
                                        >
                                            <i className="fa fa-project-diagram mt-2 mb-3 mr-2 fa-lg" />
                                            {t('workspace.mapping')}
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Card.Header>
                        </Card>
                    </Col>
                </Row>
            ) : null}
            <Row className="px-3">
                <Col className="col-2">
                    <SideBar />
                </Col>
                <Col className="col-10">
                    {workspaceLoader ? <Loader />
                        : (
                            <div>
                                <NewModal
                                    title={t('workspace.confirm-title') as string}
                                    showModal={showPopup}
                                    confirmText={t('workspace.confirm') as string}
                                    confirmDisabled={false}
                                    onConfirm={changeToActualStatus}
                                    onCancel={() => {
                                        setShowPopup(false);
                                    }}
                                >
                                    <div>
                                        <span>
                                            {t(
                                                'workspace.alert-confirm-actual-status',
                                                { status: getStatusById(actualTaskStatusId) },
                                            )}
                                            {' '}
                                        </span>
                                    </div>
                                </NewModal>
                                {getTab()}
                            </div>
                        )}
                </Col>
            </Row>
        </Tab.Container>
    );
}
export default WorkspaceLayout;

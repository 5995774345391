/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import { Component, useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation, withTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
// import Board from "react-trello";
import { TableFilter } from 'components/taskTable';
// eslint-disable-next-line import/no-relative-packages
import Board from "../edited_npm_modules/react-trello";
// import '../assets/scss/style.scss';
import { getNewDataInAllPages } from '../store/actions/call.action';
import { TrelloPayload, getTrelloList, updateTrelloTicketStatus } from '../store/actions/trello.action';
import { BOOK_DETAILS, FETCH_TRELLO, RESET_BOOK, SHOW_LOADING_WINDOW, TASK_FILTER } from '../store/actions/types';
import { RootState } from '../store/reducer';
import { InitialResetBook } from '../store/reducers';
import { checkDestinationAccess, checkIsAdminOrDs, formBookPath, getAllStatus, getMyTaskAction, getStatusById, getStatusPercentage, getUserById, handleToast } from '../utils/handler';
import { TrelloCard, TrelloData } from './viewTypes/trello.type';
import { UpdateTaskPayload } from './viewTypes/words.type';

const TaskDetailsModal = withTranslation('translation')((props: any) => {
    const { myProfile } = useSelector((state: RootState) => state.userReducer);
    const { t } = useTranslation();
    const {
        taskDetails: { modalStatus, taskData, taskId },
        updateContainerDetails,
        setTaskModalClose
    } = props;
    const { assignedTo, deadline, description, status, title, chapterId, versesId } = taskData;
    const [isEditable, setIsEditable] = useState(checkDestinationAccess(taskData.taskAction));
    const { userList } = useSelector((state: RootState) => state.userReducer);
    const statusList = getAllStatus();
    const [updatedTaskDetails, setUpdatedTaskDetails] = useState({
        statusId: status,
        assignedTo,
        deadLine: deadline,
        description,
        chapterId,
        versesId
    });
    useEffect(() => {
        setIsEditable(taskData.assignedTo === myProfile.id || checkIsAdminOrDs());
        setUpdatedTaskDetails({
            statusId: status,
            assignedTo,
            deadLine: deadline,
            description,
            chapterId,
            versesId
        });
    }, [taskData]);
    return (
        <Modal
            centered
            show={modalStatus}
            onHide={() => {
                setTaskModalClose();
                //setIsEditable(false);
            }}
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title as="h5">{t('task-board.modal-title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
                <Row className="p-0">
                    <Col>
                        <table className="table text-dark table-sm table-borderless">
                            <tbody>
                                <tr>
                                    <td className="text-right w-10">
                                        <strong>{t('task-board.title')}</strong>
                                    </td>
                                    <td className="text-left">{title}</td>
                                </tr>
                                <tr>
                                    <td className="text-right w-10">
                                        <strong>{t('task-board.status')}</strong>
                                    </td>
                                    {!isEditable ? (
                                        <td className="text-left">{getStatusById(status)}</td>
                                    ) : (
                                        <td>
                                            <Form.Control
                                                as="select"
                                                className=" py-0 h-25 pl-0"
                                                disabled={false}
                                                value={updatedTaskDetails?.statusId ? updatedTaskDetails?.statusId : 0}
                                                onChange={(e) => {
                                                    setUpdatedTaskDetails({
                                                        ...updatedTaskDetails,
                                                        statusId: Number(e.target.value)
                                                    });
                                                }}
                                            >
                                                <option key={0} value={0} disabled>
                                                    {t('task-board.select')}
                                                </option>
                                                {statusList.map((statusRes: { id: number, status: string }) => (
                                                    <option key={statusRes.id} value={statusRes.id}>
                                                        {t(`status.${statusRes.status}`)}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </td>
                                    )}
                                </tr>
                                <tr>
                                    <td className="text-right w-10">
                                        <strong>{t('task-board.assigned-to')}</strong>
                                    </td>
                                    {!isEditable ? (
                                        <td>{getUserById(assignedTo)?.name || '-'}</td>
                                    ) : (
                                        <td>
                                            <Form.Control
                                                className=" py-0 h-25  pl-0"
                                                as="select"
                                                disabled={false}
                                                value={updatedTaskDetails?.assignedTo ? updatedTaskDetails?.assignedTo : 0}
                                                onChange={(e) => {
                                                    setUpdatedTaskDetails({
                                                        ...updatedTaskDetails,
                                                        assignedTo: Number(e.target.value)
                                                    });
                                                }}
                                            >
                                                <option key={0} value={0} disabled>
                                                    {t('task-board.select')}
                                                </option>
                                                {userList.map((user: any) => (
                                                    <option key={user.id} value={user.id}>
                                                        {user.name}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </td>
                                    )}
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </Modal.Body>
            {isEditable && (
                <Modal.Footer className="justify-content-sm-center">
                    <div>
                        <Button
                            className="bg-success ml-1"
                            onClick={() => {
                                updateContainerDetails(updatedTaskDetails, taskId);
                            }}
                        >
                            {t('task-board.update')}
                        </Button>
                    </div>
                </Modal.Footer>
            )}
        </Modal >
    );
});
const dataLane = {
    lanes: []
};
let currentFilter = '';
let globalTrelloTasks: any = null;
const updatedFilters = {
    bookListId: '',
    page: 1,
    chapterId: '',
    versesId: '',
    verseId: '',
    languageId: '',
    assignedToMe: 1
};
let currentLocalLanguage = "";
let trelloData: TrelloData[] = [];
class Trello extends Component<any, any> {
    constructor(props: any) {
        super(props);
        const { currentProject, filter } = this.props;
        this.state = {
            data: dataLane,
            currentProject,
            selectedFilters: {
                bookListId: undefined,
                chapterId: undefined,
                versesId: undefined,
                assignedTo: 0,
                search: '',
                assignedToMe: filter.assignedToMe || 1
            },
            taskModal: {
                modalStatus: false,
                taskData: {
                    title: '',
                    word: '',
                    status: null,
                    assignedTo: null,
                    deadline: '-',
                    description: ''
                } as {
                    title: string;
                    word: string;
                    status: null | number;
                    assignedTo: null | number;
                    deadline: string;
                    description: string;
                },
                completeTaskObj: {},
                taskId: null
            },
            taskAction: {},
        };
    }
    componentDidMount() {
        const { filter, trelloFetched, updateTaskDetail, fetchTrello } = this.props;
        this.setState({
            selectedFilters: filter
        });
        updateTaskDetail(updatedFilters);
        if (currentFilter !== JSON.stringify(filter) || fetchTrello) {
            this.getAssignedTasks(filter);
            trelloFetched();
        } else if (globalTrelloTasks?.length) {
            this.setState({
                data: { lanes: globalTrelloTasks }
            });
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    UNSAFE_componentWillReceiveProps(nextProps: any) {
        const { selectedFilters, currentProject } = this.state;
        if (currentFilter !== JSON.stringify(nextProps.filter)) {
            this.getAssignedTasks(nextProps.filter);
            this.setState({
                selectedFilters: {
                    ...selectedFilters,
                    ...nextProps.filter
                }
            });
        } else if (nextProps.currentProject.id !== currentProject.id) {
            this.setState({ currentProject: nextProps.currentProject, }, () => {
                this.getAssignedTasks(nextProps.filter);
            });
        }
        if (nextProps.currentLocalLanguage !== currentLocalLanguage) {
            this.loadTrelloLanes(trelloData);
            currentLocalLanguage = nextProps.currentLocalLanguage;
        }
    }
    openTask = (card: { bookListId: number, chapterId: number, versesId: number }) => {
        const { history } = this.props;
        history.push(`/workspace?bookListId=${card.bookListId}&chapterId=${card.chapterId}&verseId=${card.versesId}`);
    };
    editTask = (card: TrelloCard) => {
        const { t, booksList, currentProject } = this.props;
        const bookName = _.find(booksList, { id: card.bookListId });
        const cd = {
            id: card.id,
            chapter: card.chapter,
            title: formBookPath(card),
            taskActions: [
                {
                    assignedTo: card.user.id,
                    chapterId: card.chapterId,
                    versesId: card.versesId,
                    statusId: Number(card.statusId) || 1,
                    projectId: currentProject.id
                }
            ]
        };
        if (bookName) {
            this.setTaskCardToggle({ ...cd, bookName: bookName.name }, card.id);
        } else {
            handleToast({ message: t('task-board.error-fetching-data') }, 'error');
        }
    };
    getAssignedTasks = async (filterParam: any = {}) => {
        const { showLoaderWindow, filter } = this.props;
        const filterArg = filterParam || filter;
        currentFilter = JSON.stringify(filterArg);
        showLoaderWindow(true);
        trelloData = await getTrelloList({
            ...filterArg,
            ...filterArg,
            assignedTo: 0,
        });
        this.loadTrelloLanes(trelloData);
        showLoaderWindow(false);
    };
    loadTrelloLanes = (trelloTasks: TrelloData[]) => {
        if (trelloTasks) {
            const { t } = this.props;
            trelloTasks.map((lane: TrelloData) => {
                const id = lane.id.replace("lane", "") || 1;
                lane.style = { backgroundColor: getStatusPercentage(Number(id)).bgcolor };
                lane.title = t(`status.${lane.status}`);
                lane.cards = lane.cards.map((card: TrelloCard) => ({
                    ...card,
                    title: formBookPath(card),
                    editTaskAction: () => this.editTask({ ...card, statusId: lane.id.trim().replace('lane', '') }),
                    openTaskAction: () => this.openTask(card),
                    openString: t('task-board.open'),
                    viewString: t('task-board.view')
                }));
                return lane;
            });
            globalTrelloTasks = trelloTasks;
            this.setState({
                data: { lanes: trelloTasks }
            });
        }
    };
    updateTaskLength = (sourceLaneId: string, targetLaneId: string, position: number, cardDetails: any) => {
        const { data } = this.state;
        const updatedLanes = [...data.lanes]?.map((task: any) => {
            // lane1  lane2
            if (task.id === sourceLaneId) {
                const cards = task.cards.filter((card: any) => card.id !== cardDetails.id);
                return {
                    ...task,
                    label: task.label - 1,
                    cards
                };
            }
            if (task.id === targetLaneId) {
                const updatedCard = [...task.cards];
                updatedCard.splice(position, 0, cardDetails);
                return {
                    ...task,
                    label: task.label + 1,
                    cards: updatedCard
                };
            }
            return task;
        });
        globalTrelloTasks = updatedLanes;
        this.setState({
            data: { lanes: updatedLanes }
        });
    };
    updateTaskStatus = async (cardId: number, sourceLaneId: string, targetLaneId: string, position: number, cardDetails: any) => {
        const { t, currentProject, updateBookDetails } = this.props;
        const { selectedFilters } = this.state;
        if (sourceLaneId !== targetLaneId) {
            const payload: TrelloPayload = {
                projectId: currentProject.id,
                statusId: Number(targetLaneId.replace('lane', '')),
                versesId: cardDetails.versesId
            };
            try {
                const { status }: any = await updateTrelloTicketStatus(payload);
                if (status === 200) {
                    updateBookDetails();
                    getNewDataInAllPages();
                    this.updateTaskLength(sourceLaneId, targetLaneId, position, cardDetails);
                    handleToast({ message: t('task-board.success-message') }, 'success');
                    this.getAssignedTasks(selectedFilters);
                }
            } catch (err) {
                handleToast({ message: t('task-board.role-error-message') }, 'error');
                this.getAssignedTasks(selectedFilters);
            }
        }
    };
    //Updates the Trello tickets
    onTrelloUpdate = (laneId: number, tasks: any) => {
        const { data } = this.state;
        data.lanes?.map((task: any) => {
            if (task.id === laneId) {
                const cards = [...task.cards, ...tasks.tasks];
                return {
                    ...task,
                    cards,
                    label: task.label,
                    ...tasks
                };
            }
            return task;
        });
    };
    //currently filters are disabled
    //interactive function for the infinite scroll of lane
    //adds the more tickets if scroll reached to end and if that lane have more data
    onLaneScroll = async (requestedPage: any, laneId: any) => {
        const { data } = this.state;
        const { filter, t } = this.props;
        const lane = data.lanes.find((page: any) => page.id.includes(laneId));
        let res = {};
        if (lane.hasMorePages) {
            const trelloTasks = await getTrelloList({
                ...filter,
                statusId: laneId.trim().replace('lane', ''),
                page: Number(lane.page) + 1,
                moreCards: 1
            });
            trelloTasks.tasks = trelloTasks.tasks.map((task: any) => ({
                ...task,
                editTaskAction: () => this.editTask({ ...task, statusId: laneId.trim().replace('lane', '') }),
                openTaskAction: () => this.openTask(task),
                openString: t('task-board.open'),
                viewString: t('task-board.view')
            }));
            res = {
                data: { lanes: this.onTrelloUpdate(laneId, trelloTasks) }
            };
            this.setState(res);
            return res;
        }
        return true;
    };
    setTaskCardToggle = (chapterDetail: any, taskId: null | number) => {
        let statusId = 0;
        let assignedTo = 0;
        let deadLine: any = ''; //new Date()
        let taskAction;
        let chapterId = 0;
        let versesId = 0;
        if (chapterDetail.taskActions) {
            taskAction = getMyTaskAction(chapterDetail.taskActions);
            if (taskAction && !_.isEmpty(taskAction)) {
                statusId = taskAction.statusId;
                assignedTo = taskAction.assignedTo;
                chapterId = taskAction.chapterId;
                versesId = taskAction.versesId;
                if (taskAction.deadLine) deadLine = new Date(taskAction.deadLine);
            }
        }
        const payload = {
            taskAction,
            title: chapterDetail.title,
            status: statusId,
            assignedTo,
            deadline: deadLine,
            chapterId,
            versesId
        };
        const taskModal = {
            modalStatus: true,
            taskData: payload,
            completeTaskObj: chapterDetail,
            taskId,
            draggable: chapterDetail.draggable
        };
        this.setState(
            {
                taskAction,
                taskModal
            },
            () => {
                // console.log('this.state.taskModel', this.state.taskModal.modalStatus)
            }
        );
    };
    setTaskModalClose = () => {
        const { taskModal } = this.state;
        this.setState({
            taskModal: {
                ...taskModal,
                modalStatus: false,
                taskData: {},
                completeTaskObj: {},
                taskId: null
            }
        });
    };
    updateContainerDetails = async (taskDetails: UpdateTaskPayload) => {
        const { t, currentProject, updateBookDetails } = this.props;
        const { selectedFilters } = this.state;
        try {
            const payload = {
                statusId: Number(taskDetails.statusId) || 1,
                projectId: currentProject.id,
                assignedTo: taskDetails.assignedTo || 0,
                versesId: taskDetails.versesId
            };
            const { status }: any = await updateTrelloTicketStatus(payload);
            if (status === 200) {
                updateBookDetails();
                getNewDataInAllPages();
                handleToast({ message: t('task-board.success-message') }, 'success');
                this.setTaskModalClose();
            }
        } catch (e) {
            handleToast({ message: t('task-board.update-success-message') }, 'error');
            this.setTaskModalClose();
        }
        this.getAssignedTasks(selectedFilters);
    };
    render() {
        const { data, taskAction, taskModal } = this.state;
        return (
            <>
                <TableFilter showStatus={false} showAssignedTo={false} showAssignedToMe />
                {data.lanes?.length > 0 ? <section role="main" id="session" >
                    <div className="card shadow-none">
                        <div className="card-body p-0">
                            <div
                                className="col-12"
                                style={{
                                    paddingBottom: 50
                                }}
                            >
                                <Board
                                    data={data}
                                    handleDragEnd={(
                                        cardId: number,
                                        sourceLaneId: string,
                                        targetLaneId: string,
                                        position: number,
                                        cardDetails: any
                                    ) => this.updateTaskStatus(cardId, sourceLaneId, targetLaneId, position, cardDetails)}
                                    laneDraggable={false}
                                    draggable={
                                        taskAction &&
                                        !_.isEmpty(taskAction) &&
                                        checkDestinationAccess(taskAction)
                                    }
                                    style={{ backgroundColor: 'white' }}
                                    onLaneScroll={this.onLaneScroll}
                                    editable={false}
                                    hideCardDeleteIcon
                                />
                            </div>
                        </div>
                        {/* </div> */}
                    </div>
                </section> : null}
                {taskModal.modalStatus ? (
                    <TaskDetailsModal
                        taskDetails={taskModal}
                        updateContainerDetails={this.updateContainerDetails}
                        setTaskModalClose={this.setTaskModalClose}
                    />
                ) : null}
            </>
        );
    }
}
const mapStateToProps = (state: RootState) => ({
    filter: state.taskReducer.filter,
    versesList: state.taskReducer.versesList,
    bookDetail: state.workspaceReducer.bookDetail,
    resetBook: state.workspaceReducer.resetBook,
    booksList: state.taskReducer.booksList,
    currentProject: state.mainReducer.currentProject,
    fetchTrello: state.workspaceReducer.fetchTrello,
    currentLocalLanguage: state.mainReducer.currentLocalLanguage
});
const mapDispatchToProps = (dispatch: any) => ({
    showLoaderWindow: (payload: boolean) => {
        dispatch({ type: SHOW_LOADING_WINDOW, payload });
    },
    trelloFetched: () => {
        dispatch({ type: FETCH_TRELLO, payload: false });
    },
    updateBookDetails: () => {
        dispatch({ type: BOOK_DETAILS, payload: { chapters: [] } });
    },
    resetBookDetail: (payload: InitialResetBook) => dispatch({ type: RESET_BOOK, payload }),
    updateTaskDetail: (payload: any) => dispatch({ type: TASK_FILTER, payload })
});
export default withTranslation('translation')(connect(mapStateToProps, mapDispatchToProps)(Trello));

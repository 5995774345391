import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import logoDark from '../assets/images/logo-dark.png';
import '../assets/scss/style.scss';
import { login } from '../store/actions/index';
import { decryptData, encryptData, handleToast, loginSignupSuccess } from '../utils/handler';
interface loginForm {
    email: string;
    password: string;
}
export function Login() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { isAccountLocked } = useSelector((state: any) => state.userReducer);
    const [userDetails, setUserDetails] = useState<loginForm>({
        email: '',
        password: ''
    });
    const history = useHistory();
    const [remember, setRemember] = useState(false);
    const { t } = useTranslation();
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name } = e.target;
        const { value } = e.target;
        setUserDetails({
            ...userDetails,
            [name]: value
        });
    };
    useEffect(() => {
        const rememberMe = localStorage.getItem('generator-remember');
        if (rememberMe === 'true') {
            const email = localStorage.getItem('generator-email') || '';
            const password = localStorage.getItem('generator-password') || '';
            setUserDetails({
                email: decryptData(email),
                password: decryptData(password)
            });
            setRemember(true);
        }
    }, []);
    const loginSuccess = (res: string) => {
        loginSignupSuccess(res).then(() => {
            handleToast({ message: t('signin.success-message') }, 'success');
            history.push('/');
        });
    };
    const submitLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        const { email = '', password = '' } = userDetails;
        if (email && password) {
            const res = await login({ password, email });
            if (res) {
                if (remember) {
                    localStorage.setItem('generator-email', encryptData(email));
                    localStorage.setItem('generator-password', encryptData(password));
                } else {
                    localStorage.removeItem('generator-email');
                    localStorage.removeItem('generator-password');
                }
                localStorage.setItem('generator-remember', remember.toString());
                loginSuccess(res);
            }
        }
    };
    return (
        <div className="auth-wrapper">
            <div className="auth-content">
                <div className="card">
                    <div className="row align-items-center text-center">
                        <div className="col-md-12">
                            <div className="card-body">
                                <form noValidate onSubmit={submitLogin}>
                                    <img src={logoDark} alt="" className="img-fluid mb-4" />
                                    <h4 className="mb-3 f-w-400">{t('signin.title')}</h4>
                                    {isAccountLocked ? <div className='mb-2 fw-600 '><span className='text-danger text-bold'>Account Locked. Please try again later</span></div> : null}
                                    <div className="form-group fill">
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            placeholder={t('signin.placeholder-email') as string}
                                            // eslint-disable-next-line jsx-a11y/no-autofocus
                                            autoFocus
                                            name="email"
                                            value={userDetails.email}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="form-group fill">
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="password"
                                            placeholder={t('signin.placeholder-password') as string}
                                            name="password"
                                            value={userDetails.password}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="d-flex align-items-start mb-4">
                                        <Link to="/forgot-password" className="text-primary">
                                            {t('signin.forgot-password')}
                                        </Link>
                                    </div>
                                    <Form.Group className="text-left save-credentials">
                                        <Form.Check
                                            className="login-credentials"
                                            custom
                                            type="checkbox"
                                            id="supported-checkbox"
                                            label={t('signin.save-credentials') as string}
                                            name="remember"
                                            checked={remember}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRemember(e.target.checked)}
                                        />
                                    </Form.Group>
                                    <button className="btn btn-block btn-primary mb-4" onSubmit={submitLogin}>
                                        {t('signin.title')}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Login;

import axios from 'axios';
import { plotBaseUrl } from '../../config';
import { CatchReturnError } from './actionHandler';

export const uploadDataFile = async (formData: FormData) => {
    try {
        const { data } = await axios.post(`${plotBaseUrl}/graph-data`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
        const Xs: Array<number> = [],
            Ys: Array<number> = [],
            Zs: Array<number> = [];
        (data as Array<Array<number>>).forEach((point: Array<number>) => {
            Xs.push(point[0]);
            Ys.push(point[1]);
            Zs.push(point[2]);
        });
        return [Xs, Ys, Zs];
    } catch (err) {
        CatchReturnError(err);
    }
};

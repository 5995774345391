/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import { Alert, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';
import { eventKeys } from 'store/reducers';
import { getVersesFromBookDetails } from 'views/workspace-wizard/handler';
import { RootState } from '../store/reducer';
import errorMessages from '../utils/errorMessages';
import { getSourceLanguage, getTargetLanguage, getUserById, handleToast, jumpToDebounce, referenceVerseType } from '../utils/handler';
import { Verse } from '../views/viewTypes/task.types';
import Loader from './shared/loader';

export function GetUserImage(props: { userId: number, size: number }) {
    const userDetail = getUserById(props.userId);
    return (
        <Avatar
            className="mr-2 avatar-profile"
            size={(props.size || 30).toString()}
            textSizeRatio={3}
            round
            src={userDetail.image}
            name={userDetail.name}
        />
    );
}
export function SourceLanguageCell() {
    return (
        <td className="w-10 px-1 py-0 text-center language-padding">
            <span className="badge badge-light-dark">{getSourceLanguage()}</span>
        </td>
    );
}
export function TargetLanguageCell() {
    return (
        <td className="w-10 px-3 py-2 text-center language-padding">
            <span className="badge badge-light-primary">{getTargetLanguage()}</span>
        </td>
    );
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ReactPagination = React.memo((props: any) => {
    const { pagination } = props;
    const [pageSpecifier, setPageSpecifier] = useState(pagination.page || 1);
    const { t } = useTranslation();
    const onPageChange = (pageNumber: number) => {
        if (pagination.totalPages >= pageNumber) {
            props.onPageChange(pageNumber);
        } else {
            handleToast({ message: errorMessages.pageNotFound });
        }
    };
    useEffect(() => {
        if (pagination.page !== pageSpecifier) { setPageSpecifier(pagination.page); }
    }, [pagination.page]);
    const jumpTo = (page: number) => {
        setPageSpecifier(page);
        if (pagination.totalPages >= page) {
            if (page) {
                jumpToDebounce(() => {
                    onPageChange(page);
                }, 500);
            }
        } else {
            handleToast({ message: t(errorMessages.pageNotFound) }, 'error');
        }
    };
    return (
        <span>
            {pagination.totalPages > 1 && (
                <div className="d-flex justify-content-between">
                    <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        breakLabel={'...'}
                        pageCount={pagination.totalPages}
                        pageRangeDisplayed={2}
                        onPageChange={(e) => {
                            onPageChange(e.selected + 1);
                        }}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                        forcePage={(pagination.page || 1) - 1}
                    />
                    <div className="ml-5">
                        <InputGroup size="sm">
                            <Form.Label className="mt-1 mr-1">{t('task-management.jump-to')}</Form.Label>
                            <Form.Control
                                type="number"
                                className="border w20 py-0 form-control jump-to"
                                placeholder={t('task-management.jump-to')}
                                value={pageSpecifier || ''}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    jumpTo(Number(e.target.value));
                                }}
                            />
                        </InputGroup>
                    </div>
                </div>
            )}
        </span>
    );
});
export const LoadingPage = React.memo(() => (
    <div className="popup">
        <Loader />
    </div>
));
export const NoVerseDetected = React.memo(() => {
    const { t } = useTranslation();
    return (
        <Col>
            <Card>
                <Card.Body className="overflow-hidden pb-0">
                    <Row className="text-center">
                        <Col>
                            <Alert variant="danger">
                                <Alert.Heading as="h4">{t('workspace.no-selection-made')}</Alert.Heading>
                                <p className="mb-0">
                                    {t('workspace.select')} {t('workspace.a')} <span className="font-weight-bold" />,{' '}
                                    <span className="font-weight-bold">{t('workspace.chapter')}</span>, {t('workspace.and')}{' '}
                                    <span className="font-weight-bold">{t('workspace.verse')}</span> {t('workspace.left-to-continue')}
                                </p>
                            </Alert>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Col>
    );
});
export const NoProjectAssigned = React.memo(() => {
    const { t } = useTranslation();
    return (
        <Col>
            <Card>
                <Card.Body className="overflow-hidden pb-0">
                    <Row className="text-center">
                        <Col>
                            <Alert variant="danger">
                                <Alert.Heading as="h4">{t('workspace.no-project-assigned')}</Alert.Heading>
                                <p className="mb-0">{errorMessages.noProjectAssigned}</p>
                            </Alert>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Col>
    );
});
export function ReferenceVerses({ type }: { type: string } = { type: referenceVerseType.typeSource }) {
    const { bookDetails, selectedVerse } = useSelector((state: RootState) => state.workspaceReducer);
    const verses: Verse[] = getVersesFromBookDetails(bookDetails);
    const selectedVerseIndex = _.findIndex(verses, (verseObj: Verse) => verseObj.id === selectedVerse?.id);
    const { t } = useTranslation();
    let hasVerses = false;
    let firstVerseIndex = 0;
    let secondVerseIndex = 0;
    // Source
    if (type === referenceVerseType.typeSource) {
        hasVerses = selectedVerseIndex - 1 >= 0;
        secondVerseIndex = selectedVerseIndex - 1;
        firstVerseIndex = selectedVerseIndex - 2;
    } else if (type === referenceVerseType.typeTarget && verses) {
        // Target
        hasVerses = selectedVerseIndex + 1 < verses.length;
        firstVerseIndex = selectedVerseIndex + 1;
        secondVerseIndex = selectedVerseIndex + 2;
    }
    const getVersePrefix = (verse: Verse) => verse.titleTop ? (t('filter.title-top')) : verse.isTitle ? (`${t('filter.title')} ${verse.verseNumber}`) : (`${t('workspace.verse')} ${verse.verseNumber}`);
    const displayVerseInfo = (verseIndex: number) => {
        const verseObj: Verse = verses[verseIndex];
        let verse = verseObj?.verse;
        if (selectedVerse.activeTab !== eventKeys.translate) {
            verse = verseObj?.translatedVerses[0]?.verse || '';
        }
        return `${getVersePrefix(verseObj)}: ${verse}`;
    };
    return hasVerses ? (
        <Row className={type === referenceVerseType.typeSource ? '' : 'mt-2'}>
            <Col>
                <Card className="shadow-none">
                    <Card.Footer className="bg-light-secondary py-1">
                        {verses[firstVerseIndex] && <p className="my-0 py-0">{displayVerseInfo(firstVerseIndex)}</p>}
                        {verses[secondVerseIndex] && <p className="my-0 py-0">{displayVerseInfo(secondVerseIndex)}</p>}
                    </Card.Footer>
                </Card>
            </Col>
        </Row>
    ) : (
        <span />
    );
}

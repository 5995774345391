import axios from 'axios';
import {baseUrl, requestHeader} from '../../config';
import {getCurrentProjectId} from '../../utils/handler';
import {CatchReturnError} from './actionHandler';

export const getEditorData = (booksId: number = 13, chapterNumber: number = 2) => {
    return axios
        .get(baseUrl + `/editorChapters/${getCurrentProjectId()}/${booksId}/${chapterNumber}`, {
            headers: requestHeader.headers
        })
        .then((res) => {
            let data = res['data'];
            return data;
        })
        .catch((err) => {
            CatchReturnError(err);
        });
};

import Axios from 'axios';
import {baseUrl, requestHeader} from '../../config';
import {definitionType, wordType} from '../../views/viewTypes/words.type';
import {CatchReturnError} from './actionHandler';


export const createContainerWord = (wordObj: wordType) => {
    return Axios.post(baseUrl + '/createContainerWord', wordObj, requestHeader)
        .then((res) => {
            let response = res['data'];
            return response;
        })
        .catch((err) => {
            CatchReturnError(err);
        });
};

// export const translationWordMerge = (wordObj: {translatedContainerId: number; wordId: number}) => {
//     return Axios.patch(baseUrl + '/translationWordMerge', wordObj, requestHeader)
//         .then((res) => {
//             let response = res['data'];
//             if (res.status === 200) return response;
//         })
//         .catch((err) => {
//             CatchReturnError(err);
//         });
// };

export interface NewTranslatedWord {
    languageId: number;
    words: string[];
    verseId: number;
}
// export const createTaskWords = (wordObj: NewTranslatedWord) => {
//     return Axios.post(baseUrl + '/createTaskWord', wordObj, requestHeader)
//         .then((res) => {
//             let response = res['data'];
//             return response;
//         })
//         .catch((err) => {
//             CatchReturnError(err);
//         });
// };


export const updateDefinition = (definitionObj: definitionType, id: number) => {
    return Axios.patch(baseUrl + '/definitions/' + id, definitionObj, requestHeader)
        .then((res) => {
            let response = res['data'];
            return response;
        })
        .catch((err) => {
            CatchReturnError(err);
        });
};

import { Suspense, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { NoProjectAssigned } from '../../../components/common';
import useWindowSize from '../../../hooks/useWindowSize';
import { RouteObject } from '../../../route';
import routes from '../../../routes';
import * as actionTypes from '../../../store/actions';
import { useSelector } from '../../../store/reducer';
import { checkIsAdminOrDs, checkIsReviewer, showMenu } from '../../../utils/handler';
import Loader from '../Loader';
import NavBar from './NavBar';
import Navigation from './Navigation';

function AdminLayout() {
    const { windowWidth } = useWindowSize();
    const dispatch = useDispatch();
    const { collapseMenu, layout, subLayout } = useSelector((state) => state.able);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { projectList } = useSelector((state: any) => state.mainReducer);
    useEffect(() => {
        if (windowWidth > 992 && windowWidth <= 1024 && layout !== 'horizontal') {
            dispatch({ type: actionTypes.COLLAPSE_MENU });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const mobileOutClickHandler = () => {
        if (windowWidth < 992 && collapseMenu) {
            dispatch({ type: actionTypes.COLLAPSE_MENU });
        }
    };
    let mainClass = ['pcoded-wrapper'];
    if (layout === 'horizontal' && subLayout === 'horizontal-2') {
        mainClass = [...mainClass, 'container'];
    }
    return (
        <>
            <Navigation />
            <NavBar />
            <div className="pcoded-main-container" onClick={() => mobileOutClickHandler}>
                <div className={mainClass.join(' ')}>
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            <div className="main-body">
                                <div className="page-wrapper">
                                    <Suspense fallback={<Loader />}>
                                        <Switch>
                                            {routes.map((route: RouteObject) =>
                                                (route.checkProjectAssigned && projectList.length === 0 && !checkIsAdminOrDs()) ? <div key={route.path}><NoProjectAssigned /></div> :
                                                    route.component && showMenu(route.isAdmin) ? (
                                                        (route.isAdminOrDS
                                                            ? checkIsAdminOrDs() || (checkIsReviewer() && route.isReviewer !== false)
                                                            : true
                                                        ) ? (
                                                            <Route
                                                                key={route.path}
                                                                path={route.path}
                                                                exact={route.exact}
                                                                // eslint-disable-next-line react/jsx-props-no-spreading
                                                                render={(props) => <route.component {...props} />}
                                                            />
                                                        ) :
                                                            null
                                                    ) : null
                                            )}
                                        </Switch>
                                    </Suspense>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default AdminLayout;

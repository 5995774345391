import Axios from 'axios';
import { baseUrl, requestHeader } from '../../config';
import { CatchReturnError } from './actionHandler';

export const getDataExport = (languageId: number, booksId: number) => Axios.get(`${baseUrl}/mapping-export/${languageId}/${booksId}`, requestHeader)
    .then((response) => {
        if (response.status === 200) {
            return response.data;
        }
    })
    .catch((err) => {
        CatchReturnError(err);
    });

interface propType {
    size?: number;
    className?: string;
}
function Loader({ size = 0, className = '' }: propType) {
    let dotStyle = {};
    let marginStyle = {};
    if (size) {
        marginStyle = { padding: '0px' };
        dotStyle = { width: size, height: size, margin: size / 2 };
    }
    return (
        <div className={`loader-holder ${className}`} style={marginStyle}>
            <div className="dot-loader " style={dotStyle} />
            <div className="dot-loader dot-loader--2" style={dotStyle} />
            <div className="dot-loader dot-loader--3" style={dotStyle} />
        </div>
    );
}
export default Loader;
export function PageLoader({ size = 0, className = '' }: propType) {
    let dotStyle = {};
    let marginStyle = {};
    if (size) {
        marginStyle = { padding: '0px' };
        dotStyle = { width: size, height: size, margin: size / 2 };
    }
    return (
        <div className='page-loader '>
            <div className={`loader-holder ${className}`} style={marginStyle}>
                <div className="dot-loader " style={dotStyle} />
                <div className="dot-loader dot-loader--2" style={dotStyle} />
                <div className="dot-loader dot-loader--3" style={dotStyle} />
            </div>
        </div>
    );
}

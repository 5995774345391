/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItemType } from '../../../../../../menu-items';
import NavCollapse from "../NavCollapse";
import NavItem from "../NavItem";

interface NavGroupProps {
    group: MenuItemType;
}
function NavGroup(props: NavGroupProps) {
    let navItems: React.ReactNode = '';
    const { t } = useTranslation();
    if (props.group.children) {
        const groups = props.group.children;
        navItems = Object.keys(groups).map((key) => {
            const item = groups[Number(key)];
            switch (item.type) {
                case 'collapse':
                    return <NavCollapse key={item.id} collapse={item} type="main" />;
                case 'item':
                    return <NavItem key={item.id} item={item} />;
                default:
                    return false;
            }
        });
    }
    return (
        <>
            <li key={props.group.id} className="nav-item pcoded-menu-caption">
                <label>{t(props.group.internationalizationPrefixes) || props.group.title}</label>
            </li>
            {navItems}
        </>
    );
}
export default NavGroup;
